import { useDispatch, useSelector } from "react-redux";

import { cleanAnswer, reducerFormAnswer, setRefresQuestions } from "../../../../reducers/documents/reducerGenerateDocument";
import { FINISH, ID_NOT_DEFINED, NO_FINISH } from "../../../../constant/constant";
import { MessageError } from "../../../../utils/message";
import { toast } from "../../../../utils/toast";
import GeneralService from "../../../../services/GeneralService";

const FormAnswers = () => {
    const { selectedAnswer, selectedQuestion } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerFormAnswer({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("answers");

        if (selectedAnswer.id === ID_NOT_DEFINED) {
            const data = {
                ...selectedAnswer,
                questions_documents_id: selectedQuestion.id,
            }
            const res = await service.post(data);
            if (res.is_ok) {
                toast(dispatch, res.message);
                dispatch(setRefresQuestions());
                dispatch(cleanAnswer());
                return;
            }
            MessageError(res.message, service.status);
            return;
        }
        const res = await service.update(selectedAnswer);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(setRefresQuestions());
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <form onSubmit={handleSubmit} autoCorrect="on">
            <div className="row">
                <div className="col">
                    <h5><b>{selectedAnswer.id === ID_NOT_DEFINED ? "Crear" : "Editar"} respuesta</b></h5>
                    <p>
                        Vas a {selectedAnswer.id === ID_NOT_DEFINED ? " crear " : " editar "} una respuesta para la pregunta:
                    </p>
                    <p className="text-center"><b>{selectedQuestion.name}</b></p>
                </div>

            </div>
            <div className="row mt-2 mb-3">

                <div className='col'>
                    <label>Tipo de respuesta<b><i className="text-red">*</i></b></label>
                    <input
                        type="text"
                        className="form-control mt-1"
                        name="name"
                        value={selectedAnswer.name}
                        maxLength={255}
                        placeholder="Ingrese un nombre"
                        autoComplete="off"
                        autoCapitalize="words"
                        onChange={setData}
                        required
                    />
                </div>
            </div>


            <label>¿La respuesta finaliza la secuencia?</label>
            <div className="row abs-center mt-1">
                <div className={`col-4 py-1 px-2 mx-2 rounded-4 ${selectedAnswer.finish ? "bg-success text-white" : "border-success"} text-center`}
                    role="button"
                    onClick={() => dispatch(reducerFormAnswer({ key: "finish", value: FINISH }))
                    }>
                    SÍ
                </div>
                <div className={`col-4 py-1 px-2 mx-2 rounded-4 ${!selectedAnswer.finish ? "bg-success text-white" : "border-success"} text-center`}
                    role="button"
                    onClick={() => {
                        dispatch(reducerFormAnswer({ key: "finish", value: NO_FINISH }));
                        dispatch(reducerFormAnswer({ key: "answer", value: "" }));
                    }}>
                    NO
                </div>
            </div>


            <div className="row my-2">
                <div className='col'>
                    <label>Respuesta {selectedAnswer.finish && <b><i className="text-red">*</i></b>}</label>
                    <textarea
                        className="form-control custom-textarea mt-1"
                        name="answer"
                        value={selectedAnswer.answer}
                        maxLength={1000}
                        placeholder="Ingrese la respuesta"
                        disabled={!selectedAnswer.finish}
                        onChange={setData}
                        required={selectedAnswer.finish}
                    />
                </div>
            </div>

            <button type="submit" className={`btn btn-success d-block mx-auto mt-4 rounded-3 button-save-document'`}>
                <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                {selectedAnswer.id === ID_NOT_DEFINED ? "Agregar" : "Editar"}
            </button>


        </form>
    )
}

export default FormAnswers;