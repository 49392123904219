import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import GeneralService from "../../services/GeneralService";
import { HIDE, SHOW } from "../../constant/constant";
import "./styles/cardDefaultBusiness.css";
import { MessageError, toast } from "../../utils";

export const DefaultBusiness = ({ onChange }) => {
    const [show, setShow] = useState(HIDE);
    const { businessProfiles } = useSelector(state => state.loginSlice);
    const dispatch = useDispatch();

    const selectBusiness = async (business_id) => {
        const service = new GeneralService("user/set-favorite-business");
        const res = await service.post({ business_id });
        setShow(HIDE);
        if (res.is_ok) {
            toast(dispatch, res.message);
            localStorage.setItem("defaultBusiness", business_id);
            onChange();
            return;
        }
        MessageError(res.message);
    }

    useEffect(() => {
        if (businessProfiles.length > 1 && !localStorage.getItem("defaultBusiness")) {
            setShow(SHOW);
        }
    }, [businessProfiles]);

    return (
        <Modal show={show} centered size="lg">
            <Modal.Header className="bg-green" closeButton>
                <h5 className="d-block w-100 fw-bold text-center">Empresa por defecto</h5>
            </Modal.Header>

            <Modal.Body className="font-lato pb-4">
                <div className="row">
                    <div className="col">
                        <p>Hemos encontrado que estás vinculado a más de una empresa. Por favor, selecciona la empresa con la que deseas trabajar por defecto:</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <div className="d-flex justify-content-center align-items-center">
                            {
                                businessProfiles.map(item => <CardDefaultBusiness
                                    key={item.id}
                                    name={item.business?.name}
                                    onClick={() => selectBusiness(item.business_id)}
                                />)
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const CardDefaultBusiness = ({ name, onClick }) => {
    return (
        <div
            className="card__default-business me-2"
            onClick={onClick}>
            <i className={`fa-solid fa-building`}></i>
            <div className="fs-6 fw-bold">{name}</div>
        </div>
    )
}