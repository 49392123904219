import { useResize } from "../hooks/useResize";

import './styles/cardProcess.css';

const CardProcess = ({ icon, title, subtitle, iconColor, isActive, order, className = "", onClick = () => { }, icon_style }) => {
    const { windowSize } = useResize();

    return (
        windowSize.width <= 576
            ?
            <div title={subtitle} className={`rounded-pill abs-center mx-3 ${isActive ? "bg-blue" : "bg-white"}`}
                style={{ width: "40px", height: "40px" }}
                onClick={onClick}
            >
                <i className={`fa-solid ${icon} ${isActive ? "text-white animate__animated animate__shakeY animate__slow animate__delay-5s" : iconColor} fs-4`} ></i>
            </div>
            :
            <div title={subtitle}
                onClick={onClick}
                className={`col card-custom__config ${isActive ? "bg-blue" : "bg-white"} py-2 px-4 ${className}`}
            >
                <i className={`fa-solid me-2 ${icon} ${isActive ? "text-white" : iconColor} fs-4 mt-1`} ></i>
                <h6 className={`mt-1 ms-1 ${isActive ? "text-white" : "text-black"}`}><b>{title}</b></h6>
            </div>
    );
}

export default CardProcess