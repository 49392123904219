import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChatItem from './ChatItem';
import { HeaderModalBottom, ModalBottom } from '../../../app/components/ModalBottom';
import { SearchBar } from '../../../app/components/SearchBar';

import GeneralService from '../../../services/GeneralService';
import { searchTextUsers, setUsers } from '../../../reducers/user/reducerUsers';
import { setSelectedChat } from '../../../reducers/chat/reducerChatSlice';
import { findChatWithParticipants } from '../utils/processData';
import { useResize } from '../../../app/hooks/useResize';

const AddNewChat = () => {
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const refNewChat = useRef();

    const handleContextMenu = (e) => {
        e.preventDefault();
        setContextMenuVisible(c => !c);
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const handleDocumentClick = (e) => {
        if (refNewChat.current && !refNewChat.current.contains(e.target)) {
            setContextMenuVisible(false);
        }
    };

    return (
        <div className='d-inline-block' ref={refNewChat}>
            <div
                title="Iniciar nuevo chat"
                className="add-new__chat"
                onClick={handleContextMenu}
            >
                <i className='fa-solid fa-plus'></i>
            </div>
            <ContextMenu
                visible={contextMenuVisible}
                onHide={() => setContextMenuVisible(false)}
            />
        </div>
    );
}

const ContextMenu = ({ visible, onHide }) => {
    const { listFilter, dataFilter } = useSelector(state => state.userSlice);
    const { nick_name, name } = useSelector(state => state.loginSlice.profile);
    const { filteredChats } = useSelector(state => state.businessChatSlice);
    const { windowSize } = useResize();
    const dispatch = useDispatch();

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        const service = new GeneralService("user");
        const response = await service.getList(0, { page: 0, page_size: 1000 });
        const { profile = [] } = response;
        dispatch(setUsers(profile));
    }

    const handleAddNewChat = (user) => {
        const foundChat = findChatWithParticipants(filteredChats, nick_name, user.nick_name);
        if (foundChat) {
            dispatch(setSelectedChat(foundChat));
            onHide();
            return;
        }

        const newChat = {
            chatId: new Date().toISOString(),
            participants: {
                [user.nick_name]: user.name,
                [nick_name]: name,
            }
        };
        dispatch(setSelectedChat(newChat));
        onHide();
    }

    if (!visible) return null;

    const content = <>

        <div className="row">
            <div className="col">
                <SearchBar
                    value={dataFilter.filterName}
                    onChange={(e) => dispatch(searchTextUsers({ value: e.target.value }))}
                />
            </div>
        </div>
        <div className="row mt-2">
            <div className="col">
                <div className="list-users__chat">
                    {
                        listFilter.length === 0
                            ?
                            <div className="alert bg-light-purple text-center" role="alert">
                                No hay coincidencias
                            </div>
                            :
                            listFilter.filter(user => user.nick_name !== nick_name).map(user => <ChatItem
                                key={user.id}
                                name={user.name}
                                urlAvatar={user.url_avatar}
                                onClick={() => handleAddNewChat(user)}
                            />)
                    }
                </div>
            </div>
        </div>
    </>;

    return (
        windowSize.width > 900
            ?
            <div className="context-menu__chat" style={{ width: "25rem" }}>
                <div className="container-fluid">
                    <div className="row mt-2 mb-1">
                        <div className="col">
                            <h6 className='text-start font-lato fw-bold'>Nuevo chat</h6>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
            :
            <ModalBottom isOpen={visible} onClose={onHide}>
                <HeaderModalBottom onHide={onHide} >
                    <h6 className='text-start font-lato fw-bold'>Nuevo chat</h6>
                </HeaderModalBottom>
                {content}
            </ModalBottom>
    );
};

export default AddNewChat;