import { FileIcons } from '../fixtures/fileIcons';
import IconButton from '../../../app/components/IconButton';

export const CardFileUploaded = ({ file, onEdit, onDelete, onDownload, showEditAction = true }) => {
    return (
        <div className="drop-file-preview__item" onDoubleClick={onEdit}>
            <div className='icon-name'>
                {FileIcons[file.type] || FileIcons['default']}
                <div className="d-inline-block fs-6 font-lato">
                    {file.name}
                </div>
            </div>
            <div className='actions center-vertical'>
                {
                    showEditAction
                    &&
                    <IconButton
                        icon="fa-solid fa-pen-to-square text-green"
                        title="Editar nombre de archivo"
                        fontSize='20px'
                        onClick={onEdit}
                    />
                }
                <IconButton
                    icon="fa-solid fa-file-arrow-down text-blue"
                    title="Descargar archivo"
                    fontSize='20px'
                    onClick={onDownload}
                />
                <IconButton
                    icon="fa-solid fa-trash-can text-red"
                    title="Eliminar archivo"
                    fontSize='20px'
                    onClick={onDelete}
                />
            </div>
        </div >
    );
}