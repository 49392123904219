import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../../app/components/IconButton";

import { MessageError } from "../../../utils/message";
import confirmDelete from "../../../utils/confirmDelete";
import GeneralService from "../../../services/GeneralService";
import { toast } from "../../../utils";
import { deleteFromImporter } from "../../../reducers/importer/reducerImporter";

export const ListImports = () => {
    const { listFilter } = useSelector(state => state.reducerImporterSlice);
    const { email } = useSelector(state => state.loginSlice.auth);
    const dispatch = useDispatch();

    const deleteImport = async (id) => {
        const service = new GeneralService("data-import");
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFromImporter(id));
            return;
        }
        MessageError(res.message);
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay importaciones que mostrar {":)"}
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Archivo</th>
                                        <th>Colección</th>
                                        <th>Status</th>
                                        <th className="text-center">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((itemImport, i) => (
                                            <tr key={itemImport.id}>
                                                <td></td>
                                                <td style={{width: "40px"}}>{i + 1}.</td>
                                                <td><i className="fa-solid fa-file-excel text-green fs-4 me-2"></i> {itemImport.file_name}</td>
                                                <td>{itemImport.collection}</td>
                                                <td>{itemImport.status}</td>
                                                <td className="text-center">
                                                    {
                                                        email !== itemImport.user?.email && <IconButton
                                                            icon="fa-solid fa-trash-can text-red"
                                                            title="Eliminar importación"
                                                            onClick={async () => await confirmDelete(() => deleteImport(itemImport.id))}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    );
}