import { useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import ToastNotify from "../app/components/ToastNotify";
import LoadingCustom from "../app/components/LoadingCustom";
import RegisterCustomer from "../modules/TercerosCreate/components/createPeople/RegisterCustomer";
import SuccessRegisterCustomer from "../modules/TercerosCreate/components/createPeople/SuccessRegisterCustomer";
import InvalidTokenRegistrationMessage from "../modules/TercerosCreate/components/createPeople/InvalidTokenRegistrationMessage";


const CustomerRoutes = () => {
    const { show } = useSelector(state => state.toastSlice);

    return (
        <div>
            {show && <ToastNotify />}
            <LoadingCustom />

            <Router>
                <Switch>
                    <Route exact path="/customer/registration/:uuid"> <RegisterCustomer /> </Route>
                    <Route exact path="/customer/registration-status/successful/:name"> <SuccessRegisterCustomer /> </Route>
                    <Route exact path="/customer/registration-status/error"> <InvalidTokenRegistrationMessage /> </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default CustomerRoutes;