import { useState } from "react";
import { useDispatch } from "react-redux";

import FormQuestions from "./dialogs/FormQuestions";
import FormAnswers from "./dialogs/FormAnswers";
import ListQuestions from "./dialogs/ListQuestions";

import { cleanQuestion } from "../../../reducers/forms/reducerDialogForm";
import { HIDE, SHOW } from "../../../constant/constant";
import LegendCard from "../../DocumentsAuto/components/parametrize/LegendCard";

const TabDialogs = () => {
    const [showFormQuestions, setShowFormQuestions] = useState(true);
    const [showFormAnswers, setShowFormAnswers] = useState(false);
    const dispatch = useDispatch();

    return (
        <div className="process-tab">
            <div className="row">
                <div className="col-8 border-right-dashed">
                    <h5><b>Crea diálogos y Formulaciones</b></h5>

                    <p>Se pueden incluir preguntas abiertas y cerradas, las preguntas abiertas finalizan la secuencia.</p>

                    <div className="row my-4">
                        <LegendCard title="Pregunta abierta" icon="fa-circle-question text-dark" />
                        <LegendCard title="Pregunta cerrada" icon="fa-circle-question text-orange" />
                        <LegendCard title="Respuesta" icon="fa-thumbtack text-green" />

                        <div className="col-1 mt-2">
                            <div className="row">
                                <div className="col ms-2 fs-7">
                                    <span className="px-2 py-1 rounded-4 bg-purple text-white">Fin</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-2">
                            <button title="Crear pregunta base" className="btn btn-success d-block mt-1 mx-auto btn-sm"
                                type="button"
                                onClick={() => {
                                    dispatch(cleanQuestion());
                                    setShowFormQuestions(SHOW);
                                    setShowFormAnswers(HIDE);
                                }}
                            >
                                <i className="fa-solid fa-question text-white me-2" style={{ fontSize: '16px' }} />
                                Crear
                            </button>
                        </div>

                    </div>

                    <ListQuestions
                        setShowFormQuestions={setShowFormQuestions}
                        setShowFormAnswers={setShowFormAnswers}
                        aditionalIDDOM="create"
                    />

                </div>

                <div className="col-4 px-4">
                    {showFormQuestions && <FormQuestions />}
                    {showFormAnswers && <FormAnswers />}
                </div>

            </div>
        </div>
    );
}

export default TabDialogs;