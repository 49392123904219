import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../app/components/IconButton";
import { CalendarComponent, Dropdowncustome, ModalEvent, ModalLabel } from "./components/index";
import { LabelCalendar } from "./components/LabelCalendar";

import GeneralService from "../../services/GeneralService";
import { setListLabels, setEvents, setEventsday, setListUser, eventUpdate, setFilters, setEvent } from "../../reducers/calendar/reducerCalendar";
import { HIDE, SHOW } from "../../constant/constant";
import "./styles/calendarstyle.css";

const Calendar = () => {
  const { labels, events, eventsday, filters } = useSelector(state => state.calendarslice);
  const [showModalLabel, setShowModalLabel] = useState(HIDE);
  const [showModalEvent, setShowModalEvent] = useState(HIDE);
  const dispatch = useDispatch();
  const reFilter = useRef(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    getEvents();
    getLabels();
    getEventsday();
    getUser();
  }, [filters]);

  useEffect(() => {
    // This new effect will run when selectedDate changes
    getEventsday();
  }, [selectedDate]);

  //traer Get de eventos
  const getEvents = async () => {
    const service = new GeneralService("events");

    const event = await service.getList(1000, filters);
    dispatch(setEvents({ value: event ?? [] }));
  };

  const isSameDay = (dayOne, dayTo) => {
    return (
      dayOne.getUTCFullYear() === dayTo.getUTCFullYear() &&
      dayOne.getUTCMonth() === dayTo.getUTCMonth() &&
      dayOne.getUTCDate() === dayTo.getUTCDate()
    );
  }

  const getEventsday = async () => {
    const fechaObj = new Date(selectedDate);
    const eventDays = events.filter((event) =>
      isSameDay(new Date(event.start_date), fechaObj)
    );
    dispatch(setEventsday({ value: eventDays }));
  }

  //get de labels
  const getLabels = async () => {
    const service = new GeneralService("labels");
    const object = await service.getList(1000);
    const labels = object.labels;
    dispatch(setListLabels({ value: labels ?? [] }));
  }

  //transoformar datos recibidos de eventos
  const transformEvents = (events) => {
    return events.map((event) => ({
      title: event.title,
      start: event.start_date.slice(0, 16),
      end: event.end_date.slice(0, 16) || event.start_date.slice(0, 16),
      color: event.LabelShort ? event.LabelShort[0].color_font : null,
    }));
  }

  const transformedEvents = transformEvents(events);

  //traer usuarios
  const getUser = async () => {
    const service = new GeneralService("user");
    const object = await service.getList(1000);
    const user = object.profile;

    dispatch(setListUser({ value: user }));
  }

  // filtros de labels
  const eventLabel = (e, itemid) => {
    e.preventDefault();

    dispatch(setFilters({ key: "label_id", value: itemid }));
  }

  //contenido de dropdown de filtros
  const dropdownContent = (
    <div className="dropdown-locale">
      {labels.map((item, index) => (
        <button
          key={item.id}
          className="btndrop"
          style={{
            color: item.label_color.color_font,
            backgroundColor: item.label_color.color,
          }}
          onClick={(e) => eventLabel(e, item.id)}
        >
          {item.name}
        </button>
      ))}
    </div>
  )

  //Delete de labels
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que quieres eliminar esta etiqueta?"
    );

    if (confirmed) {
      const service = new GeneralService(`labels`);
      const object = await service.delete(id);
      getLabels();
    }
  }

  //funcion seleccionar dia
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    dispatch(setEvent({ key: "start_date", value: date+"T00:00" }));
    getEventsday();
  }

  const formatTime = (timeString) => {
    if (!timeString) return "";

    const [hours, minutes] = timeString.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // convierte a formato 12 horas
    const formattedTime = `${formattedHours}:${minutes < 10 ? "0" + minutes : minutes
      } ${ampm}`;

    return formattedTime;
  }

  const handleDeletevents = async (item) => {
    const confirmed = window.confirm(
      `¿Estás seguro de que quieres eliminar el evento ${item.id}?`
    );
    if (confirmed) {
      const service = new GeneralService(`events`);
      const object = await service.delete(item.id);
      getEvents();
    }
  }

  const handleEditEvent = (item) => {
    dispatch(eventUpdate({
      ...item,
      labels_id: item.LabelShort ? item.LabelShort.map(label => label.id) : undefined
    }));
    setShowModalEvent(SHOW);
  }

  return (
    <div className="container-fluid max-height-overflow-y">
      <div className="main-div">
        <div className="div-categorias" ref={reFilter}>
          <div className="container-parent">
            <div className="divcat2">
              {
                labels.map((label) => <LabelCalendar
                  key={label.id}
                  label={label}
                  onDelete={() => handleDelete(label.id)}
                />)
              }
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <Dropdowncustome
              label={
                <a className="a">
                  <i className="fa-solid fa-filter icon"></i>
                  <label className="textcolor">aplicar filtro</label>
                  <i className="fa-solid fa-chevron-down ms-2"></i>
                </a>
              }
              content={dropdownContent}
              position="bottom-right"
              width={200}
            />
            <IconButton
              icon="fa-solid fa-circle-plus text-green"
              title="Añadir etiqueta"
              onClick={() => setShowModalLabel(SHOW)}
            />
          </div>
        </div>
        <div className="container-calendar">
          <div className="divcalendar-1">
            <CalendarComponent
              reFilter={reFilter}
              eventosDB={transformedEvents}
              onDateSelect={handleDateSelect}
              getEvents={getEvents}
            >
              <div className="divcat2">
                {
                  labels.map((label) => <LabelCalendar
                    key={label.id}
                    label={label}
                    showAction={HIDE}
                  />)
                }
              </div>
            </CalendarComponent>
          </div>
          <div className="divcalendar-2">
            <div className="divcalendar-2-header">
              {selectedDate}
              <IconButton
                icon="fa-solid fa-circle-plus text-green"
                title="Añadir evento"
                onClick={() => setShowModalEvent(SHOW)}
              />
            </div>

            {eventsday.map((item, index) => (
              <div
                className="divcalendar-2-body"
                style={{
                  borderBottom: `8px solid ${item.LabelShort
                    ? item.LabelShort[0].color
                    : "rgba(51, 122, 255, 1)"
                    } `,
                }}
                key={index}
              >
                <div className="divcalendar-2-bodyinterno">
                  <div className="bodyinterno-datetime">
                    <div>
                      {formatTime(
                        item.start_date ? item.start_date.split("T")[1] : ""
                      )}
                    </div>
                    <div>{item.notification_time}</div>
                  </div>
                  <label className="bodyinterno-title">{item.title}</label>
                </div>
                <div className="tooltiptext">
                  <div
                    className="headertooltip"
                    style={{
                      backgroundColor: item.LabelShort
                        ? item.LabelShort[0].color
                        : "rgba(51, 122, 255, 1)",
                    }}
                  >
                    {item.title}
                  </div>
                  <div className="bodytooltip">
                    <div className="bodytooltip-text">
                      <i
                        className="fa-solid fa-clock"
                        style={{ paddingRight: "5px" }}
                      />
                      {item.start_date ? item.start_date.split("T")[0] : ""} a
                      las
                      {formatTime(
                        item.start_date ? item.start_date.split("T")[1] : ""
                      )}
                      {item.end_date &&
                        item.end_date.split("T")[0] !==
                        item.start_date.split("T")[0] ? (
                        <>
                          hasta el {item.end_date.split("T")[0]} a las
                          {formatTime(item.end_date.split("T")[1])}
                        </>
                      ) : (
                        <>
                          a las{" "}
                          {formatTime(
                            item.end_date ? item.end_date.split("T")[1] : ""
                          )}
                        </>
                      )}
                      <div>
                        <i className="fa-solid fa-user me-2" />
                        Responsable: {item.Responsible.name}
                      </div>

                      <div className="botoncontainertool mt-3">
                        <button type="button" title="Modificar evento"
                          className="btn btn-success"
                          onClick={() => handleEditEvent(item)}
                        >
                          <i className="fa-solid fa-pen-to-square text-white" />
                        </button>
                        <button type="button" title="Eliminar evento"
                          className="btn btn-success"
                          onClick={() => handleDeletevents(item)}
                        >
                          <i className="fa-solid fa-trash text-white" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ModalLabel show={showModalLabel} setShow={setShowModalLabel} />
      <ModalEvent
        show={showModalEvent}
        setShow={setShowModalEvent}
        getEvents={getEvents}
        selectDate={selectedDate}
      />
    </div>
  );
};

export default Calendar;
