import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedCategory, setListCategories } from '../../../../reducers/typePeople/reducerTypepeople';
import { toast } from "../../../../utils/toast";

const SelectCategory = () => {
    const { selectedTypepeople, selectedCategory, listCategories } = useSelector(state => state.typepeopleSlice);
    const [showSelectedCategory, setShowSelectedCategory] = useState(false);
    const dispatch = useDispatch();

    const setCategoryToList = () => {
        if (Object.keys(selectedCategory).length === 0) return;
        dispatch(setListCategories());
        setShowSelectedCategory(false);
        toast(dispatch, "Categoría añadida");
    }

    return (
        <>
            {
                !showSelectedCategory &&
                <div className='col-12 col-lg-5'>
                    <button title="Añade una categoría de datos" type="button" className='btn btn-success rounded-3 d-block mx-auto'
                        onClick={() => setShowSelectedCategory(true)}
                        disabled={!(selectedTypepeople.categories)}
                    >
                        <i className="fa-solid fa-plus me-1"></i>
                        Categoria
                    </button>
                </div>
            }

            {
                (showSelectedCategory && selectedTypepeople.categories) &&
                <>
                    <div className="col-9 col-lg-6 p-0">
                        <select className="form-select"
                            value={selectedCategory.id}
                            onChange={(e) => dispatch(setSelectedCategory({ value: e.target.value }))}
                        >
                            <option value="">
                                Seleccione la categoría
                            </option>
                            {
                                selectedTypepeople.categories?.map((category) => {
                                    return (
                                        <option value={category.id} key={category.id}
                                            disabled={
                                                listCategories.length > 0 &&
                                                listCategories.findIndex(i => i.id === category.id) !== -1
                                            }
                                        >
                                            {category.name}
                                        </option>
                                    )
                                })
                            }

                        </select>
                    </div>
                    <div className='col-3 col-lg-1'>
                        <button type="button" className={`btn btn-success rounded-3`}
                            onClick={setCategoryToList}
                            disabled={Object.keys(selectedCategory).length === 0}
                        >
                            Listo
                        </button>
                    </div>
                </>
            }
        </>
    )
}

export default SelectCategory;