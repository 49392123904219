import BackgroundCode from "./BackgroundCode";

import { TYPE_ANSWER } from "../../constant/constant";

const CardAnswer = ({ item, actions }) => {
    return (
        <div className="row mb-2 animate__animated animate__fadeInLeft" key={item.id + item.name}>
            <div className="col-10">
                <div className="row mb-2">
                    <div className="col">
                        <BackgroundCode>
                            <span className="text-purple">{item.question}</span>
                        </BackgroundCode>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        {item.type_answer && <b>
                            Seleccionaste
                        </b>} {item.type_answer && item.type_answer}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <b>Respuesta:</b> {item.answer_text}
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-2">
                {actions}
            </div>
            <hr className="mt-2" />
        </div>
    )
}

export default CardAnswer;