import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import FillForm from "../../../assets/fill-form.svg";
import CardForm from './CardForm';
import { SearchBar } from '../../../app/components/SearchBar';

import { searchForm, setListForm } from '../../../reducers/forms/reducerFormSlice';
import GeneralService from '../../../services/GeneralService';
import "../style/modelForm.css";

const VirtualNotary = () => {
    const { listFilter } = useSelector(state => state.formSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const loadForms = async () => {
        const service = new GeneralService("form/sortom-forms");
        const response = await service.getList(1000);
        const { form = [] } = response;
        dispatch(setListForm({ value: form }));
    }

    const onSearch = (e) => {
        dispatch(searchForm(e.target.value));
    }

    useEffect(() => {
        loadForms();
    }, []);

    return (
        <div className="pt-4 bg-light-purple pb-5" style={{ minHeight: "100vh" }}>
            <div className="row">
                <div className="col-10 mx-auto">

                    <div className="row bg-green rounded-3">
                        <div className="col-md-12 col-lg-2 abs-center">
                            <img className="ms-2" src={FillForm} alt="Notaría Virtual" />
                        </div>
                        <div className="col-md-12 col-lg-10 p-3">
                            <h4 className='font-lilita-one'>Notaría Virtual <i className="fa-solid fa-arrow-down animate__animated animate__bounce animate__repeat-3 ms-3"></i></h4>
                            <p><b>¡Hola!</b> Bienvenido. En SortomForms puedes encontrar variedad de formularios de propósito general. Tus datos estarán protegidos y no los compartiremos con nadie sin tu consentimiento. Al registrar tus datos, podemos ejecutar los procesos siguientes. Además, podrás recibir información detallada sobre tu proceso y no tendrás de qué preocuparte. <b>¡Que la pases bien!</b> 😊</p>
                        </div>
                    </div>

                    <div className='row pt-3'>
                        <div className="col-md-12 col-lg-4 mx-auto">
                            <SearchBar onChange={onSearch} className='bg-white' />
                        </div>
                    </div>

                    <div className='row row-cols-auto abs-center pt-3'>
                        {
                            listFilter.length == 0
                                ?
                                <div className="alert alert-warning text-center mt-4" role="alert">
                                    No hay formularios disponibles
                                </div>
                                :
                                listFilter.map(form => <CardForm
                                    key={form.id}
                                    form={form}
                                    onClick={() => history.push("/virtual-notary/fill-out/" + form.id)}
                                />)
                        }
                    </div>

                </div>
            </div>
        </div >
    );

}
export default VirtualNotary;