import { useRef } from "react";

import { MessageError } from "../../../utils/message";
import { maxSizeFile } from "../fixtures";
import { CardUploadItem } from "./CardUploadItem";

export const DropFiles = ({ files, setFiles, handleSubmit }) => {
    const wrapperRef = useRef(null);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const newFile = e.target.files;
        let newList = [];

        for (let i = 0; i < newFile.length; i++) {
            let x = newFile[i].name.split('.');
            if (newFile[i].size < maxSizeFile[x[x.length - 1]]) {
                newList.push(newFile[i]);
            } else {
                MessageError(`El archivo "${newFile[i].name}" supera el tamaño de MB permitido`);
            }
        }
        newList = [...newList, ...files]
        setFiles(newList);
    }

    const fileRemoveOfList = (item) => {
        const updatedList = [...files];
        updatedList.splice(files.indexOf(item), 1);
        setFiles(updatedList);
    }

    return (
        <>
            <div
                className="drop-files"
                ref={wrapperRef}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <i className="fa-solid fa-cloud-arrow-up"></i>
                <div className='description font-rubik fw-bold'>
                    Arrastra y suelta tus archivos aquí
                </div>
                <span className="fs-6">o</span>
                <button>Explorar</button>
                <input
                    type="file"
                    name=""
                    id=""
                    multiple={true}
                    onChange={e => onFileDrop(e)}
                />
            </div>
            {
                files.length > 0 &&
                <>
                    {
                        files.map((item, index) => <CardUploadItem
                            key={item.name}
                            file={item}
                            onClick={() => fileRemoveOfList(item)}
                        />)
                    }
                    <button title="Sube los arcihvos que has seleccionado"
                        className="font-lato btn btn-success d-block mx-auto mt-4" type="button"
                        onClick={handleSubmit}
                    >
                        Subir archivos
                    </button>
                </>
            }
        </>
    )
}
