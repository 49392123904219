import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import BreadcrumbCustom from '../../../../app/components/BreadcrumCustom';
import { cleanData, setListActor } from '../../../../reducers/forms/reducerActorForm';

import { ID_NOT_DEFINED } from '../../../../constant/constant';
import ListActorForm from './ListActorForm';
import ModalActorForm from './ModalActorForm';
import SearchActorForm from './SearchActorForm';
import GeneralService from '../../../../services/GeneralService';
import { setSelectedForm } from '../../../../reducers/forms/reducerFormSlice';

const ActorForm = () => {
    const { list, selectedForm } = useSelector(state => state.formSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { form = 0 } = queryString.parse(search);

    const loadData = async () => {
        if (selectedForm.id === ID_NOT_DEFINED) {
            dispatch(setSelectedForm({ value: list.find(i => i.id === parseInt(form)) }));
            const service = new GeneralService("actor-form");
            const { actors = [] } = await service.getList(1000, { form_id: form });
            dispatch(setListActor({ value: actors }));
            return;
        }
        const service = new GeneralService("actor-form");
        const { actors = [] } = await service.getList(1000, { form_id: selectedForm.id });
        dispatch(setListActor({ value: actors }));
    }

    useEffect(() => {
        loadData();
    }, [form, list]);

    return (
        <>
            <div className='mx-4'>
                <BreadcrumbCustom
                    title="Actores"
                    routes={[
                        { name: "Formularios", link: "/form-parameterization/list" },
                        { name: selectedForm.name ? selectedForm.name : "Seleccione tipo de formulario", link: "/form-parameterization/list" },
                    ]}
                    activeRoute="Actores"
                />

                <div className="container m-0 p-0">
                    <div className="row">
                        <div className="col-8">
                            <SearchActorForm />
                        </div>
                        <div className="col-4">
                            <button title="Crear nuevo documento" className="btn btn-success mt-1" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                                <i className="fa-solid fa-person-circle-plus text-white me-2" style={{ fontSize: '16px' }} />
                                Crear actor
                            </button>
                        </div>
                    </div>
                </div>

                <div className='table-data'>
                    <ListActorForm setShow={setShow} onCopy={loadData} />
                </div>
            </div>
            <ModalActorForm show={show} setShow={setShow} />
        </>
    )
}

export default ActorForm;