import IconButton from "../../../app/components/IconButton"

export const LabelCalendar = ({ label, showAction = true, onDelete = () => { } }) => {
    return (
        <div
            className="label__calendar"
            style={{
                color: label.label_color.color_font,
                backgroundColor: label.label_color.color,
            }}
        >
            {label.name}
            {
                showAction
                &&
                <IconButton
                    icon="fa-solid fa-circle-xmark text-white"
                    title="Eliminar etiqueta"
                    fontSize="13px"
                    onClick={onDelete}
                />
            }
        </div>
    )
}
