import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { reducerForm } from "../../../reducers/forms/reducerVirtualNotaryProcess";
import GeneralService from "../../../services/GeneralService";
import { setListForm } from "../../../reducers/forms/reducerFormSlice";

const ToolFilterDocuments = () => {
    const { dataFilter } = useSelector(state => state.virtualNotaryProcessSlice);
    const { list } = useSelector(state => state.formSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const loadTemplates = async () => {
        const service = new GeneralService("form");
        const { form = [] } = await service.getList(1000);
        dispatch(setListForm({ value: form }));
    }

    useEffect(() => {
        loadTemplates();
    }, []);

    return (
        <div className="row px-2 py-3 animate__animated animate__fadeInDown">

            <div className="col">
                <label>Tipo de formulario</label>
                <select
                    className="form-select green-input"
                    name="form_id"
                    value={dataFilter.form_id}
                    onChange={setData}
                >
                    <option value="0">
                        Selecciona...
                    </option>
                    {
                        list.map(document => <option value={document.id} key={document.id}>
                            {document.name}
                        </option>)
                    }
                </select>
            </div>
            <div className="col">
                <label>Fecha</label>
                <input
                    type="date"
                    className="form-control green-input"
                    name="date_sent"
                    placeholder="Digita una fecha"
                    value={dataFilter.date_sent}
                    maxLength="50"
                    onChange={setData}
                />
            </div>
            <div className="col">
                <label>Estado</label>
                <select
                    className="form-select green-input"
                    name="status"
                    value={dataFilter.status}
                    onChange={setData}
                >
                    <option value="">
                        Selecciona el estado
                    </option>
                    <option value="signed">
                        FIRMADO
                    </option>
                    <option value="pending">
                        NO FIRMADO
                    </option>
                </select>
            </div>

        </div>
    )
}

export default ToolFilterDocuments;