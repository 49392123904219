const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
}

export const generateDistinctColors = (numColors) => {
    const colors = [];
    const colorStep = 360 / numColors;

    for (let i = 0; i < numColors; i++) {
        const hue = i * colorStep;
        const saturation = getRandomInt(60, 100);
        const lightness = getRandomInt(40, 70);
        const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        colors.push(color);
    }

    return colors;
}