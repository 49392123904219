import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const customMailSlice = createSlice({
    name: 'customMailSlice',
    initialState: {
        configEmail: {
            id: 0,
            email: "",
            smtp: "",
            port: 0,
            username: "",
            password: "",
        },
        status: 'idle',
        error: null
    },
    reducers: {
        setFormConfigEmail: (state, action) => {
            const { key, value } = action.payload;
            state.configEmail[key] = value;
        },

        saveConfigEmail: (state, action) => {
          state.configEmail = action.payload;
      },        
    }
   
});

export const { setFormConfigEmail, saveConfigEmail } = customMailSlice.actions;

export default customMailSlice.reducer;