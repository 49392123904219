import { Accordion } from "react-bootstrap";

import ItemCardParameterization from "./ItemCardParameterization";

import Group from '../../assets/group.svg';

const ItemCardGroup = ({ list, nameParameterization, keyGroups, keyByGroup, insertCode }) => {
    return (
        <Accordion.Item eventKey={keyGroups}>
            <Accordion.Header className="p-0 accordion-header">
                <div className="container-fluid p-0 accordion-children-container">
                    <div className='row m-0 px-2'>
                        <div className='col-2 m-0 p-0'>
                            <i className="fa-solid fa-layer-group text-green" style={{ fontSize: 18 }}></i>
                        </div>
                        <div className='col-10 m-0 p-0'>
                            <span className='spanLetter'>Grupos</span>
                        </div>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body className="m-0 p-0 ps-3">
                {
                    list?.clusters?.map((cluster) => (
                        <Accordion.Item eventKey={`${keyByGroup}-${cluster.code_cluster}`} key={cluster.id}>
                            <Accordion.Header className="p-0 accordion-header">
                                <div className="container-fluid p-0 accordion-children-container">
                                    <div className='row m-0 px-2'>
                                        <div className='col-2 m-0 p-0'>
                                            <img src={Group} className="mb-0 personImage" alt="" />
                                        </div>
                                        <div className='col-10 m-0 p-0'>
                                            <small className='smallLetter'>Grupo</small>
                                            <br></br>
                                            <span className='spanLetter'>{cluster.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Header>

                            <Accordion.Body className="m-0 p-0">
                                <div className="row ms-3">
                                    {
                                        cluster[nameParameterization]?.map(
                                            p => (<ItemCardParameterization
                                                parameterization={p}
                                                key={p.id}
                                                onClick={() => insertCode(p.code_parameterization)}
                                            />))
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default ItemCardGroup;