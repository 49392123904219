import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { DocumentEditorContainerComponent, Editor, Selection, Toolbar, WordExport } from '@syncfusion/ej2-react-documenteditor';

import ModalError from './ModalError';

import GeneralService from '../../../../services/GeneralService';
import { base64ToBlob, blobToBase64, MessageConfirm, MessageError, MessageSuccess, toast } from '../../../../utils';
import { setGenerateDocument } from '../../../../reducers/documents/reducerGenerateDocument';
import { CHECK_CODES, HTTP_OK } from '../../../../constant/constant';
import { hideLoading, showLoading } from '../../../../reducers/main/loadingReducer';
import { isURL } from '../../../../utils/isUrl';
import { useDownloadFile } from '../../../../app/hooks/useDownloadFile';
import DocumentEditorService from '../../../../services/DocumentEditorService';

DocumentEditorContainerComponent.Inject(Toolbar, Selection, Editor, WordExport);

const TabText = ({ canEdit = true }) => {
    const { generateDocument, checkCodes } = useSelector(state => state.documentsAutoSlice);
    const [showWarningErrors, setShowWarningErrors] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [hasChange, setHasChange] = useState(0);
    const handleDownload = useDownloadFile();
    const { search } = useLocation();
    const { next = '/documents-auto/list' } = queryString.parse(search);
    let hostUrl = "https://services.syncfusion.com/react/production/api/documenteditor/";
    const container = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const rendereComplete = () => {
        window.onbeforeunload = () => {
            return "¡No olvides guardar los cambios del editor!";
        };
        container.current.documentEditor.pageOutline = "#E0E0E0";
        container.current.documentEditor.acceptTab = true;
        container.current.documentEditor.resize();
    };

    const loadContent = async (check_codes) => {
        dispatch(showLoading());
        const service = new GeneralService("generate-document/show/" + generateDocument.id);
        const res = await service.getRequest({ check_codes });
        if (res.is_ok) {
            const blob = base64ToBlob(res.file);
            const serviceDocument = new DocumentEditorService();
            const sfdtData = await serviceDocument.convertDocxToSfdt(blob);
            if (serviceDocument.status === HTTP_OK) {
                container.current.documentEditor.open(sfdtData);
            }
            dispatch(hideLoading());
            return;
        }

        dispatch(hideLoading());
        if (check_codes && res.errors) {
            setErrors(res.errors)
            setShowModalError(true);
            return
        }
        MessageError(res.message);
    }

    const generate = async () => {
        dispatch(showLoading());
        const service = new GeneralService("generate-document/create-pdf/" + generateDocument.id);
        const res = await service.post({}, { check_codes: checkCodes });
        dispatch(hideLoading());
        if (res.is_ok) {
            dispatch(setGenerateDocument({ value: res.generate_document }));
            handleDownload(res.generate_document.url_pdf);
            return;
        }
        setErrors(res.errors);
        setShowModalError(true);
    }

    const send = async () => {
        const confirm = await MessageConfirm("¿Estás realmente seguro de enviar este documento a tus destinatarios?");
        if (!confirm) return;

        if (hasChange > 2) {
            const confirm = await MessageConfirm("Se han detectado cambios en el documento. ¿Desea guardar el progreso?");
            if (confirm) await saveProgress();
        }
        dispatch(showLoading());
        if (!isURL(generateDocument.url_pdf)) {
            const service = new GeneralService("generate-document/create-pdf/" + generateDocument.id);
            const res = await service.post({}, { check_codes: checkCodes });
            if (!res.is_ok) {
                return MessageError(res.message, service.status);
            }
        }

        const service = new GeneralService("generate-document/send/" + generateDocument.id);
        const res = await service.post({});
        dispatch(hideLoading());
        if (res.is_ok) {
            await MessageSuccess(res.message);
            history.push(next);
            return;
        }
        MessageError(res.message, service.status);
    }

    const saveProgress = async () => {
        container.current.documentEditor.saveAsBlob('Docx').then(async (blobFile) => {
            const base64 = await blobToBase64(blobFile);
            const service = new GeneralService("generate-document/save-progress");
            const data = {
                id: generateDocument.id,
                content: base64,
            }
            const res = await service.post(data);
            if (res.is_ok) {
                MessageSuccess(res.message);
                setHasChange(0);
                return;
            }
            MessageError("No fue posible guardar el progreso.");
        });
    }

    const reload = async () => {
        await loadContent(CHECK_CODES);
        toast(dispatch, "Los datos han sido recargados");
    }

    const popover = (
        <Popover style={{ minWidth: "30rem" }}>
            <Popover.Header className="bg-orange text-center" as="h3">Errores encontrados</Popover.Header>
            <Popover.Body>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <p>Reemplaza los códigos por el valor correspondiente, o elimina el código y su contexto.</p>
                        </div>
                    </div>
                    {
                        errors?.map((error, i) => <div className="row" key={i}>
                            <div className="col">
                                <b>{i + 1}. <i>{error.name}: </i></b> @{error.code}
                            </div>
                        </div>)
                    }
                </div>
            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
        rendereComplete();
    }, []);

    useEffect(() => {
        if (generateDocument.id !== 0) loadContent(checkCodes);
    }, [generateDocument.id, checkCodes]);

    return (
        <>
            <div className="process-tab">
                <div className="row mb-2">
                    <div className="col">
                        <button disabled={!canEdit} title="Guardar contenido" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={saveProgress}>
                            <i className="fa-solid fa-floppy-disk text-white"></i>
                        </button>
                        <button disabled={!canEdit} title="Descargar documento" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={generate}>
                            <i className="fa-solid fa-file-arrow-down text-white"></i>
                        </button>
                        <button disabled={!canEdit} title="Enviar documento a los destinatarios" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={send}>
                            <i className="fa-solid fa-paper-plane text-white"></i>
                        </button>
                        <button disabled={!canEdit} title="Recargar datos" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={reload}>
                            <i className="fa-solid fa-arrows-rotate text-white"></i>
                        </button>
                    </div>
                    <div className="col">
                        {
                            errors?.length > 0
                                ?
                                <OverlayTrigger placement="left" overlay={popover} show={showWarningErrors}>
                                    <div className='mt-2' style={{ position: "relative", width: "30px", float: "right" }} onClick={() => setShowWarningErrors(c => !c)} >
                                        <i className="fa-solid fa-triangle-exclamation text-red fs-5"></i>
                                        <span className="position-absolute top-2 start-1 translate-middle badge rounded-pill bg-info p-1">
                                            {errors.length}
                                            <span className="visually-hidden">unread messages</span>
                                        </span>
                                    </div>
                                </OverlayTrigger>
                                :
                                null
                        }
                    </div>

                </div>
                <div className="row">
                    <div className="col">
                        <DocumentEditorContainerComponent
                            id="container"
                            ref={container}
                            style={{ display: "block" }}
                            height={"650px"}
                            serviceUrl={hostUrl}
                            enableToolbar={true}
                            locale="en-US"
                        />
                    </div>
                </div>
            </div>

            <ModalError show={showModalError} setShow={setShowModalError} errors={errors} />
        </>
    );
}

export default TabText;