export const saveProgress = ({ process, listAnswers, dataForm, listActors, optionsZapsign, checkCodes }) => {
    localStorage.setItem("process", JSON.stringify(process));
    localStorage.setItem("listAnswers", JSON.stringify(listAnswers));
    localStorage.setItem("dataForm", JSON.stringify(dataForm));
    localStorage.setItem("listActors", JSON.stringify(listActors));
    localStorage.setItem("optionsZapsign", JSON.stringify(optionsZapsign));
    localStorage.setItem("checkCodes", JSON.stringify(checkCodes));
}

export const loadInformation = (formId) => {
    const process = JSON.parse(localStorage.getItem("process"));
    if(!process) return {};
    
    if(process.form_id === formId) {
        return {
            process: JSON.parse(localStorage.getItem("process")),
            listAnswers: JSON.parse(localStorage.getItem("listAnswers")),
            dataForm: JSON.parse(localStorage.getItem("dataForm")),
            listActors: JSON.parse(localStorage.getItem("listActors")),
            optionsZapsign: JSON.parse(localStorage.getItem("optionsZapsign")),
            checkCodes: JSON.parse(localStorage.getItem("checkCodes")),
        };
    }

    return {};
}