import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { registerLicense, validateLicense } from '@syncfusion/ej2-base';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_KEY })
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

registerLicense(process.env.REACT_APP_SYNCFUSION);
validateLicense();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
