import { useDispatch } from "react-redux";

import IconButton from "../IconButton";

import { encodeFileBase64 } from "../../../modules/FillOutForm/utils/encondeBase64";
import { toast } from "../../../utils/toast";
import { MessageError } from "../../../utils/message";
import { fitText } from "../../../modules/FillOutForm/utils/fitText";
import { aceptFile } from "../../../modules/FillOutForm/utils/aceptFile";
import { isURL } from "../../../utils/isUrl";
import { useDownloadFile } from "../../hooks/useDownloadFile";


const FieldTypeFile = ({ param, location, value, onChange }) => {
    const dispatch = useDispatch();
    const handleDownload = useDownloadFile();

    const encodeDocument = async (e, param) => {
        const file = e.target.files[0].name.split('.');
        const ext = file[file.length - 1];

        const base64 = await encodeFileBase64(e.target.files[0]);
        if (base64) {
            const archivo = `${base64}--EXTENSION--${ext}`;
            const { id, ...other } = param;
            const value = e.target.value.split("\\").pop();

            onChange({
                parameterization_id: param.id,
                doc: archivo,
                value,
                ...other,
            });

            toast(dispatch, `Archivo seleccionado`);
            return;
        }
        MessageError("No es un documento válido");
    }

    const clearFile = () => {
        const { id, ...other } = param;
        onChange({
            parameterization_id: param.id,
            doc: "",
            value: "",
            ...other,
        });
    }

    return (
        value !== "" ? (
            <div role="button" className="col ps-2 pe-1 pt-1 rounded-2 bg-light-green d-flex justify-content-between" style={{ maxHeight: "35px" }}>
                <div>
                    <i className="fa-solid fa-paperclip text-purple me-2"></i>
                    <span className="fs-7">{
                        isURL(value)
                            ?
                            <div className="d-inline-block text-blue" onClick={() => handleDownload(value)}>Click para mostrar archivo</div>
                            :
                            fitText(value, 25)}
                    </span>
                </div>
                <IconButton
                    title="Remover archivo"
                    icon="fa-solid fa-xmark-circle text-red"
                    className="pt-0"
                    onClick={clearFile}
                />
            </div>
        ) : (
            <input
                className="form-control parametrizacion__form-input"
                type={'file'}
                name={param.name}
                required={param.required}
                onChange={(e) => encodeDocument(e, param)}
                aria-label={location}
                accept={aceptFile(param.type_files_id)}
            />
        )
    );
}

export default FieldTypeFile;