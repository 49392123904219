
import IconButton from '../../app/components/IconButton';
import TimeoutImage from '../../assets/timeout.svg';

export const TimeOutErrorView = () => {
  return (
    <div className="container-fluid max-height-overflow-y d-flex flex-column justify-content-center align-items-center font-lato">
      <h4 className='mt-4 fw-bold'>¡Tiempo de espera agotado!</h4>

      <img src={TimeoutImage} height={300} />
      <h5 className='mt-2'>No hemos podido procesar tu solicitud :(</h5>

      <p>Parece que no tienes conexión o tu señal es débil, te pedimos que refresques este sitio.</p>

      <IconButton
        title="Refrescar"
        icon='fa-solid fa-arrows-rotate text-green fs-2'
        onClick={() => window.location.href = "/"}
      />
      <div className="d-block mt-3"></div>
    </div>
  )
}
