
export const validateExistingCodes = async (data, codesActors = [], codesQuestions = []) => {
    const codes = [...codesActors, ...codesQuestions];
    let content = data;

    const foundCodes = content.match(/({{)?(&amp;NC)?@\w+(#\w+)?(}})?/g);
    let errors = 0;

    if (foundCodes) {
        foundCodes.forEach((invalidCode) => {
            const position = content.indexOf(invalidCode);
            if (content.charCodeAt(position - 1) !== 32 && content.charCodeAt(position - 1) !== 62) {
                return;
            }
            if (content.includes(`<span style="color: rgb(224, 62, 45);">${invalidCode}</span>`)) {
                return;
            }
            if (!codes.includes(invalidCode)) {
                const pattern = new RegExp(invalidCode, 'gi');
                content = content.replace(pattern, `<span style="color: rgb(224, 62, 45);">${invalidCode}</span>`);
                errors++;
            }
        });

        return { content, errors };
    }
    return { content, errors: 1 };
}

export const extractFormCodes = (form) => {
    let codes = [];

    form.clusters?.forEach(cluster => {
        cluster.parameterizations?.forEach(parameterization => {
            codes.push("@"+parameterization.code_parameterization);
            codes.push("&amp;NC@"+parameterization.code_parameterization);
        });
    });
    form.actors?.forEach(actor => {
        actor.parameterizations?.forEach(parameterization => {
            codes.push("@"+parameterization.code_parameterization);
            codes.push("&amp;NC@"+parameterization.code_parameterization);
        });
    });

    return codes;
}

export const extractQuestionCodes = (question) => {
    let codes = [];
    if (question.code_questions) {
        codes.push("@" + question.code_questions.code);
    }

    return codes;
}