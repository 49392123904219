import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BreadcrumbCustom from "../../../../app/components/BreadcrumCustom";
import ListGeneratedDocuments from "./ListGeneratedDocuments";
import ToolFilterDocuments from "./ToolFilterDocuments";

import GeneralService from "../../../../services/GeneralService";
import { applyFilters, fillElatedDocuments } from "../../../../reducers/people/reducerPersonDocument";
import { CustomPagination } from "../../../../app/components/CustomPagination";
import { toast } from "../../../../utils";

const InProgressView = () => {
    const { dataFilter } = useSelector(state => state.personDocumentSlice);
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });
    const dispatch = useDispatch();

    const loadDocuments = async () => {
        const service = new GeneralService("generate-document");
        const res = await service.getList(0, { ...pagination, documents_auto_id: dataFilter.type_document });
        const { generate_documents = [], total_pages = 0 } = res;
        dispatch(fillElatedDocuments({ value: generate_documents }));
        setPagination({ ...pagination, total_pages });
    }

    const handleReload = () => {
        loadDocuments();
        toast(dispatch, "Información actualizada");
    }

    useEffect(() => {
        loadDocuments();
    }, [pagination.page, pagination.page_size, dataFilter.type_document]);

    useEffect(() => {
        dispatch(applyFilters({ value: { isPerPerson: false, isNoSigned: true } }));
    }, [dataFilter]);

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row m-0 p-0">
                <div className="col p-0">
                    <BreadcrumbCustom
                        title="Documentos en progreso"
                        activeRoute={"Listado"}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ToolFilterDocuments onReload={handleReload} showExportButton />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ListGeneratedDocuments />
                </div>
            </div>
            <CustomPagination
                pagination={pagination}
                setPagination={setPagination}
            />
        </div>
    )
}

export default InProgressView;