import { createSlice } from '@reduxjs/toolkit';

export const viewsSlice = createSlice({
    name: 'viewsSlice',
    initialState: {
        list: {},
        listFilter: {},
        listCountries: [],
        selectedCountry: "",
        selectedRegion: "",
        totalViewsSortom: 0,
        totalViewsNotary: 0,
        regions: {},
        forms: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = { ...state.list, ...payload };
            state.listFilter = { ...state.list, ...payload };

            const countries = [];
            state.list.sortom?.forEach(item => {
                item.countries?.forEach(countryFromItem => {
                    if (!countries.some(country => country === countryFromItem.name)) {
                        countries.push(countryFromItem.name);
                        state.regions = { ...state.regions, [countryFromItem.name]: countryFromItem.regions }
                    } else {
                        countryFromItem.regions?.forEach(itemRegion => {
                            if (!state.regions[countryFromItem.name]?.some(region => region === itemRegion)) {
                                state.regions[countryFromItem.name] = [...state.regions[countryFromItem.name], itemRegion]
                            }
                        });
                    }
                })
            });
            state.list.notary?.forEach(item => {
                item.countries?.forEach(countryFromItem => {
                    if (!countries.some(country => country === countryFromItem.name)) {
                        countries.push(countryFromItem.name);
                        state.regions = { ...state.regions, [countryFromItem.name]: countryFromItem.regions }
                    } else {
                        countryFromItem.regions?.forEach(itemRegion => {
                            if (!state.regions[countryFromItem.name]?.some(region => region === itemRegion)) {
                                state.regions[countryFromItem.name] = [...state.regions[countryFromItem.name], itemRegion]
                            }
                        });
                    }
                })
            });

            let listForms = [];
            state.list.notary?.forEach(item => {
                item.forms?.forEach(itemForm => {
                    if (!listForms.some(form => form.id == itemForm.id)) {
                        listForms.push(itemForm);
                    } else {
                        listForms = listForms.map(f => {
                            if (f.id === itemForm.id) {
                                return {
                                    ...f,
                                    count: f.count + itemForm.count,
                                }
                            }
                            return f;
                        })
                    }
                })
            });
            state.listForms = listForms;

            state.listCountries = countries;
            state.selectedCountry = "";
            state.selectedRegion = "";

            let totalSortom = 0;
            let totalNotary = 0;
            state.list.sortom?.forEach(item => { totalSortom += item.count });
            state.list.notary?.forEach(item => { totalNotary += item.count });

            state.totalViewsSortom = totalSortom;
            state.totalViewsNotary = totalNotary;
        },
        setFilter: (state, { payload }) => {
            state[payload.key] = payload.value;
            if (payload.key === "selectedCountry") state.selectedRegion = "";
        },
        applyFilters: (state, action) => {
            const filterCountry = (item) => state.selectedCountry ? item.countries.some(country => country.name === state.selectedCountry) : true;
            const filterRegion = (item) => state.selectedRegion ? item.countries.some(country => country.regions?.some(region => region === state.selectedRegion)) : true;

            const sortom = state.list.sortom?.filter(filterCountry).filter(filterRegion);
            const notary = state.list.notary?.filter(filterCountry).filter(filterRegion);

            state.listFilter = { sortom, notary };

            let listForms = [];
            notary?.forEach(item => {
                item.forms?.forEach(itemForm => {
                    if (!listForms.some(form => form.id == itemForm.id)) {
                        listForms.push(itemForm);
                    } else {
                        listForms = listForms.map(f => {
                            if (f.id === itemForm.id) {
                                return {
                                    ...f,
                                    count: f.count + itemForm.count,
                                }
                            }
                            return f;
                        })
                    }
                })
            });
            state.listForms = listForms;
        },
    }
});

export const {
    setList,
    setFilter,
    applyFilters,
} = viewsSlice.actions;

export default viewsSlice.reducer;