import { createSlice } from '@reduxjs/toolkit';

export const clusterCategoryLegalCaseSlice = createSlice({
  name: 'clusterCategoryLegalCaseSlice',
  initialState: {
    list: [],
    listFilter: [],
    isLoading: true,
    selectedCluster: {
      id: 0,
      name: "",
      category_legal_case_id: 0,
    },
  },
  reducers: {
    reducerForm: (state, action) => {
      state.selectedCluster[action.payload.key] = action.payload.value;
    },
    searchCluster: (state, action) => {
      if (action.payload.value === "") {
        state.listFilter = state.list;
        return;
      }
      const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
      state.listFilter = filtered;
    },
    setCluster: (state, action) => {
      state.list = action.payload.value
      state.isLoading = false
    },
    setListCluster: (state, action) => {
      state.list = action.payload.value;
      state.listFilter = action.payload.value;
      state.isLoading = false
    },
    deleteCluster: (state, action) => {
      const newList = state.list.filter(e => e.id !== action.payload.value)
      state.list = newList;
      state.listFilter = newList;
    },
    //Para editar el grupo en Modal
    setSelectedCluster: (state, action) => {
      state.selectedCluster = action.payload.value;
    },
    addCluster: (state, action) => {
      state.list.push(action.payload.value);
      state.listFilter.push(action.payload.value);
    },
    updateCluster: (state, action) => {
      const group = action.payload.value
      const modificadaList = state.list.map((e) => {
        if (e.id === group.id) {
          return group
        }
        return e;
      });
      state.list = modificadaList;
      state.listFilter = modificadaList;
    },
    cleanData: (state, action) => {
      state.selectedCluster = {
        id: 0,
        name: "",
      };
    },
  },
})

export const {
  reducerForm,
  searchCluster,
  cleanData,
  setCluster,
  setListCluster,
  setData,
  deleteCluster,
  setSelectedCluster,
  addCluster,
  updateCluster,
} = clusterCategoryLegalCaseSlice.actions

export default clusterCategoryLegalCaseSlice.reducer