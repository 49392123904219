import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import { TYPE_ANSWER } from "../../../constant/constant";
import { addListAnswers, reducerSelectedGenerateAnswer, updateGenerateAnswer } from "../../../reducers/forms/reducerCreateProcessForm";
import { toast } from "../../../utils/toast";

const ModalOpenQuestion = ({ show, setShow, handleScrollAnswers }) => {
    const { selectedGenerateAnswer, listAnswers } = useSelector(state => state.createProcessFormSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerSelectedGenerateAnswer({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (listAnswers.some(answer => answer.code_question_id === selectedGenerateAnswer.code_question_id)) {
            dispatch(updateGenerateAnswer({ value: selectedGenerateAnswer }));
            toast(dispatch, "Respuesta editada correctamente");
        } else {
            dispatch(addListAnswers({ value: selectedGenerateAnswer }));
            toast(dispatch, "Respuesta agregada correctamente");
            handleScrollAnswers();
        }

        setShow(false);
    };

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on" className="p-0">
                <Modal.Header className='bg-green pe-4' closeButton>
                    {
                        selectedGenerateAnswer.edit ?
                            <i className="fa-solid fa-pen-to-square animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                            :
                            <i className="fa-solid fa-circle-plus animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                    }
                    <h5 className="mt-2 ms-2">
                        {
                            selectedGenerateAnswer.edit ?
                                "Editar"
                                :
                                "Crear"
                        } Respuesta
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">

                        <div className="row m-0 p-0">
                            <div className='mb-3'>
                                <b>{selectedGenerateAnswer.type === TYPE_ANSWER ? "Tipo respuesta" : "Pregunta"}:</b> {selectedGenerateAnswer.name}
                            </div>
                            <div className='mb-3'>
                                <label>Respuesta<b><i className="text-red">*</i></b></label>
                                <textarea
                                    className="form-control custom-textarea mt-1"
                                    name="answer_text"
                                    value={selectedGenerateAnswer.answer_text}
                                    maxLength={500}
                                    placeholder="Ingrese una respuesta"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedGenerateAnswer.edit ?
                                "Actualizar"
                                :
                                "Agregar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalOpenQuestion;