import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import { ID_NOT_DEFINED, TYPE_ANSWER } from "../../../../constant/constant";
import { addListAnswers, reducerSelectedGenerateAnswer, updateGenerateAnswer } from "../../../../reducers/documents/reducerGenerateDocument";
import { toast } from "../../../../utils/toast";
import GeneralService from "../../../../services/GeneralService";
import { MessageError } from "../../../../utils/message";

const ModalOpenQuestion = ({ show, setShow, handleScrollAnswers }) => {
    const { selectedGenerateAnswer } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerSelectedGenerateAnswer({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedGenerateAnswer.edit) {
            if (selectedGenerateAnswer.id !== ID_NOT_DEFINED) {
                const service = new GeneralService("answers-questinos-generate");
                const res = await service.update(selectedGenerateAnswer);
                if (!res.is_ok) {
                    MessageError(res.message, service.status);
                    return;
                }
            }
            dispatch(updateGenerateAnswer({ value: selectedGenerateAnswer }));
            setShow(false);
            toast(dispatch, "Respuesta editada correctamente");
            return;
        }
        dispatch(addListAnswers({ value: selectedGenerateAnswer }));
        setShow(false);
        toast(dispatch, "Respuesta agregada correctamente");
        handleScrollAnswers();
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedGenerateAnswer.edit ?
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedGenerateAnswer.edit ?
                                            "Editar"
                                            :
                                            "Crear"
                                    } Respuesta
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">

                        <div className="row m-0 p-0">
                            <div className='mb-3'>
                                <b>{selectedGenerateAnswer.type === TYPE_ANSWER ? "Tipo respuesta" : "Pregunta"}:</b> {selectedGenerateAnswer.name}
                            </div>
                            <div className='mb-3'>
                                <label>Respuesta<b><i className="text-red">*</i></b></label>
                                <textarea
                                    className="form-control custom-textarea mt-1"
                                    name="answer_text"
                                    value={selectedGenerateAnswer.answer_text}
                                    maxLength={500}
                                    placeholder="Ingrese una respuesta"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedGenerateAnswer.edit ?
                                "Actualizar"
                                :
                                "Agregar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalOpenQuestion;