import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import GeneralService from "../../../services/GeneralService";
import { setList } from "../../../reducers/business/reducerViews";
import { CardTotalVisits } from "./CardTotalVisits";
import IconButton from "../../../app/components/IconButton";
import { toast } from "../../../utils";
import { SHOW } from "../../../constant/constant";
import { sortByDate } from "../utils/sort";

export const LogViews = () => {
    const { totalViewsSortom, totalViewsNotary } = useSelector(state => state.viewsSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const loadViews = async (showConfirmation = false) => {
        const service = new GeneralService("log-visitor");
        const { content: sortom = [] } = await service.getList(0, { is_sortom: true });
        dispatch(setList({ sortom: sortByDate(sortom) }));

        const { content: notary = [] } = await service.getList(0, { is_sortom: false });
        dispatch(setList({ notary: sortByDate(notary) }));

        if(showConfirmation) toast(dispatch, "Información actualizada");
    }

    useEffect(() => {
        loadViews();
    }, []);

    return (
        <div className="d-block ps-3">
            <h5 className="font-lato mb-4 fw-bold d-inline-block">Vistas</h5>
            <IconButton
                icon="fa-solid fa-arrows-rotate text-green"
                onClick={()=> loadViews(SHOW)}
            />

            <div className="d-flex flex-row">
                <CardTotalVisits title="Sortom" icon="fa-s" total={totalViewsSortom} />
                <CardTotalVisits title="Notaría" icon="fa-building-columns" total={totalViewsNotary} />
                <div className="card-views justify-content-center cursor-pointer" onClick={() => history.push("/visits")}>
                    <i className="fa-solid fa-circle-info"></i>
                    <div className="fs-8 mt-2">Ver detalles</div>
                </div>
            </div>
        </div>
    )
}
