

const FieldTypeDefault = ({ param, location, value, onChange, onInformation, className }) => {
    const inputTypeMap = {
        3: 'date',
        4: 'time',
        5: 'datetime-local',
        7: 'email'
    };

    const inputType = inputTypeMap[param.type_fields_id];

    const handleChange = (e) => {
        const { id, ...other } = param;
        const value = e.target.value;

        onChange({
            parameterization_id: param.id,
            value,
            ...other,
        });
        if (param.required) {
            if (inputType === "email" && !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
                onInformation({ show: true, message: "El email no es válido", isError: true });
                return;
            }
            if (inputType === "date" && value === "") {
                onInformation({ show: true, message: "La fecha no es válida", isError: true });
                return;
            }
            if (inputType === "time" && value === "") {
                onInformation({ show: true, message: "La hora no es válida", isError: true });
                return;
            }
            if (inputType === "datetime-local" && value === "") {
                onInformation({ show: true, message: "La fecha y hora no son válidas", isError: true });
                return;
            }
        }
        onInformation({ show: false, message: "", isError: false });
    }

    return (
        <input
            className={`form-control parametrizacion__form-input ${className}`}
            autoComplete="off"
            value={value}
            type={inputType}
            name={param.name}
            required={param.required}
            placeholder="Ingresa un valor"
            aria-label={location}
            onChange={handleChange}
        />
    )
}

export default FieldTypeDefault;