import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { SelectAndCreate } from './SelectAndCreate';
import { SearchBarPeople } from './SearchBarPeople';
import DynamicTable from './DynamicTable';

import { ALERT_DANGER } from '../../../constant/constant';
import { toast } from '../../../utils/toast';
import { addSelectedActors, deleteSelectedActors } from '../../../reducers/documents/reducerGenerateDocument';
import confirmDelete from '../../../utils/confirmDelete';
import IconButton from '../../../app/components/IconButton';
import { valueInputPeople } from '../../DocumentsAuto/utils/functions';
import { reducerForm } from '../../../reducers/people/reducerPersonDocument';

const ModalFilterByPerson = ({ show, setShow }) => {
    const { selectedPeople } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();

    const handleSelectedPerson = async (person) => {
        if (selectedPeople.some(item => item.people_id === person.id)) {
            toast(dispatch, "Actor ya agregado", ALERT_DANGER);
            return;
        }
        dispatch(addSelectedActors({
            value: {
                id: 0,
                people_id: person.id,
                people: person,
            }
        }));
    }

    const onHide = () => {
        dispatch(reducerForm({
            key: "actors",
            value: selectedPeople,
        }));
        setShow(false);
    }

    const removeActor = async (person) => {
        toast(dispatch, "Actor removido");
        dispatch(deleteSelectedActors({ value: person.people_id }));
    }

    return (
        <Modal show={show} onHide={onHide} size='xl'>
            <Modal.Header className='bg-green pe-4' closeButton>
                <div className='container'>
                    <div className='row'>
                        <div className='col-1'>
                            <i className="fa-solid fa-filter mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                        </div>
                        <div className='col'>
                            <h5 className="mt-2">
                                Filtrar por persona
                            </h5>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8">
                            <div className='row'>
                                <SelectAndCreate showCreateButton={false} />
                            </div>
                            <div className='row mt-3'>
                                <SearchBarPeople forParameterization={false} />
                            </div>
                            <div className='row'>
                                <DynamicTable
                                    actionName="Seleccionar"
                                    forParameterization={false}
                                    onPersonSelected={handleSelectedPerson}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="actors-selected__tab">
                                <h5 className="text-center" style={{ color: "#5484FF" }}><b>Seleccionados</b></h5>
                                <hr style={{ color: "green" }} />
                                {
                                    selectedPeople.length === 0 &&
                                    <div className="alert alert-warning text-center mt-2" role="alert">
                                        No hay personas seleccionadas
                                    </div>
                                }
                                {
                                    selectedPeople.map(person => <div className="animate__animated animate__fadeInLeft" key={person.people_id}>
                                        <div className="row">
                                            <div className="col-9">{person.people?.name}</div>
                                            <div className="col">
                                                <IconButton
                                                    icon="fa-solid fa-trash text-redligth"
                                                    title="Eliminar del filtro"
                                                    onClick={async () => await confirmDelete(() => removeActor(person))}
                                                />
                                            </div>
                                        </div>
                                        <hr className="mt-2 text-purple" />
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onHide} className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                    Listo
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalFilterByPerson;