
export const CardTotalVisits = ({ title, icon, total }) => {
    return (
        <div className="card-views justify-content-between me-2">
            <i className={`fa-solid ${icon}`}></i>
            <div className="fs-6">{title}</div>
            <div className="fw-bold">{total}</div>
        </div>
    )
}
