import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalEditName } from './ModalEditName';
import { DropFiles } from './DropFiles';
import { CardFileUploaded } from './CardFileUploaded';

import GeneralService from '../../../services/GeneralService';
import { uploadFilesFirebase, deleteFilesFirebase } from '../../../firebase/firebaseStorage';
import { MessageError, MessageSuccess, toast } from '../../../utils';
import { setFilesFirebase, deleteFileOfUsers, setSelectedFile } from '../../../reducers/storageUser/reducerStorageUser';
import { hideLoading, showLoading } from '../../../reducers/main/loadingReducer';
import confirmDelete from '../../../utils/confirmDelete';
import '../styles/drop-files.css';
import { useDownloadFile } from '../../../app/hooks/useDownloadFile';

const StorageUser = () => {
    const { dataUser } = useSelector(state => state.userSlice);
    const { readFiles } = useSelector(state => state.storageUserSlice);
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleDownload = useDownloadFile();
    const service = new GeneralService("storage-user");

    const handleSubmit = async () => {
        dispatch(showLoading());
        const path = `storage-user/business_id_${dataUser.business_id}/profile_id_${dataUser.id}`;
        const { is_ok, listUpload, message } = await uploadFilesFirebase(files, path);
        if (is_ok) {
            const res = await uploadFilesBD(listUpload);

            if (res.is_ok) MessageSuccess('¡Los archivos se cargaron correctamente :)!');
            else MessageError("Error inesperado", res.message);

            await getFilesOfUsers();
        } else {
            await MessageError(message);
        }

        dispatch(hideLoading());
        setFiles([]);
    }

    const uploadFilesBD = async (files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const data = {
                url: `https://storage.googleapis.com/${file.metadata.bucket}/${file.metadata.fullPath}`,
                name: file.metadata.name,
                profile_id: dataUser.id,
                megabytes_used_space: file.metadata.size / (1024 * 1024),
            }
            const res = await service.post(data);
            if (!res.is_ok) return res;
        }

        return { is_ok: true };
    }

    //Listar todos los archivos desde la BD
    const getFilesOfUsers = async () => {
        const res = await service.getList(1000, { profile_id: dataUser.id });
        const { storage_user = [] } = res;
        let files = storage_user.map(item => ({
            ...item,
            path: item.url.replace('https://storage.googleapis.com/npg-storage.appspot.com/', ''),
            type: item.name.split('.').pop(),
        }))
        dispatch(setFilesFirebase({ value: files }));
    }

    const deleteFile = async (path, id) => {
        const success = await deleteFilesFirebase(path);
        if (success) {
            const res = await service.delete(id);
            if (res.is_ok) {
                toast(dispatch, res.message);
                dispatch(deleteFileOfUsers({ value: id }));
                return;
            }
            MessageError(res.message, service.status);
        }
    }

    useEffect(() => {
        getFilesOfUsers();
    }, [dataUser.id]);

    return (
        <>
            <div className='container-fluid box'>
                <div className='row'>
                    <div className="col-12 col-lg-4 col-xl-4 box-upload-files">
                        <h5 className="box-header mb-4 font-lato">Cargar Archivos</h5>
                        <DropFiles files={files} setFiles={setFiles} handleSubmit={handleSubmit} />
                    </div>

                    <div className="col-12 col-lg-8 col-xl-7 ms-2">
                        <h5 className="font-lato mb-3">Archivos Cargados</h5>
                        <div className="uploaded-files">
                            {
                                readFiles?.length === 0 ?
                                    <div className="alert alert-warning" role="alert" style={{ marginBottom: '0px', margin: '10px 0px' }}>
                                        ¡No hay archivos cargados en la nube!
                                    </div>
                                    :
                                    readFiles.map((file) => <CardFileUploaded
                                        key={file.id}
                                        file={file}
                                        onDelete={() => confirmDelete(() => deleteFile(file.path, file.id))}
                                        onEdit={() => {
                                            dispatch(setSelectedFile({ value: file }));
                                            setShow(true);
                                        }}
                                        onDownload={() => handleDownload(file.url, file.name)}
                                    />)
                            }
                        </div>
                    </div>
                </div >
            </div >
            <ModalEditName show={show} setShow={setShow} onSubmited={getFilesOfUsers} />
        </>
    );
}

export default StorageUser;
