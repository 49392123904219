import { ProgressBar } from 'react-bootstrap'

const UsagePercentageCard = ({ title = "", icon = "", total = 1, used = 0 }) => {
    let now = (used / total) * 100;
    now = now.toFixed(1);

    return (
        <div className="usage__percentage-card font-lato">
            <div>
                <div className="center-vertical">
                    <i className={`${icon} me-2 fs-5`}></i>
                    <span className='fs-7'></span>
                    {title}
                </div>
                <div className="usage__percentage-counter">
                    {now} %
                </div>
            </div>
            <div className="text-center fw-bold fs-8">
                {used}/{total}
            </div>
            <ProgressBar
                animated
                striped
                variant={now < 90 ? "info" : now >= 90 && now <= 98 ? "warning" : "danger"}
                now={now}
                label={`${now}%`}
                style={{ height: "10px" }}
            />
        </div>
    )
}

export default UsagePercentageCard;