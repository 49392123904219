import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from "react-router-dom";
import queryString from 'query-string';

import GeneralService from '../../../../services/GeneralService';
import { cleanData, setDataPeopleOnEdit, setListCategoriesOnEdit, setPeopleId } from '../../../../reducers/people/reducerPeople';
import { extractDataAndCategories, extractFieldsTypepeople } from "../../utils/extract";
import { hideLoading, showLoading } from "../../../../reducers/main/loadingReducer";
import { HTTP_OK, ID_NOT_DEFINED } from '../../../../constant/constant';
import { MessageError, MessageSuccess } from '../../../../utils/message';
import { validateDataForm } from '../../../FillOutForm/utils/extract';
import { setSelectedTypepeople } from '../../../../reducers/typePeople/reducerTypepeople';

const usePeople = () => {
    const { dataPeople } = useSelector(state => state.peopleSlice);
    const { selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    let { id = 0 } = useParams();
    const { search } = useLocation();
    const { next } = queryString.parse(search);


    const loadInitialValuesForm = () => {
        const data = {};
        const fieldsData = extractFieldsTypepeople(selectedTypepeople);

        fieldsData.map(param => {
            if (!param.required && param.alert_required && !param.is_category) {
                data[param.name] = {
                    value: "",
                    parameterization_id: param.id,
                    ...param
                }
            }
        });

        dispatch(setDataPeopleOnEdit({ value: data }));
    }

    const extraerParam = async () => {
        const servicePeople = new GeneralService("people/" + id);
        const people = await servicePeople.getRequest();
        if (servicePeople.status === HTTP_OK) {
            const serviceTypepeople = new GeneralService("typepeople/" + people.type_peoples_id);
            const typePeople = await serviceTypepeople.getRequest();
            dispatch(setSelectedTypepeople({ value: typePeople }));

            const fieldsData = extractFieldsTypepeople(typePeople);
            const { data, categories } = extractDataAndCategories(people.data_peoples, fieldsData, typePeople);

            fieldsData.map(param => {
                if (!param.required && param.alert_required) {
                    if (!param.is_category && !data[param.name]) {
                        data[param.name] = {
                            value: "",
                            parameterization_id: param.id,
                            ...param
                        }
                    }
                    if (param.is_category && !data[param.name] && categories.some(c => c.id === param.parent_id)) {
                        data[param.name] = {
                            value: "",
                            parameterization_id: param.id,
                            ...param
                        }
                    }
                }
            });

            dispatch(setListCategoriesOnEdit({ value: categories }));
            dispatch(setDataPeopleOnEdit({ value: data }));
        }
    }

    const saveDataPeople = async (e) => {
        e.preventDefault();
        const service = new GeneralService("people");

        const { listData, omit, isOk } = await validateDataForm(dataPeople);
        if (!omit || !isOk) {
            return false;
        }
        const data_peoples = listData;

        dispatch(showLoading());
        if (id === ID_NOT_DEFINED) {
            const res = await service.post({ type_peoples_id: selectedTypepeople.id, data_peoples });
            dispatch(hideLoading());
            if (res.is_ok) {
                MessageSuccess(res.message);
                dispatch(cleanData());
                if (next) history.push(next + "&people=" + res.people?.id);
                else history.push("/people/list");

                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update({ id, data_peoples });
        dispatch(hideLoading());
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(cleanData());
            history.push(next ? next : "/people/list");
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        if (id !== ID_NOT_DEFINED) {
            dispatch(setPeopleId({ value: parseInt(id) }));
            extraerParam();
            return;
        }
        return () => {
            dispatch(setPeopleId({ value: ID_NOT_DEFINED }));
        }
    }, [id]);

    useEffect(() => {
        if (id === ID_NOT_DEFINED) {
            loadInitialValuesForm();
        }
    }, [selectedTypepeople]);

    return {
        id,
        saveDataPeople,
    }
}

export default usePeople;