import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import BreadcrumbCustom from "../../app/components/BreadcrumCustom";
import { ListUsers } from "./components/ListUsers";
import SearchUsers from "./components/SearchUsers";

import { cleanData, setUsers } from "../../reducers/user/reducerUsers";
import GeneralService from "../../services/GeneralService";
import { setRole } from "../../reducers/roles/reducerRoles";
import { CustomPagination } from "../../app/components/CustomPagination";

export const UsersView = () => {
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });
    const dispatch = useDispatch();
    const history = useHistory();

    const loadUsers = async () => {
        const service = new GeneralService("user");
        const res = await service.getList(0, pagination);
        const { profile = [], total_pages = 0 } = res;
        dispatch(setUsers(profile));
        setPagination({ ...pagination, total_pages });
    }

    const getRole = async () => {
        const service = new GeneralService("roles");
        const data = await service.getList(0);
        const { roles = [] } = data;
        dispatch(setRole(roles));
    }

    const handleCreate = () => {
        dispatch(cleanData());
        history.push("/users/create")
    }

    useEffect(() => {
        getRole();
    }, []);

    useEffect(() => {
        loadUsers();
    }, [pagination.page, pagination.page_size]);

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Usuarios"
                        activeRoute="Listar usuarios"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6 p-0 mt-1">
                    <SearchUsers />
                </div>
                <div className="col-6">
                    <button title="Crear nuevo documento" className="btn btn-success rounded-pill mt-1" type="button" onClick={handleCreate}>
                        <i className="fa-solid fa-person-circle-plus text-white me-2" style={{ fontSize: '16px' }} />
                        Crear
                    </button>
                </div>
            </div>
            <div className="table-data" >
                <ListUsers />
            </div>
            <CustomPagination
                pagination={pagination}
                setPagination={setPagination}
            />
        </div >
    );
}