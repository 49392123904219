import { createSlice } from '@reduxjs/toolkit';

export const categoryLegalCaseSlice = createSlice({
  name: 'categoryLegalCaseSlice',
  initialState: {
    list: [],
    listFilter: [],
    isLoading: false,
    selectedCategory: {
      id: 0,
      name: "",
      description: "",
    },
  },
  reducers: {
    reducerForm: (state, action) => {
      state.selectedCategory[action.payload.key] = action.payload.value;
    },
    setListCategory: (state, action) => {
      state.list = action.payload.value
      state.listFilter = action.payload.value
      state.isLoading = false
    },
    deleteCategory: (state, action) => {
      const newList = state.list.filter(e => e.id !== action.payload.value)
      state.list = newList;
      state.listFilter = newList;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload.value ?? { id: 0, name: "", description: "" };
    },
    editCateogry: (state, action) => {
      state.selectedCategory[action.payload.key] = action.payload.value;
    },
    addCategory: (state, action) => {
      state.list.push(action.payload.value);
      state.listFilter.push(action.payload.value);
    },
    // Actualizar los campos después de editar
    updateCategory: (state, action) => {
      const categoria = action.payload.value
      const modificadaList = state.list.map((e) => {
        if (e.id === categoria.id) {
          return categoria
        }
        return e;
      });
      const modificadaListFilter = state.listFilter.map((e) => {
        if (e.id === categoria.id) {
          return categoria
        }
        return e;
      });
      state.list = modificadaList;
      state.listFilter = modificadaListFilter;
    },
    // Buscar en el input el nombre de la categoría 
    searchCategory: (state, action) => {
      if (action.payload.value === "") {
        state.listFilter = state.list;
        return;
      }
      const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
      state.listFilter = filtered;
    },
    cleanData: (state, action) => {
      state.selectedCategory = {
        id: 0,
        name: "",
        description: "",
      };
    }

  }
});

export const {
  reducerForm,
  cleanData,
  setListCategory,
  addCategory,
  updateCategory,
  searchCategory,
  deleteCategory,
  setSelectedCategory,
  editCateogry,
} = categoryLegalCaseSlice.actions;

export default categoryLegalCaseSlice.reducer;