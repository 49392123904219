import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";

import serviceBusiness from '../services/serviceBusiness';
import { addNewObjectToList, cleanData, reducerForm, setDataGetById } from '../../../reducers/business/reducerBusiness';
import { MessageError, MessageSuccess } from "../../../utils/message";
import { ID_NOT_DEFINED } from "../../../constant/constant";

const FormBusiness = () => {
    const { dataBusiness, listPlans } = useSelector(state => state.businessSlice);
    const { listTypeDocument } = useSelector(state => state.userSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    let { id = 0 } = useParams();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    useEffect(() => {
        if (id !== 0) { dataUpdate(id) }
    }, [id]);

    const dataUpdate = async (id) => {
        const res = await serviceBusiness.getById(id);
        if (Object.keys(res).length > 0) {
            dispatch(setDataGetById({ value: res }));
            return;
        }
        MessageError("No es un ID de usuario válido");
        history.push("/users/list");
    }

    useEffect(() => {
        if (listPlans?.length === 0 && listTypeDocument?.length === 0) {
            history.push('/business/list');
        }
    }, [listPlans, listTypeDocument]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (id === 0) {
            const data = {
                type_document_id: parseInt(dataBusiness.type_document_id),
                plans_id: parseInt(dataBusiness.plans_id),
                num_document: dataBusiness.num_document,
                name: dataBusiness.name,
                email: dataBusiness.email,
            }
            const res = await serviceBusiness.save(data);
            if (res.is_ok) {
                MessageSuccess(res.message);
                dispatch(addNewObjectToList({ value: res.business }));
                history.push("/business/list");
                return;
            }
            MessageError(res.message); // TODO: Change by GeneralService
        } else {
            const data = {
                ...dataBusiness,
                type_document_id: parseInt(dataBusiness.type_document_id),
                plans_id: parseInt(dataBusiness.plans_id),
                num_document: dataBusiness.num_document,
                name: dataBusiness.name,
                email: dataBusiness.email,
            }
            const res = await serviceBusiness.update(data);
            if (res.is_ok) {
                MessageSuccess(res.message);
                dispatch(cleanData());
                history.push("/business/list");
                return;
            }
            MessageError(res.message); // TODO: Change by GeneralService
        }
    }

    return (
        <div className="mx-4">
            <BreadcrumbCustom
                title={id === ID_NOT_DEFINED ? "Crear Empresa" : "Editar Empresa"}
                routes={[
                    { name: "Empresas", link: "/business/list" },
                ]}
                activeRoute={id === ID_NOT_DEFINED ? "Crear Empresa" : "Editar Empresa"}
            />

            <div className="container-fluid">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12 table-data p-0 mt-2" >
                            <div className="card card-primary p-4">
                                <div className="card-body">
                                    <div className="row">


                                        <div className="col-md-6">
                                            <label>Nombre o razón social<b><i className="text-red">*</i></b></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder="Ingrese nombre"
                                                value={dataBusiness.name}
                                                minLength={2}
                                                maxLength={100}
                                                onChange={setData}
                                                required
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label>Tipo de documento<b><i className="text-red">*</i></b></label>
                                            <select
                                                className="form-select"
                                                name="type_document_id"
                                                value={dataBusiness.type_document_id}
                                                onChange={setData}
                                                required
                                            >
                                                <option value="">Seleccione el tipo de documento</option>
                                                {
                                                    listTypeDocument?.map((i) => {
                                                        return (
                                                            <option value={i.id} key={i.id} >
                                                                {i.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label>Numero de documento<b><i className="text-red">*</i></b></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="num_document"
                                                placeholder="Ingrese número de documento"
                                                value={dataBusiness.num_document}
                                                minLength={4}
                                                maxLength={12}
                                                onChange={setData}
                                                required
                                            />
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <label>Plan<b><i className="text-red">*</i></b></label>
                                            <select
                                                className="form-select"
                                                name="plans_id"
                                                value={dataBusiness.plans_id}
                                                onChange={setData}
                                                required
                                            >
                                                <option value="">Seleccione un plan</option>
                                                {
                                                    listPlans.map((i) => (
                                                        <option value={i.id} key={i.id}>
                                                            {i.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label>Correo<b><i className="text-red">*</i></b></label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                placeholder="Ingrese correo electrónico"
                                                value={dataBusiness.email}
                                                onChange={setData}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer">
                                    <button className='btn btn-success d-block ms-auto' type='submit'>
                                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                                        {id === 0 ? "Crear empresa" : "Editar empresa"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FormBusiness;
