import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { cleanData, reducerForm } from "../../../reducers/people/reducerPeople";
import ModalSendCustomerRegistrationLink from "./ModalSendCustomerRegistrationLink";

export const SelectAndCreate = ({ showCreateButton = true, showCreateLinkButton = true, pathCreate = "/people/create", activeTypePeople = [] }) => {
    const { list } = useSelector(state => state.typepeopleSlice);
    const { dataTypePeole } = useSelector(state => state.peopleSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const setData = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleCreate = () => {
        dispatch(cleanData());
        history.push(pathCreate);
    }

    return (
        <div className="container m-0 p-0">
            <div className="row">
                <div className="col-4">
                    <select
                        className="form-select mt-1"
                        name="type_people_id"
                        value={dataTypePeole.type_people_id}
                        onChange={setData}
                    >
                        <option value="0">
                            Seleccione tipo de persona
                        </option>
                        {
                            list.map(typeP => (<option
                                value={typeP.id}
                                key={typeP.id}
                                disabled={activeTypePeople.length > 0 ? !activeTypePeople.some(item => item === typeP.id) : false}
                            >
                                {typeP.name}
                            </option>))
                        }
                    </select>
                </div>
                <div className="col">
                    {
                        showCreateLinkButton
                        &&
                        <button title="Enviar link de registro" className="btn btn-success rounded-pill px-2 py-1 mx-1 mt-2 float-end" type="button"
                            onClick={() => setShow(true)}
                        >
                            <i className="fa-solid fa-link text-white"></i>
                        </button>
                    }
                    {
                        showCreateButton
                        &&
                        <button title="Crear tipo de persona" className="btn btn-success mt-1 float-end" type="button" onClick={handleCreate}>
                            <i className="fa-solid fa-plus text-white me-2" />
                            Crear persona
                        </button>
                    }
                </div>
            </div>
            <ModalSendCustomerRegistrationLink show={show} setShow={setShow} />
        </div >
    )
}
