import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

import ModalCodesQuestions from './dialogs/ModalCodesQuestions';
import ModalAutomateParameterization from '../../GptAutomation/components/ModalAutomateParameterization';
import ModalChatParameterization from '../../GptAutomation/components/ModalChatParameterization';
import SidebarCodesForm from './SidebarCodesForm';

import GeneralService from '../../../services/GeneralService';
import { MessageError, MessageVerifyCodes } from '../../../utils/message';
import { isURL } from '../../../utils/isUrl';
import { setBodyScriptHTML } from '../../../utils/textUtils';
import { toast } from '../../../utils/toast';
import { hideLoading, showLoading } from '../../../reducers/main/loadingReducer';
import { pluginsTinyMCE, stylesEditorTinyMCE, toolbarEditorTinyMCE } from '../../../config/editorConfigurations';
import { reducerForm } from '../../../reducers/forms/reducerFormSlice';
import { validateExistingCodes } from '../utils/functions';
import { ACTION_ADD_TO_END, ACTION_REPLACE_CONTENT } from '../../GptAutomation/constant/constant';
import { PROMPT_FORMS } from '../../GptAutomation/prompts/prompts';
import { textFormCodes, textQuestionCode } from '../utils/exportFunctions';

const TabText = () => {
    const { selectedForm, codesForm } = useSelector(state => state.formSlice);
    const { listQuestions } = useSelector(state => state.dialogsFormSlice);
    const { codesQuestions } = useSelector(state => state.dialogsFormSlice);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState({ modal: 1, show: false });
    const { id = 0 } = useParams();
    const editorRef = useRef(null);
    const dispatch = useDispatch();

    const insertCode = (code) => {
        if (editorRef.current) {
            editorRef.current.execCommand('mceInsertContent', false, code);
        }
        toast(dispatch, `Se insertó ${code}`);
    }

    const verifyCodes = async () => {
        const { content, errors } = await validateExistingCodes(selectedForm.url_template, codesForm, codesQuestions);
        dispatch(reducerForm({ key: "url_template", value: content }));
        if (errors > 0) {
            await MessageVerifyCodes(() => parametrizeDocument(content));
            return;
        }
        await parametrizeDocument(content);
    }

    const parametrizeDocument = async (content) => {
        dispatch(showLoading());
        const service = new GeneralService("form/parameterization");
        const res = await service.update({ id, url_template: setBodyScriptHTML(content) });
        dispatch(hideLoading());
        if (res.is_ok) {
            toast(dispatch, res.message);
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleEditor = (value, editor) => {
        const symbols = ["&nbsp;", "&ldquo;", "&rdquo;", "&lsquo;", "&rsquo;", "&ndash;", "&mdash;", "&hellip;", "&bull;", "&quot;", "&lt;", "&gt;", "&amp;"];

        symbols.forEach(symbol => {
            const regex = new RegExp(symbol + "@", "g");
            value = value.replace(regex, "@");
        });

        dispatch(reducerForm({ key: "url_template", value: value }));
    };

    const handleChangeContentChat = (content, action) => {
        switch (action) {
            case ACTION_ADD_TO_END:
                return dispatch(reducerForm({ key: "url_template", value: selectedForm.url_template + content }));
            case ACTION_REPLACE_CONTENT:
                return dispatch(reducerForm({ key: "url_template", value: content }));
            default:
                console.log("opcion no disponible");
        }
    }

    const textCodes = ()=> {
        let textCodes = textFormCodes(selectedForm);
        if (listQuestions.length > 0) {
            textCodes += textQuestionCode(listQuestions);
        }

        return textCodes;
    }

    return (
        <>
            <div className="process-tab">
                <div className="row mb-2">
                    <div className="col">
                        <button title="Guardar contenido" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={verifyCodes}>
                            <i className="fa-solid fa-floppy-disk text-white"></i>
                        </button>
                    </div>
                    <div className="col">
                        <button title="Visualizar códigos" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                            <i className="fa-solid fa-angles-left text-white"></i>
                        </button>
                        <button title="Códigos de preguntas" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button"
                            onClick={() => setShow(true)}
                        >
                            <i className="fa-solid fa-sitemap text-white"></i>
                        </button>
                        <button title="Parametrización automática" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button"
                            onClick={() => setShowChat(s => ({ ...s, show: true }))}
                        >
                            <i className="fa-solid fa-robot text-white"></i>
                        </button>
                    </div>

                </div>
                <div className="row">
                    <div className="col">
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_CLOUD_TOKEN}
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                                height: 750,
                                menubar: true,
                                plugins: pluginsTinyMCE(["emoticons"]),
                                toolbar: toolbarEditorTinyMCE(),
                                content_style: stylesEditorTinyMCE(),
                            }}
                            value={isURL(selectedForm.url_template) ? "" : selectedForm.url_template}
                            onEditorChange={handleEditor}
                        />
                    </div>
                </div>
            </div>

            <ModalCodesQuestions show={show} setShow={setShow} insertCode={insertCode} />
            <SidebarCodesForm insertCode={insertCode} id={id} />
            <ModalAutomateParameterization
                show={showChat.modal === 1 && showChat.show}
                setShow={setShowChat}
                textCodes={textCodes()}
                prompt={PROMPT_FORMS}
            />
            <ModalChatParameterization
                show={showChat.modal === 2 && showChat.show}
                setShow={setShowChat}
                onChangeContent={handleChangeContentChat}
            />
        </>
    );
}

export default TabText;