import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedTypepeople, setSelectedCategory, setListCategories } from '../../../../reducers/typePeople/reducerTypepeople';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import { toast } from '../../../../utils/toast';

const Selecctores = ({ id }) => {
    const { list, selectedTypepeople, selectedCategory, listCategories } = useSelector(state => state.typepeopleSlice);
    const dispatch = useDispatch();
    const [showSelectedCategory, setShowSelectedCategory] = useState(false);

    const setCategoryToList = () => {
        if (Object.keys(selectedCategory).length === 0) return;
        dispatch(setListCategories());
        setShowSelectedCategory(false);
        toast(dispatch, "Categoría añadida");
    }

    const handleSelect = (e) => {
        const id = parseInt(e.target.value);
        const typePeople = list.find(item => item.id === id);
        dispatch(setSelectedTypepeople({ value: typePeople }));
    }

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row m-0 p-0">

                    <div className="col-3">
                        <select className="form-select"
                            value={selectedTypepeople.id}
                            onChange={handleSelect}
                            disabled={id !== ID_NOT_DEFINED}
                        >
                            <option value=''>
                                Seleccione el tipo de persona
                            </option>
                            {
                                list.map((item) => {
                                    return (
                                        <option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })
                            }

                        </select>
                    </div>

                    {/* Botón Añadir Categoría */}
                    <div className='col-1' style={{ minWidth: '155px' }}>
                        <button type="button" className='btn btn-success rounded-3 button-add-category'
                            onClick={() => setShowSelectedCategory(true)}
                            disabled={!(selectedTypepeople.categories)}
                        >
                            <i className="fa-solid fa-plus" style={{ marginRight: '6px' }}></i>
                            Categoria
                        </button>
                    </div>

                    {
                        (showSelectedCategory && selectedTypepeople.categories) &&
                        <>
                            <div className="col-3">
                                <select className="form-select"
                                    value={selectedCategory.id}
                                    onChange={(e) => dispatch(setSelectedCategory({ value: e.target.value }))}
                                >
                                    <option value="">
                                        Seleccione la categoría
                                    </option>
                                    {
                                        selectedTypepeople.categories?.map((category) => {
                                            return (
                                                <option value={category.id} key={category.id}
                                                    disabled={
                                                        listCategories.length > 0 &&
                                                        listCategories.findIndex(i => i.id === category.id) !== -1
                                                    }
                                                >
                                                    {category.name}
                                                </option>
                                            )
                                        })
                                    }

                                </select>
                            </div>
                            <div className='col-1'>
                                <button type="button" className={`btn btn-success rounded-3`}
                                    onClick={setCategoryToList}
                                    disabled={Object.keys(selectedCategory).length === 0}
                                >
                                    Listo
                                </button>
                            </div>
                        </>
                    }

                </div>
            </div>
        </>
    )
}

export default Selecctores;
