import { createSlice } from "@reduxjs/toolkit";

export const colorsSlice = createSlice({
    name: "colorsSlice",
    initialState: {
        selectedColor: {
            id: 0,
            color: "",
            description: "",
            status: "",
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, action) => {
            state.selectedColor[action.payload.key] = action.payload.value;
        },
        reducerFormSearch: (state, action) => {
            if (action.payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.status.toLowerCase().includes(action.payload.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromColors: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload);
        },
        updateFromColors: (state, action) => {
            const code = action.payload;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromColors: (state, action) => {
            state.list.push(action.payload);
            state.listFilter.push(action.payload);
        },
        setSelected: (state, action) => {
            state.selectedColor = action.payload;
        },
        cleanData: (state, action) => {
            state.selectedColor = {
                id: 0,
                color: "",
                description: "",
                status: "",
            };
        },        
        orderColor: (state, action) => {
            const { id, order } = action.payload.value;
            const currentIndex = state.listFilter.findIndex(e => e.id === id);
            const element = state.listFilter[currentIndex];

            state.listFilter.splice(currentIndex, 1);
            state.listFilter.splice(order, 0, element);
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromColors,
    updateFromColors,
    insertFromColors,
    setSelected,
    cleanData,
    orderColor,
} = colorsSlice.actions;

export default colorsSlice.reducer;