import { createSlice } from "@reduxjs/toolkit";

export const partsSlice = createSlice({
    name: "partsSlice",
    initialState: {
        selectedPart: {
            id: 0,
            people_id: 0,
            label_global_id: 0,
            observation: '',
            expedients_id: 0,
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, action) => {
            state.selectedPart[action.payload.key] = action.payload.value;
        },
        reducerFormSearch: (state, { payload }) => {
            if (payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.people.name.toLowerCase().includes(payload.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromPart: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload);
        },
        updateFromPart: (state, action) => {
            const code = action.payload;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromPart: (state, action) => {
            state.list.push(action.payload);
            state.listFilter.push(action.payload);
        },
        setSelected: (state, action) => {
            state.selectedPart = action.payload;
        },
        orderPart: (state, action) => {
            const { id, order } = action.payload.value;
            const currentIndex = state.listFilter.findIndex(e => e.id === id);
            const element = state.listFilter[currentIndex];

            state.listFilter.splice(currentIndex, 1);
            state.listFilter.splice(order, 0, element);
        },
        cleanData: (state, action) => {
            state.selectedPart = {
                id: 0,
                people_id: 0,
                label_global_id: 0,
                observation: '',
                expedients_id: '',
            };
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromPart,
    updateFromPart,
    insertFromPart,
    setSelected,
    cleanData,
    orderPart,
} = partsSlice.actions;

export default partsSlice.reducer;