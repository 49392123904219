import { createSlice } from '@reduxjs/toolkit';

export const businessChatSlice = createSlice({
    name: 'businessChatSlice',
    initialState: {
        dataFilter: {
            name: "",
            filterRead: false,
        },
        chats: [],
        filteredChats: [],
        selectedChat: {},
        message: "",
    },
    reducers: {
        setListChats: (state, action) => {
            state.chats = action.payload;
            state.filteredChats = action.payload;
        },
        searchChat: (state, { payload }) => {
            const { name, users } = payload;
            state.dataFilter.name = name;

            if (name === "") {
                state.filteredChats = state.chats;
                return;
            }

            const nickNameToNameMap = users.reduce((map, user) => {
                map[user.nick_name] = user.name;
                return map;
            }, {});

            state.filteredChats = state.chats.filter(chat => {
                return Object.keys(chat.participants).some(nick_name => {
                    const participantName = nickNameToNameMap[nick_name];
                    return participantName && participantName.toLowerCase().includes(name.toLowerCase());
                });
            });
        },
        filterReadMessages: (state, action) => {
            state.dataFilter.filterRead = !state.dataFilter.filterRead;
            const value = state.dataFilter.filterRead;

            if (!value) {
                state.filteredChats = state.chats;
                return;
            }

            const filterRead = (chat) => value ? value === chat.isRead : true;
            state.filteredChats = state.chats.filter(filterRead);
        },
        setSelectedChat: (state, { payload }) => {
            state.selectedChat = payload ?? {};
        },
        setMessagesSelectedChat: (state, { payload }) => {
            state.selectedChat = { ...state.selectedChat, messages: payload };
        },
        setMessage: (state, { payload }) => {
            state.message = payload;
        },
    },
});

export const {
    setListChats,
    searchChat,
    filterReadMessages,
    setSelectedChat,
    setMessagesSelectedChat,
    setMessage,
} = businessChatSlice.actions;

export default businessChatSlice.reducer;