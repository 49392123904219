import { textQuestionCode } from "../../FormParameterization/utils/exportFunctions";


export const textActorCodes = (position, typePeople = {}, actor) => {
    let text = `\n\n-> Actor #${position}: ${typePeople.name}\n\n`;
    text += `  » Etiquetas\n`;
    actor.labels?.forEach(label => {
        text += `   - ${label.name}\n`;
    });

    text += `\n\n  » Grupos de datos\n`;
    typePeople.clusters?.forEach((cluster, i) => {
        text += `\n   -> Grupo #${i + 1}: ${cluster.name}\n`;
        cluster.parameterizations?.forEach(field => {
            text += `     - @${field.code_parameterization}: ${field.name}\n`;
        });
    });

    if (typePeople.categories) {
        text += `\n  » Categorías`;
        typePeople.categories?.forEach((category, i) => {
            text += `\n\n   > Categoría #${i + 1}: ${category.name}\n`;
            category.clusters?.forEach((cluster, k) => {
                text += `\n    -> Grupo #${k + 1}: ${cluster.name}\n`;
                cluster.parameterizations?.forEach(field => {
                    text += `      - @${field.code_parameterization}: ${field.name}\n`;
                });
            });
        });
    }

    return text;
}

export const textGlobalCodes = (globalCodes = []) => {
    let text = "\nCódigos globales\n";
    globalCodes.forEach(global => {
        text += ` - @${global.code}: ${global.name}\n`;
    });

    return text;
}

export const extractAllCodesDocument = (title, listActorsSelected, listTypePeople, listQuestions, listGlobal) => {
    let text = title + "\n\nActores";
    listActorsSelected.forEach((actor, i) => {
        const typePeople = listTypePeople.find(t => t.id === actor.type_peoples_id);
        text += textActorCodes(i + 1, typePeople, actor);
    });

    if (listQuestions.length > 0) {
        text += textQuestionCode(listQuestions);
    }

    if (listGlobal.length > 0) {
        text += textGlobalCodes(listGlobal);
    }

    return text;
}