import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { SearchBar } from "../../../../app/components/SearchBar";
import IconButton from "../../../../app/components/IconButton";
import { ModalPart } from "./ModalPart";

import GeneralService from "../../../../services/GeneralService";
import { cleanData, deleteFromPart, reducerFormSearch, setList, setSelected, orderPart } from "../../../../reducers/expedient/reducerParts";
import confirmDelete from "../../../../utils/confirmDelete";
import { MessageError, cutText, showAnimateOrderItem, toast } from "../../../../utils";
import { useAnimateBackground } from "../../../../app/hooks/useAnimateBackground";

export const ContentParts = () => {
    const { selectedExpedient } = useSelector(state => state.expedientSlice);
    const { listFilter } = useSelector(state => state.partsSlice);
    const { list: listLabels } = useSelector(state => state.labelGlobalSlice);
    const { search } = useLocation();
    const { people } = queryString.parse(search);
    const [show, setShow] = useState(people === undefined ? false : (parseInt(people) > 0));
    const dispatch = useDispatch();
    const [showEffect, setShowEffect] = useAnimateBackground();
    const service = new GeneralService("part");

    const deletePart = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFromPart(id));
        }
    }

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    const loadParts = async () => {
        const res = await service.getList(1000, { expedients_id: selectedExpedient.id });
        const { parts = [] } = res;
        dispatch(setList(parts));
    }

    const onEdit = (part) => {
        dispatch(cleanData());
        dispatch(setSelected(part));
        setShow(true);
    }

    const handleMove = async (id, order) => {
        const service = new GeneralService("part/order");
        const res = await service.post({ id, order });
        if (res.is_ok) {
            dispatch(orderPart({ value: { id, order } }));
            setShowEffect({ show: true, id });
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        loadParts();
    }, [selectedExpedient.id]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-5">
                    <SearchBar placeholder="Buscar persona" onChange={onSearch} />
                </div>
                <div className="col">
                    <button title="Crear parte"
                        className="btn btn-success d-block ms-auto" type="button"
                        onClick={() => { dispatch(cleanData()); setShow(true); }}
                    >
                        <i className="fa-solid fa-user-plus text-white me-2" />
                        Añadir
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="table-data">
                        <div className="order">
                            {
                                listFilter.length === 0 ?
                                    <div className="alert alert-warning text-center mt-2" role="alert">
                                        No hay partes que mostrar
                                    </div>
                                    :
                                    <div className="d-block rounded-3 clip-hide">
                                        <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                            <thead>
                                                <tr className="font-lato">
                                                    <th></th>
                                                    <th>#</th>
                                                    <th>Persona</th>
                                                    <th>Carácter</th>
                                                    <th>Observación</th>
                                                    <th>Acciones</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listFilter.map((part, i) =>
                                                        <tr
                                                            key={part.id}
                                                            className={`font-rubik change-background-animation ${showAnimateOrderItem(part, showEffect)}`}
                                                            onDoubleClick={() => onEdit(part)}
                                                        >
                                                            <td></td>
                                                            <td>{i + 1}</td>
                                                            <td className="text-uppercase">{part.people?.name}</td>
                                                            <td>
                                                                <i className="fa-solid fa-tags text-purple me-1"></i>
                                                                {listLabels.find(label => label.id === part.label_global_id)?.name}
                                                            </td>
                                                            <td>{cutText(part.observation, 65)}</td>
                                                            <td>
                                                                <IconButton
                                                                    icon="fa-solid fa-pen-to-square text-green"
                                                                    title="Editar parte"
                                                                    onClick={() => onEdit(part)}
                                                                />
                                                                <IconButton
                                                                    icon="fa-solid fa-trash-can text-red"
                                                                    title="Eliminar parte"
                                                                    onClick={async () => await confirmDelete(() => deletePart(part.id))}
                                                                />
                                                                <div className="move-parameterization">
                                                                    <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(part.id, (i - 1))}></i>
                                                                    <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(part.id, (i + 1))}></i>
                                                                </div>
                                                            </td>
                                                            <td></td>
                                                        </tr>)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <ModalPart show={show} setShow={setShow} people={people} />
        </div>
    )
}
