import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { HexColorInput, HexColorPicker } from 'react-colorful';

import GeneralService from '../../../services/GeneralService';
import { ID_NOT_DEFINED } from '../../../constant/constant';
import { MessageError } from '../../../utils/message';
import { insertFromColors, reducerForm, updateFromColors } from '../../../reducers/configuration/reducerColors';
import { toast } from '../../../utils/toast';

const ModalColors = ({ show, setShow }) => {
    const { selectedColor } = useSelector(state => state.colorsSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const onSelectColor = (color = "") => {
        dispatch(reducerForm({
            key: "color",
            value: color.substring(1, 7).toUpperCase(),
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("colors");

        if (selectedColor.id === ID_NOT_DEFINED) {
            const res = await service.post(selectedColor);
            if (res.is_ok) {
                setShow(false);
                toast(dispatch, res.message);
                dispatch(insertFromColors(res.color));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(selectedColor);
        if (res.is_ok) {
            setShow(false);
            toast(dispatch, res.message);
            dispatch(updateFromColors(selectedColor));
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedColor.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedColor.id === ID_NOT_DEFINED ?
                                            "Crear Color"
                                            :
                                            "Editar Color"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">

                        <div className="row">
                            <div className='col mb-3'>
                                <label>Color<b><i className="text-red">*</i></b></label>
                                <HexColorPicker style={{ width: "100%" }} color={selectedColor.color} onChange={onSelectColor} />
                                <div className="row mt-3">
                                    <div className="col-1">
                                        <div
                                            style={{
                                                backgroundColor: "#" + selectedColor.color,
                                                width: '35px',
                                                height: '35px',
                                                borderRadius: "35px"
                                            }}
                                        ></div>
                                    </div>
                                    <div className="col">
                                        <HexColorInput className='form-control ms-2' style={{ width: "100%" }} color={selectedColor.color} onChange={onSelectColor} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col mb-3'>
                                <label>Estatus<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="status"
                                    value={selectedColor.status}
                                    maxLength={45}
                                    minLength={3}
                                    placeholder="Ingrese un estatus"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col mb-3'>
                                <label>Descripción</label>
                                <textarea
                                    className="form-control custom-textarea mt-1"
                                    name="description"
                                    value={selectedColor.description}
                                    maxLength={100}
                                    placeholder="Ingrese una descripción"
                                    onChange={setData}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-palette me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedColor.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalColors;