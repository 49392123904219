import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ID_NOT_DEFINED } from '../../../../constant/constant';
import { addActor, reducerForm, updateActor } from '../../../../reducers/forms/reducerActorForm';
import { MessageError, MessageSuccess } from '../../../../utils/message';
import { setSelectedForm } from '../../../../reducers/forms/reducerFormSlice';
import GeneralService from '../../../../services/GeneralService';

const ModalActorForm = ({ show, setShow }) => {
    const { list, selectedForm } = useSelector(state => state.formSlice);
    const { selectedActor } = useSelector(state => state.actorFormSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value
        }));

        if (e.target.name === "form_id") {
            const id = parseInt(e.target.value)
            dispatch(setSelectedForm({ value: list.find(i => i.id === id) }));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("actor-form");

        const data = {
            id: selectedActor.id,
            name: selectedActor.name,
            form_id: selectedForm.id,
        };

        if (selectedActor.id === ID_NOT_DEFINED) {
            const res = await service.post(data);

            if (res.is_ok) {
                MessageSuccess(res.message)
                dispatch(addActor({ value: res.actor }));
                setShow(false);
                return;
            }
            MessageError(res.message, service.status);
            return;
        }
        const res = await service.update(data);
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(updateActor({ value: data }));
            setShow(false);
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedActor?.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-person-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedActor?.id === ID_NOT_DEFINED ?
                                            "Crear Actor"
                                            :
                                            "Editar Actor"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">

                        <div className="row m-0 p-0">

                            <div className='mb-3'>
                                <label>Tipo de formulario<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select"
                                    name="form_id"
                                    value={selectedForm.id}
                                    onChange={setData}
                                    required
                                >
                                    <option value="">
                                        Seleccione tipo de formulario
                                    </option>
                                    {
                                        list.map(item => (<option value={item.id} key={item.id}>
                                            {item.name}
                                        </option>))
                                    }
                                </select>
                            </div>

                            <div className='mb-3'>
                                <label>Etiqueta<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="name"
                                    value={selectedActor.name}
                                    maxLength={50}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedActor?.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalActorForm;