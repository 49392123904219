

export const filterUtilitiesCodeTypePeople = (typePeoples, codes = []) => {
    const lista = [];
    typePeoples?.clusters?.forEach(cluster => {
        cluster?.parameterizations?.forEach(param => {
            const value = codes.findIndex(c => c.parameterization_type_peoples_id === param.id);
            if (param.type_fields_id === 1 || param.type_fields_id === 7 || param.type_fields_id === 8) {
                lista.push({
                    ...param,
                    state: value !== -1,
                    utilities_id: value !== -1 ? codes[value].id : 0,
                    type_category: value !== -1 ? codes[value].name : "SELECCIONAR",
                    cluster: cluster.name
                });
            }
        });
    });

    typePeoples?.categories?.forEach(category => {
        category?.clusters?.forEach(cluster => {
            cluster?.parameterizations?.forEach(param => {
                const value = codes.findIndex(c => c.parameterization_category_peoples_id === param.id);
                if (param.type_fields_id === 1 || param.type_fields_id === 7 || param.type_fields_id === 8) {
                    lista.push({
                        ...param,
                        state: value !== -1,
                        utilities_id: value !== -1 ? codes[value].id : 0,
                        type_category: value !== -1 ? codes[value].name : "SELECCIONAR",
                        cluster: `${category.name}-${cluster.name}`
                    });
                }
            });
        });
    });

    return lista;
}