import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import { SearchBar } from "../../../app/components/SearchBar";
import { FilterExpedients } from "./components/FilterExpedients";
import { ListExpedient } from "./components/ListExpedient";

import { applyFilters, cleanData, clearFilter, reducerFormSearch } from "../../../reducers/expedient/reducerExpedient";
import { setList as setListExpedient } from "../../../reducers/expedient/reducerExpedient";
import { setList } from "../../../reducers/configuration/reducerColors";
import { setList as setListLabels } from "../../../reducers/configuration/reducerLabelGlobal";
import { setList as setListGroups } from "../../../reducers/configuration/reducerExpedientGroups";
import GeneralService from "../../../services/GeneralService";

export const ExpedientsView = () => {
    const [showFilter, setShowFilter] = useState(false);
    const { list } = useSelector(state => state.expedientGroupsSlice);
    const [group, setGroup] = useState(0);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { typeProcess } = queryString.parse(search);
    const history = useHistory();

    const onSearch = (e) => {
        dispatch(reducerFormSearch({ value: e.target.value, showFilter }));
    }

    const onShowFilter = () => {
        dispatch(clearFilter());
        dispatch(applyFilters());
        setShowFilter(!showFilter);
    }

    const onCreate = () => {
        dispatch(cleanData());
        history.push('/expedient/create');
    }

    const getExpedients = async () => {
        const service = new GeneralService("expedient");
        const res = await service.getList(1000, { type_process_id: typeProcess, expedient_group_id: group });
        const { expedients = [] } = res;
        dispatch(setListExpedient(expedients));
    }

    const loadColors = async () => {
        const service = new GeneralService("colors");
        const res = await service.getList({ page_size: 1000 });
        const { colors = [] } = res;
        dispatch(setList(colors));
    }

    const loadLabels = async () => {
        const service = new GeneralService("label-global");
        const res = await service.getList({ page_size: 1000 });
        const { labels = [] } = res;
        dispatch(setListLabels(labels));
    }

    const loadGroups = async () => {
        const service = new GeneralService("expedient-groups");
        const res = await service.getList();
        const { groups = [] } = res;
        dispatch(setListGroups(groups));
    }

    useEffect(() => {
        getExpedients();
    }, [typeProcess, group]);

    useEffect(() => {
        loadColors();
        loadLabels();
        loadGroups();
    }, []);

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Expedientes"
                        activeRoute="Listado"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <SearchBar onChange={onSearch} />
                </div>
                <div className="col-2">
                    <select
                        className="form-select green-input"
                        name="group"
                        value={group}
                        onChange={(e) => setGroup(e.target.value)}
                    >
                        <option value="0">
                            Grupo (Todos)
                        </option>
                        {
                            list.map(group => <option value={group.id} key={group.id}>
                                {group.name}
                            </option>)
                        }
                    </select>
                </div>
                <div className="col-7">
                    <div className="row">
                        <FilterExpedients showFilter={showFilter} />

                        <div className="col-1">
                            <button title={showFilter ? "Ocultar filtros" : "Activar filtros"}
                                className="btn btn-success rounded-pill me-1" type="button"
                                onClick={onShowFilter}
                            >
                                <i className={`fa-solid ${showFilter ? "fa-filter-circle-xmark" : "fa-filter"} text-white`} />
                            </button>
                        </div>

                        <div className="col-3">
                            <button title="Crear nuevo tipo de proceso"
                                className="btn btn-success rounded-pill" type="button"
                                onClick={onCreate}
                            >
                                <i className="fa-solid fa-folder-plus text-white me-2" />
                                Añadir
                            </button>

                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="table-data">
                        <ListExpedient typeProcess={typeProcess} />
                    </div>
                </div>
            </div>

        </div >
    )
}
