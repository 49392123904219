import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import service from "./service/serviceParametrizacion";
import { deleteCategoryGroupParametrize, orderParameterization } from '../../../../../../reducers/typePeople/reducerParameterize';
import confirmDelete from '../../../../../../utils/confirmDelete';
import { MessageError, MessageSuccess } from '../../../../../../utils/message';
import IconButton from '../../../../../../app/components/IconButton';
import { showAnimateOrderItem } from '../../../../../../utils/showAnimateOrderItem';
import { useAnimateBackground } from '../../../../../../app/hooks/useAnimateBackground';

const CategoryGroupListParameterize = () => {
    const { listFilter } = useSelector(state => state.typepeopleCategoryGroupParameterizeSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const { people = 0 } = queryString.parse(search);
    const [showEffect, setShowEffect] = useAnimateBackground();

    const deleteParam = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(deleteCategoryGroupParametrize({ value: id }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleEdit = (item) => {
        history.push({
            pathname: `/typepeople-categories-groups/edit-parameterize/${item.id}`,
            search: `?people=${people}&category=${item.parent_id}&cluster=${item.cluster_id}`,
        });
    }

    const handleMove = async (id, order, is_category) => {
        const res = await service.order({ id, order, is_category });
        if (res.is_ok) {
            dispatch(orderParameterization({ value: { id, order } }));
            setShowEffect({ show: true, id });
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay campos que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Campo</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id} className={`change-background-animation ${showAnimateOrderItem(item, showEffect)}`}>
                                                <td></td>
                                                <td style={{width: "40px"}}>{i + 1}.</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar parametrización"
                                                        onClick={() => handleEdit(item)}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar parametrización"
                                                        onClick={async () => await confirmDelete(() => deleteParam(item.id))}
                                                    />
                                                    <div className="move-parameterization">
                                                        <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(item.id, (i - 1), true)}></i>
                                                        <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(item.id, (i + 1), true)}></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    )

}

export default CategoryGroupListParameterize;