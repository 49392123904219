import { createSlice } from "@reduxjs/toolkit";
import { IS_A_CATEGORY } from "../../constant/constant";
import { extractFieldsCategory } from "../../modules/TercerosCreate/utils/extract";

const peopleSlice = createSlice({
    name: 'people',
    initialState: {
        peopleId: 0,
        dataTypePeole: {
            type_people_id: 0,
            field_favorite_id: "",
        },
        //Aquí se guarda toda la data que viene del formulario
        dataPeople: {},

        //Aquí se guardan las personas de la petición general
        listTypepeople: [],

        //Aquí se guarda la lista, de la persona seleccionada
        selectedTypePeople: {},

        //Aquí se guarda la lista de las categorías seleccionadas
        selectedCategory: {},
        //Agregar esa categoría al arreglo de categorías
        listCategories: [],

        //Aquí se guarda la lista de los campos inválidos
        dataSearchPeople: {},
        listFoundPeople: [],
        fieldsPeopleTable: [],
        fieldsTypePeople: [],
        favoriteCodes: [],
        favoriteCodesTable: [],
        refreshTable: true,
    },
    reducers: {
        setPeopleId: (state, action) => {
            state.peopleSliceId = action.payload.value;
        },
        // --------------
        //Reducer para mandar la lista general del tipo de persona
        setTypepeople: (state, action) => {
            state.listTypepeople = action.payload.value;
        },
        //Seleccionar el tipo de persona en la creación
        setSelectedTypepeople: (state, action) => {
            const selected = action.payload.value;
            state.selectedTypePeople = state.listTypepeople.find(e => e.id === parseInt(selected)) ?? {
                name: "",
            };
        },
        // --------------
        //Mandar la persona por id en la edición
        setTypepeopleOnEdit: (state, action) => {
            state.selectedTypePeople = action.payload.value
        },
        // --------------
        setSelectedCategory: (state, action) => {
            const selected = parseInt(action.payload.value);
            if (selected !== 0) {
                state.selectedCategory = state.selectedTypePeople.categories.find(c => c.id === selected) ?? {};
            }
        },
        setListCategories: (state) => {
            state.listCategories.push(state.selectedCategory);

            const fields = extractFieldsCategory(state.selectedCategory);
            fields.forEach(param => {
                if (param.required === 0 && param.alert_required) {
                    state.dataPeople = {
                        ...state.dataPeople,
                        [param.name]: {
                            value: "",
                            parameterization_category_peoples_id: param.id,
                            ...param
                        }
                    }
                }
            });

            state.selectedCategory = {};
        },
        // --------------
        //Manda las categorías a la hora de la edición
        setListCategoriesOnEdit: (state, action) => {
            state.listCategories = action.payload.value;
        },

        //Eliminar item de la lista de las categorías:
        setDeleteItemOfCategoriesList: (state, action) => {
            const newList = state.listCategories.filter(e => e.id !== action.payload.value);
            state.listCategories = newList;
        },

        //Llenar el objeto con los grupos de la persona
        setDataPeople: (state, action) => {
            state.dataPeople[action.payload.key] = { ...state.dataPeople[action.payload.key], ...action.payload.value };
        },
        //Asignar valos al objeto para la edición:
        setDataPeopleOnEdit: (state, action) => {
            state.dataPeople = action.payload.value;
        },

        cleanData: (state) => {
            // state.listTypepeople = [];
            state.dataPeople = {};
            state.dataPeopleCategory = {};
            state.selectedTypePeople = {};
            state.listCategories = [];
        },
        reducerForm: (state, action) => {
            let { value, key } = action.payload
            // Si la persona cambia, se limpian los campos de formulariode busquedas de favoritos
            if (key === "type_people_id") {
                state.dataSearchPeople = {};
                state.listFoundPeople = [];
            }
            state.dataTypePeole[key] = value;
        },
        // Filtra de los campos favoritos el campo del select que está activo, y guarda su valor en el objeto dataSearchPeople
        reducerFormSearch: (state, action) => {
            let { value, key } = action.payload
            const obj = state.dataTypePeole.field_favorite_id.split("--");

            if (obj.length === 3) {
                state.dataSearchPeople = {
                    ...state.dataSearchPeople,
                    [key]: obj[2] === IS_A_CATEGORY ? {
                        parameterization_id: parseInt(obj[0] ?? 0),
                        value,
                        is_category: IS_A_CATEGORY,
                    } : {
                        parameterization_id: parseInt(obj[0] ?? 0),
                        value,
                        is_category: IS_A_CATEGORY,
                    },
                }
            }
        },
        setListFoundPeople: (state, action) => {
            state.listFoundPeople = action.payload.value;
        },
        setFieldsTypePeople: (state, action) => {
            state.fieldsTypePeople = action.payload.value;
        },
        setFieldsPeopleTable: (state, action) => {
            state.fieldsPeopleTable = action.payload.value;
        },
        setFavoriteCodes: (state, action) => {
            state.favoriteCodes = action.payload.value;
        },
        setStateFavorite: (state, action) => {
            const { id, value, favorite_id, is_category = false } = action.payload;

            if (!is_category) {
                state.fieldsTypePeople = state.fieldsTypePeople.map(code => {
                    if (code.id === id && !code.category_peoples_id) {
                        return {
                            ...code,
                            state: value,
                            favorite_id,
                        }
                    }
                    return code;
                });
                return;
            }
            state.fieldsTypePeople = state.fieldsTypePeople.map(code => {
                if (code.id === id && code.category_peoples_id) {
                    return {
                        ...code,
                        state: value,
                        favorite_id,
                    }
                }
                return code;
            });
        },
        setStateFavoriteCodesTable: (state, action) => {
            const { id, value, favorite_id, is_category = false } = action.payload;

            if (!is_category) {
                state.fieldsPeopleTable = state.fieldsPeopleTable.map(code => {
                    if (code.id === id && !code.category_peoples_id) {
                        return {
                            ...code,
                            state: value,
                            favorite_id,
                        }
                    }
                    return code;
                });
                return;
            }
            state.fieldsPeopleTable = state.fieldsPeopleTable.map(code => {
                if (code.id === id && code.category_peoples_id) {
                    return {
                        ...code,
                        state: value,
                        favorite_id,
                    }
                }
                return code;
            });
        },
        setFavoriteCodesTable: (state, action) => {
            state.favoriteCodesTable = action.payload.value;
        },
        setRefreshTable: (state, action) => {
            state.refreshTable = !state.refreshTable
        },
        resetDataSearchPeople: (state, action) => {
            state.dataSearchPeople = {};
        },
    }
})

export const {
    setPeopleId,
    // --------------
    setTypepeople,
    setSelectedTypepeople,
    // --------------
    setSelectedCategory,
    setListCategories,
    setDeleteItemOfCategoriesList,
    setDataPeople,
    cleanData,
    reducerForm,
    reducerFormSearch,
    setListFoundPeople,
    setFieldsTypePeople,
    setFieldsPeopleTable,
    setFavoriteCodes,
    setStateFavorite,
    setStateFavoriteCodesTable,
    setRefreshTable,
    setFavoriteCodesTable,
    setTypepeopleOnEdit,
    setDataPeopleOnEdit,
    setListCategoriesOnEdit,
    resetDataSearchPeople,
} = peopleSlice.actions;

export default peopleSlice.reducer;