import { Modal } from "react-bootstrap";

import ReccoverPassword from '../../../assets/recoverPassword.jpg';
import IconButton from "../../../app/components/IconButton";
import GeneralService from "../../../services/GeneralService";
import { MessageError, MessageSuccess } from "../../../utils/message";
import { useForm } from "react-hook-form";

const RecoverPasswordModal = ({ show, setShow }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = async (data) => {
        const service = new GeneralService("user/reset-password");
        const res = await service.post(data);
        if (res.is_ok) {
            setShow(false);
            MessageSuccess(res.message, 3000);
            reset();
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <form onSubmit={handleSubmit(onSubmit)} autoCorrect="on" className="font-lato">
                <Modal.Body>
                    <div className="row">
                        <div className="col text-end">
                            <IconButton
                                title="Cerrar"
                                icon="fa-solid fa-circle-xmark text-green"
                                onClick={() => setShow(false)}
                            />
                        </div>
                    </div>
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col">
                                <img className="d-block mx-auto" src={ReccoverPassword} alt="cookies-img" height={200} />
                                <h4 className="text-center mt-1 fw-bold mt-2">Recuperar contraseña</h4>
                            </div>
                        </div>
                        <p>Ingresa tu dirección de correo electrónico y te enviaremos un mail con las instrucciones para recuperar tu contraseña.</p>
                        <div className="row">
                            <div className='col'>
                                <input
                                    type="email"
                                    className="form-control green-input rounded-pill"
                                    {...register('email', {
                                        required: 'Correo requerido', minLength: 3, maxLength: 100,
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'El formato no es correcto'
                                        }
                                    })}
                                    placeholder="Digita correo electrónico"
                                />
                                {errors.email && <div className="fs-7 text-red text-end">{errors.email.message}</div>}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                <button type="submit" className="btn btn-success text-white d-block mx-auto rounded-5 py-2 px-3 my-2">
                                    <i className="fa-solid fa-key me-2" style={{ fontSize: '16px' }}></i>
                                    Recuperar
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </form>
        </Modal>
    )
}

export default RecoverPasswordModal;