import { createSlice } from '@reduxjs/toolkit';

export const typepeopleCategorySlice = createSlice({
  name: 'typepeopleCategory',
  initialState: {
    dataTypepeopleCategory: {
      name: "",
      description: "",
      type_peoples_id: ""
    },
    list: [],
    listFilter: [],
    isLoading: false,

    listTypepeople: [],

    selectedCategory: {
      id: 0,
      name: "",
      description: "",
    },
  },
  reducers: {
    reducerForm: (state, action) => {
      state.selectedCategory = {
        ...state.selectedCategory,
        [action.payload.key]: action.payload.value
      }
    },
    setCategory: (state, action) => {
      state.list = action.payload.value
      state.listFilter = action.payload.value
      state.isLoading = false
    },
    setData: (state, action) => {
      state.dataTypepeopleCategory = action.payload.value
    },
    deleteCategory: (state, action) => {
      const newList = state.list.filter(e => e.id !== action.payload.value)
      state.list = newList;
      state.listFilter = newList;
    },
    setListTypepeople: (state, action) => {
      state.listTypepeople = action.payload.value
      state.isLoading = false
    },
    //Para editar en Modal
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload.value ?? { id: 0, name: "", description: "" };
    },
    setEditSelectedCateogry: (state, action) => {
      state.selectedCategory[action.payload.key] = action.payload.value;
    },
    //Agregar la categoría creada a la lista
    addNewObjectToList: (state, action) => {
      state.list.push(action.payload.value);
      state.listFilter.push(action.payload.value);
    },
    // Actualizar los campos después de editar
    updateNewObjectAfterEdit: (state, action) => {
      const categoria = action.payload.value
      const modificadaList = state.list.map((e) => {
        if (e.id === categoria.id) {
          return categoria
        }
        return e;
      });
      const modificadaListFilter = state.listFilter.map((e) => {
        if (e.id === categoria.id) {
          return categoria
        }
        return e;
      });
      state.list = modificadaList;
      state.listFilter = modificadaListFilter;
    },
    // Buscar en el input el nombre de la categoría 
    searchCategory: (state, action) => {
      if (action.payload.value === "") {
        state.listFilter = state.list;
        return;
      }
      const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
      state.listFilter = filtered;
    },
    cleanData: (state, action) => {
      state.selectedCategory = {
        id: 0,
        name: "",
        description: "",
      };
    }

  }
})

export const {
  reducerForm,
  cleanData,
  setCategory,
  setData,
  setListTypepeople,
  addNewObjectToList,
  updateNewObjectAfterEdit,
  searchCategory,
  deleteCategory,
  setSelectedCategory,
  setEditSelectedCateogry,
} = typepeopleCategorySlice.actions
export default typepeopleCategorySlice.reducer