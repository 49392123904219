import { createSlice } from "@reduxjs/toolkit";
import { IS_A_CATEGORY } from "../../constant/constant";
import { extractFieldsCategory } from "../../modules/CreateCase/utils/extract";

const createCaseSlice = createSlice({
    name: 'createCaseSlice',
    initialState: {
        caseId: 0,
        dataLegalCase: {
            legal_case_id: 0,
            field_favorite_id: "",
        },
        dataCase: {},
        selectedCategory: {},
        listCategories: [],
        invalidFields: [],
        dataSearch: {},
        listFoundCase: [],
        fieldsTable: [],
        fieldsCase: [],
        favoriteCodes: [],
        favoriteCodesTable: [],
        refreshTable: true,
    },
    reducers: {
        setCaseId: (state, action) => {
            state.caseId = action.payload.value;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload.value;
        },
        setListCategories: (state) => {
            state.listCategories.push(state.selectedCategory);

            const fields = extractFieldsCategory(state.selectedCategory);
            fields.forEach(param => {
                if (param.required === 0 && param.alert_required) {
                    state.dataCase = {
                        ...state.dataCase,
                        [param.name]: {
                            value: "",
                            parameterization_id: param.id,
                            ...param
                        }
                    }
                }
            });

            state.selectedCategory = {};
        },
        //Manda las categorías a la hora de la edición
        setListCategoriesOnEdit: (state, action) => {
            state.listCategories = action.payload.value;
        },

        //Eliminar item de la lista de las categorías:
        setDeleteItemOfCategoriesList: (state, action) => {
            const newList = state.listCategories.filter(e => e.id !== action.payload.value);
            state.listCategories = newList;
        },

        //Llenar el objeto con los grupos de la persona
        setDataCase: (state, action) => {
            state.dataCase[action.payload.key] = { ...state.dataCase[action.payload.key], ...action.payload.value };
        },
        //Asignar valos al objeto para la edición:
        setDataCaseOnEdit: (state, action) => {
            state.dataCase = action.payload.value;
        },
        cleanData: (state) => {
            state.dataCase = {};
            state.listCategories = [];
            state.dataLegalCase = {
                legal_case_id: 0,
                field_favorite_id: "",
            };
        },
        setInvalidFields: (state, action) => {
            state.invalidFields.push(action.payload.value);
        },
        setCleanFields: (state) => {
            state.invalidFields = [];
        },
        reducerForm: (state, action) => {
            let { value, key } = action.payload
            // Si la persona cambia, se limpian los campos de formulariode busquedas de favoritos
            if (key === "legal_case_id") {
                state.dataSearch = {};
                state.listFoundCase = [];
            }
            state.dataLegalCase = {
                ...state.dataLegalCase,
                [key]: value
            }
        },
        // Filtra de los campos favoritos el campo del select que está activo, y guarda su valor en el objeto dataSearchPeople
        reducerFormSearch: (state, action) => {
            let { value, key } = action.payload
            const obj = state.dataLegalCase.field_favorite_id.split("--");

            if (obj.length === 3) {
                state.dataSearch = {
                    ...state.dataSearch,
                    [key]: {
                        id: parseInt(obj[0] ?? 0),
                        is_category: obj[2] === IS_A_CATEGORY,
                        value,
                    },
                }
            }
        },
        setListFoundCase: (state, action) => {
            state.listFoundCase = action.payload.value;
        },
        setFieldsCase: (state, action) => {
            state.fieldsCase = action.payload.value;
        },
        setFieldsCaseTable: (state, action) => {
            state.fieldsTable = action.payload.value;
        },
        setFavoriteCodes: (state, action) => {
            state.favoriteCodes = action.payload.value;
        },
        setStateFavorite: (state, action) => {
            const { id, value, favorite_id, is_category = false } = action.payload;

            if (!is_category) {
                state.fieldsCase = state.fieldsCase.map(code => {
                    if (code.id === id && !code.category_legal_case_id) {
                        return {
                            ...code,
                            state: value,
                            favorite_id,
                        }
                    }
                    return code;
                });
                return;
            }
            state.fieldsCase = state.fieldsCase.map(code => {
                if (code.id === id && code.category_legal_case_id) {
                    return {
                        ...code,
                        state: value,
                        favorite_id,
                    }
                }
                return code;
            });
        },
        setStateFavoriteCodesTable: (state, action) => {
            const { id, value, favorite_id, is_category = false } = action.payload;

            if (!is_category) {
                state.fieldsTable = state.fieldsTable.map(code => {
                    if (code.id === id && !code.category_legal_case_id) {
                        return {
                            ...code,
                            state: value,
                            favorite_id,
                        }
                    }
                    return code;
                });
                return;
            }
            state.fieldsTable = state.fieldsTable.map(code => {
                if (code.id === id && code.category_legal_case_id) {
                    return {
                        ...code,
                        state: value,
                        favorite_id,
                    }
                }
                return code;
            });
        },
        setFavoriteCodesTable: (state, action) => {
            state.favoriteCodesTable = action.payload.value;
        },
        setRefreshTable: (state, action) => {
            state.refreshTable = !state.refreshTable
        },
        resetDataSearchCase: (state, action) => {
            state.dataSearch = {};
        },
    }
})

export const {
    setCaseId,
    setSelectedCategory,
    setListCategories,
    setDeleteItemOfCategoriesList,
    setDataCase,
    cleanData,
    setInvalidFields,
    setCleanFields,
    reducerForm,
    reducerFormSearch,
    setListFoundCase,
    setFieldsCase,
    setFieldsCaseTable,
    setFavoriteCodes,
    setStateFavorite,
    setStateFavoriteCodesTable,
    setRefreshTable,
    setFavoriteCodesTable,
    setDataCaseOnEdit,
    setListCategoriesOnEdit,
    resetDataSearchCase,
} = createCaseSlice.actions;

export default createCaseSlice.reducer;