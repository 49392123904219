import axios from "../../utils/axios";

const utilitiesCode = {};
const path = 'utilities-code-type-people';

utilitiesCode.getList = async (id) => {
    try {
        const response = await axios.get(path, {
            params: {
                offset: 1000,
                type_people_id: id,
            },
        });
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

utilitiesCode.save = async (data) => {
    try {
        const response = await axios.post(path, data);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

utilitiesCode.getById = async (id) => {
    try {
        const urlGet = `${path}/${id}`;
        const response = await axios.get(urlGet);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

utilitiesCode.update = async (data) => {
    try {
        const urlUpdate = `${path}/${data.id}`;
        const response = await axios.put(urlUpdate, data);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

utilitiesCode.delete = async (id) => {
    try {
        const urlDelete = `${path}/${id}`;
        const response = await axios.delete(urlDelete);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export default utilitiesCode;