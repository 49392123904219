import { useDispatch } from "react-redux";
import { searchNumberBusiness, searchTextBusiness } from '../../../reducers/business/reducerBusiness';
import { SearchBar } from "../../../app/components/SearchBar";

export const SearchBusiness = () => {
    const dispatch = useDispatch();

    return (
        <>
            <div className="container-fluid">
                <div className="row">

                    <div className='col-6'>
                        <SearchBar
                            placeholder="Buscar por nombre"
                            onChange={(e) => dispatch(searchTextBusiness(e.target.value))}
                        />
                    </div>

                    <div className='col-6'>
                        <SearchBar
                            placeholder="Buscar por NIT o CC"
                            onChange={(e) => dispatch(searchNumberBusiness(e.target.value))}
                        />
                    </div>
                </div>
            </div>
        </>
    );

}