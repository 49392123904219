import { Pagination } from "react-bootstrap";

import './styles/pagination.css';

export const CustomPagination = ({ pagination, setPagination }) => {
    return (<>
        <div className="row">
            <div className="col-2 ms-auto">
                <select
                    className="form-select parametrizacion__form-input"
                    value={pagination.page_size}
                    onChange={({ target }) => setPagination({ ...pagination, page_size: target.value })}
                >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select>
            </div>
        </div>
        <div className="row">
            <div className="col abs-center">
                <Pagination>
                    <Pagination.First
                        onClick={() => setPagination({ ...pagination, page: 0 })}
                        disabled={pagination.total_pages === 0 || pagination.page === 0}
                    />
                    <Pagination.Prev
                        onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
                        disabled={pagination.total_pages === 0 || pagination.page === 0}
                    />
                    <ItemsPagination
                        total={pagination.total_pages}
                        active={pagination.page}
                        onClick={setPagination}
                    />
                    <Pagination.Next
                        onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
                        disabled={pagination.total_pages === 0 || pagination.page + 1 === pagination.total_pages}
                    />
                    <Pagination.Last
                        onClick={() => setPagination({ ...pagination, page: pagination.total_pages - 1 })}
                        disabled={pagination.total_pages === 0 || pagination.page + 1 === pagination.total_pages}
                    />
                </Pagination>
            </div>
        </div>
    </>);
};

const ItemsPagination = ({ total = 0, active, onClick }) => {
    const pagesToShow = 5;
    let start = Math.max(0, active - pagesToShow);
    let end = Math.min(total, active + pagesToShow);
    const items = [];

    if (total === 0) {
        return <Pagination.Ellipsis />;
    }

    if (start > 0) {
        items.push(<Pagination.Ellipsis />);
    }

    for (let i = start; i <= end; i++) {
        items.push(<Pagination.Item
            key={i}
            active={active === i}
            onClick={() => onClick((c) => ({ ...c, page: i }))}>
            {i + 1}
        </Pagination.Item>);
    }

    if (end < total) {
        items.push(<Pagination.Ellipsis />);
    }

    return items;
};