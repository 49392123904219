import { formatNumber } from "../../../modules/FillOutForm/utils/formatNumber";


const FieldTypeNumber = ({ param, location, value, onChange, onInformation, className }) => {
    const handleChange = (e) => {
        const { id, ...other } = param;
        const value = param.apply_format ? formatNumber(e.target.value) : e.target.value;

        onChange({
            parameterization_id: param.id,
            value,
            ...other,
        });
    }

    const handleError = (e) => {
        const { value } = e.target;
        if (param.required && value !== "") {
            if (value.length < param.length_min) {
                onInformation({ show: true, message: "Debe ser un número más grande", isError: true });
                return;
            }
            if (!/^[0-9.]+$/.test(value)) {
                onInformation({ show: true, message: "Solo se aceptan números", isError: true });
                return;
            }
        }
        onInformation({ show: true, message: `${value.length}/${param.length_max}`, isError: false });
    }

    return (
        <input
            className={`form-control parametrizacion__form-input ${className}`}
            autoComplete="off"
            value={value}
            type="text"
            name={param.name}
            title="Solo se permiten números"
            required={param.required}
            maxLength={param.length_max}
            minLength={param.length_min}
            placeholder="Ingresa un número"
            aria-label={location}
            pattern="^[0-9.]+$"
            onInput={handleError}
            onChange={handleChange}
        />
    )
}

export default FieldTypeNumber;