import { createSlice } from "@reduxjs/toolkit";

export const configCronjobExpedientSlice = createSlice({
    name: "configCronjobExpedientSlice",
    initialState: {
        selectedConfig: {
            id: 0,
            days: 1,
            hour: 0,
            enable: false,
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        reducerForm: (state, action) => {
            state.selectedConfig[action.payload.key] = action.payload.value;
        },
        setSelected: (state, { payload }) => {
            state.selectedConfig = payload;
        },
        cleanData: (state, action) => {
            state.selectedConfig = {
                id: 0,
                days: 1,
                hour: 0,
                enable: false,
            };
        },
    },
});

export const {
    reducerForm,
    setSelected,
    cleanData,
} = configCronjobExpedientSlice.actions;

export default configCronjobExpedientSlice.reducer;