import IconButton from "../../../app/components/IconButton";
import { formatDate } from "../../../utils/formatDate";

const CardSentEmail = ({ email, onClick, onDelete }) => {
    const recipients = () => {
        let sentTo = "";
        email.to.forEach(item => {
            sentTo += item.name + ", ";
        });
        return sentTo.slice(0, -2);
    }

    return (
        <div className="row email-message" onClick={onClick}>
            <div className="col-5 recipients">
                <input className="d-inline-block me-2" type="checkbox" />
                <span className="text-purple">Para: {recipients()}</span>
            </div>
            <div className="col-5 subject">
                {email.subject}
            </div>
            <div className="col-2">
                {
                    formatDate(email.date_sent)
                }
                <IconButton
                    title="Eliminar"
                    icon="fa-regular fa-trash-can text-orange"
                    className="ms-3"
                    onClick={(e)=>{ e.stopPropagation(); onDelete(email);}}
                />
            </div>
        </div>
    )
}

export default CardSentEmail;