import { Accordion } from "react-bootstrap";

const GeneralAccordion = ({ title="", icon="", body }) => {
    return (
        <Accordion
            className="m-0 p-0"
            defaultActiveKey={['0']}
            alwaysOpen
            flush
        >
            <Accordion.Item
                eventKey="main"
            >
                <Accordion.Header className="p-2 accordion-header">
                    <div className='container-fluid p-0'>
                        <div className='row m-0 px-2'>
                            <div className='col-2 m-0 p-0'>
                                <i className={`fa-solid ${icon} fs-4`}></i>
                            </div>

                            <div className='col-10 mt-1 p-0'>
                                <span className='spanLetter'>{title}</span>
                            </div>
                        </div>
                    </div>
                </Accordion.Header>

                <Accordion.Body className="m-0 ps-4">
                    {body}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default GeneralAccordion;