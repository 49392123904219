
export const formatDate = (date) => {
    const decodeDate = new Date(date);

    const year = decodeDate.getFullYear();
    const month = (decodeDate.getMonth() + 1).toString().padStart(2, '0');
    const day = decodeDate.getDate().toString().padStart(2, '0');

    return `${year}/${month}/${day}`;
}

export const formatDateTime = (date) => {
    const decodeDate = new Date(date);

    const year = decodeDate.getFullYear();
    const month = (decodeDate.getMonth() + 1).toString().padStart(2, '0');
    const day = decodeDate.getDate().toString().padStart(2, '0');
    let hours = decodeDate.getHours();
    const minutes = decodeDate.getMinutes().toString().padStart(2, '0');
    let ampm = 'am';

    if (hours >= 12) {
        ampm = 'pm';
        if (hours > 12) {
            hours -= 12;
        }
    }

    hours = hours.toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
}

export const dateIsZero = (date = "") => {
    return date.startsWith("0001");
}

export const formatDateTimeLayoutISO = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}