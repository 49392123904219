import { createSlice } from "@reduxjs/toolkit";

export const copyFormSlice = createSlice({
    name: 'copyFormSlice',
    initialState: {
        dataCopy: {
            id: 0,
            is_cluster: false,
        },
    },

    reducers: {
        setData: (state, action) => {
            state.dataCopy = action.payload.value;
        },
        cleanData: (state) => {
            state.dataCopy = {
                id: 0,
                is_cluster: false,
            };
        },
    },
});

export const {
    setData,
    cleanData,
} = copyFormSlice.actions;

export default copyFormSlice.reducer;