import { createSlice } from '@reduxjs/toolkit';

export const chatGptSlice = createSlice({
    name: 'chatGptSlice',
    initialState: {
        document: {
            _id: "",
            name: "",
            text: "",
        },
        prompt: {
            _id: "",
            name: "",
            text: "",
        },
        chat: { _id: "", messages: [] },
        message: "",
        isLoading: false,
    },
    reducers: {
        reducerFormDocument: (state, { payload }) => {
            state.document[payload.key] = payload.value;
        },
        setDocument: (state, { payload }) => {
            state.document = payload;
        },
        setPrompt: (state, { payload }) => {
            state.prompt = payload;
        },
        setChat: (state, { payload }) => {
            state.chat = payload;
        },
        reducerMessage: (state, { payload }) => {
            state.message = payload;
        },
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        addMessageToChat:(state, {payload})=> {
            state.chat.messages.push(payload);
        },
    },
});

export const {
    reducerFormDocument,
    setDocument,
    setPrompt,
    setChat,
    reducerMessage,
    setIsLoading,
    addMessageToChat,
} = chatGptSlice.actions;

export default chatGptSlice.reducer;