import { createSlice } from "@reduxjs/toolkit";

export const zapsignConfigurationSlice = createSlice({
    name: "zapsignConfigurationSlice",
    initialState: {
        applyDefault: true,
        list: [
            {
                id: 0,
                type: "REQUIRE_SELFIE_PHOTO",
                active: false,
            },
            {
                id: 0,
                type: "REQUIRE_DOCUMENT_PHOTO",
                active: false,
            },
            {
                id: 0,
                type: "AUTOMATIC_WHATSAPP",
                active: false,
            },
            {
                id: 0,
                type: "AUTOMATIC_MAILING",
                active: false,
            },
        ],
    },
    reducers: {
        setApplyDefault: (state, action) => {
            state.applyDefault = action.payload.value;
        },
        setList: (state, action) => {
            const value = action.payload.value;

            state.list = state.list.map(item => {
                const index = value.findIndex(config => config.type === item.type);
                if (index !== -1) {
                    return { ...value[index], active: true };
                }

                return item;
            });
        },
        reducerForm: (state, action) => {
            const { key, active, id } = action.payload;
            state.list[key] = { ...state.list[key], active, id };
        },
    },
});

export const {
    setApplyDefault,
    setList,
    reducerForm,
} = zapsignConfigurationSlice.actions;

export default zapsignConfigurationSlice.reducer;