import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import IconButton from "../../../app/components/IconButton";
import ListProcess from "./ListProcess";
import ToolFilterDocuments from "./ToolFilterDocument";

import GeneralService from "../../../services/GeneralService";
import { applyFilters, clearFilter, fillElatedDocuments } from "../../../reducers/forms/reducerVirtualNotaryProcess";
import { toast } from "../../../utils/toast";
import { CustomPagination } from "../../../app/components/CustomPagination";
import { SignatureLegend } from "../../TercerosCreate/components/SignatureLegend";

const ProcessVirtualNotaryView = () => {
    const { dataFilter } = useSelector(state => state.virtualNotaryProcessSlice);
    const [show, setShow] = useState(false);
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });
    const dispatch = useDispatch();

    const loadProcess = async () => {
        const service = new GeneralService("process-form");
        const res = await service.getList(0, pagination);
        const { process = [], total_pages = 0 } = res;
        dispatch(fillElatedDocuments({ value: process }));
        setPagination({ ...pagination, total_pages });
    }

    const reloadInformation = async () => {
        dispatch(clearFilter());
        await loadProcess();
        toast(dispatch, "Los datos han sido recargados");
    }

    useEffect(() => {
        loadProcess();
    }, [pagination.page, pagination.page_size]);

    useEffect(() => {
        dispatch(applyFilters());
    }, [dataFilter]);

    return (
        <div className="container-fluid px-4 max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Notaría Virtual"
                        activeRoute={"Listado de firmas"}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-5">
                    <div className="row rounded-top bg-green pt-3 pb-2 px-3">
                        <div className="col">
                            <i className="fa-solid fa-file-lines fs-4 d-inline-block me-3"></i> <h5 className="d-inline-block">Documentos en proceso</h5>
                        </div>
                        <div className="col-6">
                            <IconButton
                                icon={`fa-solid ${show ? "fa-filter-circle-xmark text-black" : "fa-filter text-purple"}`}
                                title={show ? "Deshacer filtros" : "Aplicar filtros"}
                                fontSize={20}
                                onClick={() => {
                                    show && dispatch(clearFilter());
                                    setShow(c => !c);
                                }}
                            />
                        </div>
                        <div className="col-1">
                            <IconButton
                                icon="fa-solid fa-arrows-rotate purple"
                                title="Recargar información"
                                fontSize={20}
                                onClick={reloadInformation}
                            />
                        </div>
                    </div>
                    {
                        show && <ToolFilterDocuments />
                    }
                    <ListProcess />

                    <CustomPagination
                        pagination={pagination}
                        setPagination={setPagination}
                    />

                    <SignatureLegend />

                </div>
            </div>

        </div>
    )
}

export default ProcessVirtualNotaryView;