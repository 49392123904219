import IconButton from "../../../app/components/IconButton";
import { formatDate } from "../../../utils/formatDate";

const CardDraftEmail = ({ email, onClick, onDelete }) => {
    return (
        <div className="row email-message" onClick={onClick}>
            <div className="col-9 subject">
                <input className="d-inline-block me-2" type="checkbox" />{email.subject}
            </div>
            <div className="col-2">
                {
                    formatDate(email.created_at)
                }
                <IconButton
                    title="Eliminar"
                    icon="fa-regular fa-trash-can text-orange"
                    className="ms-3"
                    onClick={(e)=>{ e.stopPropagation(); onDelete(email);}}
                />
            </div>
        </div>
    )
}

export default CardDraftEmail;