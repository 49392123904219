import { useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import DashboardView from "../modules/DashboardCompany/DashboardView"
import { VisitsView } from "../modules/DashboardCompany/VisitsView"
import { FormUser } from "../modules/Usuarios/components/FormUser"
import { UsersView } from "../modules/Usuarios/UsersView"
import Business from "../modules/Empresas/components/business"
import FormBusiness from "../modules/Empresas/components/createBusiness"
import { RolesView } from "../modules/Roles/RolesView"

import TypepeopleCategory from "../modules/TercerosParam/componentes/categoria/category"
import TypeopleCateogryGroup from "../modules/TercerosParam/componentes/categoria/gruposCategoria/groupCategory"
import FormCategoryParameterization from "../modules/TercerosParam/componentes/categoria/gruposCategoria/parametrizacion/FormCategoryParameterization"
import TypepeopleCategoryGroupParameterize from "../modules/TercerosParam/componentes/categoria/gruposCategoria/parametrizacion/parameterize"
import TypepeopleGroup from "../modules/TercerosParam/componentes/grupos/group"
import FormParameterization from "../modules/TercerosParam/componentes/grupos/parametrizacion/FormParameterization"
import TypepeopleGroupParameterize from "../modules/TercerosParam/componentes/grupos/parametrizacion/parameterize"
import Typepeople from "../modules/TercerosParam/componentes/typepeople"
import People from '../modules/TercerosCreate/components/createPeople/people';
import ListPeople from '../modules/TercerosCreate/components/ListPeople';

import DocumentsAuto from "../modules/DocumentsAuto/components/Document"
import ParametrizeDocumentsAuto from "../modules/DocumentsAuto/components/parametrize/ParametrizeDocument";
import GenerateDocumentAuto from "../modules/DocumentsAuto/components/generate/GenerateDocumentAuto";
import SignaturesView from "../modules/DocumentsAuto/components/signatures/SignaturesView";
import InProgressView from "../modules/DocumentsAuto/components/noSigned/InProgressView";

import Form from "../modules/FormParameterization/componentes/Form";
import ClusterForm from "../modules/FormParameterization/componentes/cluster/ClusterForm";
import ParameterizationForm from "../modules/FormParameterization/componentes/cluster/parameterization/ParameterizationForm";
import FormParameterizationField from "../modules/FormParameterization/componentes/cluster/parameterization/FormParameterizationField";
import ActorForm from "../modules/FormParameterization/componentes/actor/ActorForm";
import ParameterizationActorForm from "../modules/FormParameterization/componentes/actor/parameterization/ParameterizationActorForm";
import FormParameterizationActorField from "../modules/FormParameterization/componentes/actor/parameterization/FormParameterizationActorField";
import TextFormParameterizationView from "../modules/FormParameterization/componentes/TextFormParameterizationView";
import ProcessVirtualNotaryView from "../modules/ProcessVirtualNotary/components/ProcessVirtualNotaryView";

import { BusinessChatView } from "../modules/Chat/BusinessChatView";
import Mails from "../modules/Email/components/Mails";
import Calendar from "../modules/Calendar/Calendarpage"

import { TypeProcessView, TypeMovementsView } from "../modules/Expedients/parameterization";
import { ExpedientsView, ModifyExpedientView } from "../modules/Expedients/generate";
import { ImporterView } from "../modules/Importers/ImporterView";
import { CreateImportView } from "../modules/Importers/CreateImportView";
import { TimeOutErrorView } from "../modules/Errors/TimeOutErrorView";
import ConfigurationView from "../modules/Configuration/ConfigurationView";

export const DashboardRoutes = () => {
    const { company } = useSelector(state => state.businessSlice);

    return (
        <Router>
            <Switch>
                <Route exact path="/" > <DashboardView /> </Route>
                {company.is_system_admin && <Route exact path="/visits" > <VisitsView /> </Route>}


                <Route exact path="/users/list"> <UsersView /> </Route>
                <Route exact path="/users/create"> <FormUser /> </Route>
                <Route exact path="/users/edit/:id"> <FormUser /> </Route>

                <Route exact path="/roles/list"> <RolesView /> </Route>

                <Route exact path="/business/list"> <Business /> </Route>
                <Route exact path="/business/create"> <FormBusiness /> </Route>
                <Route exact path="/business/edit/:id"> <FormBusiness /> </Route>


                {/* Typepeople Parameterization routes */}
                <Route exact path="/typepeople/list"> <Typepeople /> </Route>
                <Route exact path="/typepeople/groups"> <TypepeopleGroup /> </Route>
                <Route exact path="/typepeople-groups/parameterizations"> <TypepeopleGroupParameterize /> </Route>
                <Route exact path="/typepeople/create-parameterize"> <FormParameterization /> </Route>
                <Route exact path="/typepeople/edit-parameterize/:id"> <FormParameterization /> </Route>
                <Route exact path="/typepeople/categories"> <TypepeopleCategory /> </Route>
                <Route exact path="/typepeople-categories/groups"> <TypeopleCateogryGroup /> </Route>
                <Route exact path="/typepeople-categories-groups/parameterizations"> <TypepeopleCategoryGroupParameterize /> </Route>
                <Route exact path="/typepeople-categories-groups/create-parameterize"> <FormCategoryParameterization /> </Route>
                <Route exact path="/typepeople-categories-groups/edit-parameterize/:id"> <FormCategoryParameterization /> </Route>

                {/* Cases routes */}
                {/* <Route exact path="/case-parameterization/list"> <LegalCase /> </Route>
                                            <Route exact path="/case-parameterization/cluster"> <ClusterLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/fields"> <ParameterizationLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/fields/create"> <FormParameterizationLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/fields/update/:id"> <FormParameterizationLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category"> <CategoryLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category/cluster"> <ClusterCategoryLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category/fields"> <ParameterizationCategoryLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category/fields/create"> <FormParameterizationCategoryLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category/fields/update/:id"> <FormParameterizationCategoryLegalCase /> </Route>

                                            <Route exact path="/case/list"> <CreateCase /> </Route>
                                            <Route exact path="/case/create"> <FormCreateCase /> </Route>
                                            <Route exact path="/case/update/:id"> <FormCreateCase /> </Route> */}

                <Route exact path="/documents-auto/list"> <DocumentsAuto /> </Route>
                <Route exact path="/documents-auto/parameterization/:id"> <ParametrizeDocumentsAuto /> </Route>
                <Route exact path="/documents-auto/generate/:id"> <GenerateDocumentAuto /> </Route>
                <Route exact path="/documents-auto/signatures"> <SignaturesView /> </Route>
                <Route exact path="/documents-auto/in-progress"> <InProgressView /> </Route>

                {/* FormRoutes */}
                <Route exact path="/form-parameterization/list"> <Form /> </Route>
                <Route exact path="/form-parameterization/cluster"> <ClusterForm /> </Route>
                <Route exact path="/form-parameterization/fields"> <ParameterizationForm /> </Route>
                <Route exact path="/form-parameterization/fields/create"> <FormParameterizationField /> </Route>
                <Route exact path="/form-parameterization/fields/update/:id"> <FormParameterizationField /> </Route>
                <Route exact path="/form-parameterization/actors"> <ActorForm /> </Route>
                <Route exact path="/form-parameterization/actors/fields"> <ParameterizationActorForm /> </Route>
                <Route exact path="/form-parameterization/actors/fields/create"> <FormParameterizationActorField /> </Route>
                <Route exact path="/form-parameterization/actors/fields/update/:id"> <FormParameterizationActorField /> </Route>
                <Route exact path="/form-parameterization/text/:id"> <TextFormParameterizationView /> </Route>

                <Route exact path="/process/virtual-notary/list"> <ProcessVirtualNotaryView /> </Route>

                {/* RUTAS DEL TERCERO - Crear*/}
                <Route exact path="/people/list"> <ListPeople /> </Route>
                <Route exact path="/people/create"> <People /></Route>
                <Route exact path="/people/edit/:id"> <People /></Route>

                <Route exact path="/configurations"> <ConfigurationView /></Route>

                <Route exact path="/chat"> <BusinessChatView /></Route>
                <Route exact path="/mails"> <Mails /></Route>
                <Route exact path="/calendar"> <Calendar /></Route>

                <Route exact path="/type-process/list"> <TypeProcessView /></Route>
                <Route exact path="/type-movement/list"> <TypeMovementsView /></Route>
                <Route exact path="/expedient/list"> <ExpedientsView /></Route>
                <Route exact path="/expedient/create"> <ModifyExpedientView /></Route>
                <Route exact path="/expedient/edit/:id"> <ModifyExpedientView /></Route>

                <Route exact path="/errors/timeout"> <TimeOutErrorView /></Route>

                <Route exact path="/importers"> <ImporterView /></Route>
                <Route exact path="/importers/create"> <CreateImportView /></Route>
            </Switch>
        </Router>
    )
}
