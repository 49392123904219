import { createSlice } from "@reduxjs/toolkit";

export const storageExpedientSlice = createSlice({
    name: 'storageExpedientSlice',
    initialState: {
        selectedFile: {
            id: 0,
            url: '',
            name: '',
            expedients_id: 0,
            movements_id: 0,
            megabytes_used_space: 0,
        },
        list: [],
    },
    reducers: {
        //Obtener archivos en una lista, para mostrar
        setList: (state, { payload }) => {
            state.list = payload;
        },
        //Mostrar la nueva lista, después de eliminar archivos
        deleteFileFromStorage: (state, { payload }) => {
            state.list = state.list.filter(e => e.id !== payload);
        },
        setSelectedFile: (state, { payload }) => {
            state.selectedFile = {
                ...payload,
                type: payload.name.split('.').pop(),
                name: payload.name.split('.')[0],
            };
        },
        reducerForm: (state, { payload }) => {
            state.selectedFile[payload.key] = payload.value;
        },
        cleanSelectedFile: (state) => {
            state.selectedFile = {
                id: 0,
                url: '',
                name: '',
                expedients_id: 0,
                movements_id: 0,
                megabytes_used_space: 0,
            };
        },
    },
});

export const {
    setList,
    deleteFileFromStorage,
    setSelectedFile,
    reducerForm,
    cleanSelectedFile
} = storageExpedientSlice.actions;

export default storageExpedientSlice.reducer;