import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import ModalError from './ModalError';

import GeneralService from '../../../services/GeneralService';
import { pluginsTinyMCE, stylesEditorTinyMCE, toolbarEditorTinyMCE } from '../../../config/editorConfigurations';
import { MessageConfirm, MessageError, MessageSuccess } from '../../../utils/message';
import { hideLoading, showLoading } from '../../../reducers/main/loadingReducer';
import { setGeneratedProcess } from '../../../reducers/forms/reducerCreateProcessForm';
import { setBodyScriptHTML } from '../../DocumentsAuto/utils/functions';
import { toast } from '../../../utils/toast';
import { isURL } from '../../../utils/isUrl';
import { useDownloadFile } from '../../../app/hooks/useDownloadFile';

const TabText = () => {
    const { generatedProcess, checkCodes } = useSelector(state => state.createProcessFormSlice);
    const [show, setShow] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [content, setContent] = useState("");
    const [hasChange, setHasChange] = useState(0);
    const handleDownload = useDownloadFile();
    const editorRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { name = "" } = useParams();

    const loadContent = async (check_codes) => {
        dispatch(showLoading());
        const service = new GeneralService("process-form/show/" + generatedProcess.id);
        const res = await service.getRequest({ check_codes });
        dispatch(hideLoading());
        if (res.is_ok) {
            setContent(res.data);
            return;
        }

        if (check_codes) setErrors(res.errors);
        setShowModalError(true);
    }

    /* const generate = async () => {
        if (hasChange >= 2 && await MessageConfirm("¿Deseas guardar los cambios antes de descargar?")) {
            await saveProgress();
        }
        dispatch(showLoading());
        const service = new GeneralService("process-form/create-pdf/" + generatedProcess.id);
        const res = await service.post({}, { check_codes: checkCodes });
        dispatch(hideLoading());
        if (res.is_ok) {
            dispatch(setGeneratedProcess({ value: res.process }));
            handleDownload(res.process.url_pdf);
            return;
        }
        setErrors(res.errors);
        setShowModalError(true);
    } */

    const send = async () => {
        const confirm = await MessageConfirm("¿Estás realmente seguro de enviar este documento a tus destinatarios?");
        if (!confirm) return;

        dispatch(showLoading());
        if (!isURL(generatedProcess.url_pdf)) {
            const service = new GeneralService("process-form/create-pdf/" + generatedProcess.id);
            const res = await service.post({}, { check_codes: checkCodes });
            if (!res.is_ok) {
                return MessageError(res.message, service.status);
            }
        }
        const service = new GeneralService("process-form/send/" + generatedProcess.id);
        const res = await service.post({});
        dispatch(hideLoading());
        if (res.is_ok) {
            await MessageSuccess(res.message, 4000);
            localStorage.removeItem("process_form");
            dispatch(setGeneratedProcess({ value: undefined }));
            if (window.location.pathname.includes("/virtual-notary")) {
                history.push(`/virtual-notary`);
                return;
            }
            history.push(`/forms/${name}`);
            return;
        }
        MessageError(res.message, service.status);
    }

    const saveProgress = async () => {
        const service = new GeneralService("process-form/save-progress");
        const data = {
            id: generatedProcess.id,
            content: hasChange >= 2 ? setBodyScriptHTML(content) : content,
        }
        dispatch(showLoading());
        const res = await service.post(data);
        dispatch(hideLoading());
        if (res.is_ok) {
            toast(dispatch, res.message);
            setHasChange(0);
            return;
        }
        MessageError("No fue posible guardar el progreso.");
    }

    const popover = (
        <Popover style={{ minWidth: "25rem" }}>
            <Popover.Header className="bg-orange text-center" as="h3">Errores encontrados</Popover.Header>
            <Popover.Body>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <p>Reemplaza los códigos por el valor correspondiente, o elimina el código y su contexto.</p>
                        </div>
                    </div>
                    {
                        errors?.map((error, i) => <div className="row">
                            <div className="col">
                                <b>{i + 1}. <i>{error.name}: </i></b> @{error.code}
                            </div>
                        </div>)
                    }
                </div>
            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
        if (generatedProcess.id !== 0) loadContent(checkCodes);
    }, [generatedProcess.id, checkCodes]);

    return (
        <>
            <div className="process-tab">
                <div className="row mb-2">
                    <div className="col">
                        <button title="Guardar Progreso" className={`btn ${hasChange >= 2 ? "btn-warning" : "btn-success"} rounded-pill px-2 py-1 mx-1`} type="button" onClick={saveProgress}>
                            <i className="fa-solid fa-floppy-disk text-white"></i>
                        </button>
                        {/* <button title="Descargar documento" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={generate}>
                            <i className="fa-solid fa-file-arrow-down text-white"></i>
                        </button> */}
                        <button title="Enviar documento a los destinatarios" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={send}>
                            <i className="fa-solid fa-paper-plane text-white"></i>
                        </button>
                    </div>
                    <div className="col">
                        {
                            errors?.length > 0
                                ?
                                <OverlayTrigger placement="left" overlay={popover} show={show}>
                                    <div style={{ position: "relative", width: "30px", float: "right" }} onClick={() => setShow(c => !c)} >
                                        <i className="fa-solid fa-triangle-exclamation text-red fs-5"></i>
                                        <span className="position-absolute top-2 start-1 translate-middle badge rounded-pill bg-info p-1">
                                            {errors.length}
                                            <span className="visually-hidden">unread messages</span>
                                        </span>
                                    </div>
                                </OverlayTrigger>
                                :
                                null
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_CLOUD_TOKEN}
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                                height: 600,
                                menubar: true,
                                plugins: pluginsTinyMCE(["emoticons"]),
                                toolbar: toolbarEditorTinyMCE(),
                                content_style: stylesEditorTinyMCE(),
                            }}
                            value={content}
                            onEditorChange={(value, editor) => { setHasChange(hasChange + 1); setContent(value); }}
                        />
                    </div>
                </div>
            </div>

            <ModalError show={showModalError} setShow={setShowModalError} errors={errors} />
        </>
    );
}

export default TabText;