import { useEffect, useState } from 'react';
import '../styles/calendarstyle.css'
import GeneralService from "../../../services/GeneralService";
import { useDispatch, useSelector } from "react-redux";
import {
  setLabel,
  setListColors
} from "../../../reducers/calendar/reducerCalendar";

const ColorPicker = () => {
  const { colors, oncelabel, label } = useSelector(state => state.calendarslice);
  const [selectedColor, setSelectedColor] = useState(null);
  const dispatch = useDispatch();

  const requestColor = async () => {
    const service = new GeneralService("label-color");
    const { LabelColor } = await service.getList(0)
    dispatch(setListColors({ value: LabelColor }))
  }

  useEffect(() => {
    requestColor()
  }, []);

  const handleColorClick = (color) => {
    setSelectedColor(color.color);
    dispatch(setLabel({
      key: "label_color_id",
      value: color.id
    }));
  }

  return (
    <div className='colorGridStyle' >
      {colors.map((color, index) => (
        <button
          type='button'
          className='colorButtonStyle'
          key={index}
          style={{
            backgroundColor: color.color,
            border: selectedColor == color.color ? '2px solid black' : 'none'
          }}
          onClick={() => handleColorClick(color)}
        />
      ))}
    </div>
  );
};

export default ColorPicker;