import React, { useState } from "react";
import "../styles/dropdownstyle.css";

// Función para el componente Dropdown en JavaScript
const Dropdowncustome = ({ label, content, position = "bottom-left", width }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div className="dropdown">
      <div onClick={toggleDropdown} className="dropdown-button">
        {label}
      </div>
      {isOpen && (
        <div className={`dropdown-content ${position}`} style={{ width: width ? `${width}px` : undefined }}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Dropdowncustome;
