import { createSlice } from '@reduxjs/toolkit';

export const typepeopleCategoryGroupParameterizeSlice = createSlice({
    name: 'typepeopleCategoryGroupParameterize',
    initialState: {
        parameterization: {
            id: 0,
            type_peoples_id: 0,
            cluster_id: 0,
            type_fields_id: 0,
            type_files_id: 0,
            name: "",
            length_min: "",
            length_max: "",
            required: "",
            value_select: "",
            alert_required: false,
            apply_format: false,
        },
        list: [],
        listFilter: [],
        isLoading: true
    },
    reducers: {
        reducerForm: (state, action) => {
            state.parameterization[action.payload.key] = action.payload.value;
        },
        setListParameterization: (state, action) => {
            state.list = action.payload.value;
            state.listFilter = action.payload.value;
            state.isLoading = false
        },
        setData: (state, action) => {
            state.dataCategoryGroupParameterize = action.payload.value;
        },
        setDataGetById: (state, action) => {
            state.parameterization = action.payload.value
        },
        deleteCategoryGroupParametrize: (state, action) => {
            const newList = state.list.filter(e => e.id !== action.payload.value);
            state.list = newList;
            state.listFilter = newList;
        },
        orderParameterization: (state, action) => {
          const { id, order } = action.payload.value;
          const currentIndex = state.listFilter.findIndex(e => e.id === id);
          const element = state.listFilter[currentIndex];
    
          state.listFilter.splice(currentIndex, 1);
          state.listFilter.splice(order, 0, element);
        },
        addNewObjectToList: (state, action) => {
            state.list.push(action.payload.value);
        },
        //Buscar las parametrizaciones por nombre: 
        searchByNameParameterize: (state, action) => {
            if (action.payload.value === "") {
                state.listFilter = state.list;
                return
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()));
            state.listFilter = filtered;
        },
        cleanData: (state) => {
            state.parameterization = {
                type_fields_id: 0,
                type_files_id: 0,
                name: "",
                length_min: "",
                length_max: "",
                required: "",
                value_select: "",
                alert_required: false,
                apply_format: false,
            }
        },
        nullLengths: (state, action) => {
            state.parameterization = {
                ...state.parameterization,
                length_min: "",
                length_max: "",
            }
        },
    }
})

export const {
    reducerForm,
    cleanData,
    setDataGetById,
    setListParameterization,
    addNewObjectToList,
    deleteCategoryGroupParametrize,
    orderParameterization,
    setData,
    updateNewObjectAfterEdit,
    searchByNameParameterize,
    nullLengths,

} = typepeopleCategoryGroupParameterizeSlice.actions
export default typepeopleCategoryGroupParameterizeSlice.reducer