import BackgroundCode from "../../../../app/components/BackgroundCode";

const CardAnswerGenerated = ({ item }) => {
    return (
        <div className="row mb-2 animate__animated animate__fadeInLeft" key={item.id + item.name}>
            <div className="col-10">
                <div className="row mb-2">
                    <div className="col">
                        <BackgroundCode>
                            <span className="text-purple">{item.question}</span>
                        </BackgroundCode>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <b>
                            {item.type_answer ? "Tipo de respuesta" : "Pregunta"}:
                        </b> {item.type_answer ? item.type_answer : item.question}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <b>Respuesta:</b> {item.answer_text}
                    </div>
                </div>
            </div>
            <hr className="mt-2" />
        </div>
    )
}

export default CardAnswerGenerated;