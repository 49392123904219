

export const maxSizeFile = {
    //Archivos predeterminados (Usuales) --> 5MB
    txt: 5242880,
    docx: 5242880,
    docm: 5242880,
    odt: 5242880,
    pdf: 5242880,
    rtf: 5242880,
    csv: 5242880,
    xls: 5242880,
    xlsx: 5242880,
    xlsm: 5242880,
    ods: 5242880,
    pps: 5242880,
    ppt: 5242880,
    ppsx: 5242880,
    pptx: 5242880,
    ppsm: 5242880,
    pptm: 5242880,
    potx: 5242880,
    odp: 5242880,

    //Archivos comprimidos --> 20MB
    zip: 20971520,
    rar: 20971520,
    rar5: 20971520,
    ace: 20971520,
    r00: 20971520,
    gz: 20971520,

    //Archivos de imagen --> 5MB
    jpeg: 5242880,
    jpg: 5242880,
    png: 5242880,
    bmp: 5242880,
    ico: 5242880,
    svg: 5242880,
    webp: 5242880,
    gif: 5242880,
    heic: 5242880,
    nef: 5242880,
    crw: 5242880,
    ai: 5242880,
    id: 5242880,

    //Archivos de video --> 300MB
    divx: 314572800,
    avi: 314572800,
    mov: 314572800,
    mp4: 314572800,
    mpg: 314572800,
    mkv: 314572800,
    wmv: 314572800,
    wpl: 314572800,

    //Archivos de audio -->  20MB
    mp3: 20971520,
    wma: 20971520,
    wav: 20971520,
    flac: 20971520,
    midi: 20971520,
    ogg: 20971520,
    m3u: 20971520
};