import { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../../app/components/IconButton";
import ItemUtilityCodeCategory from "../../Configuration/components/ItemUtilityCodeCategory";

import confirmDelete from "../../../utils/confirmDelete";
import { deleteActor, setActorType, setSelectedActor } from "../../../reducers/forms/reducerCreateProcessForm";
import { ID_NOT_DEFINED } from "../../../constant/constant";
import { MessageConfirm } from "../../../utils/message";

const CardTypeActor = ({ actor, order, active = false, refForm }) => {
    const { selectedForm } = useSelector(state => state.formSlice);
    const { listActors } = useSelector(state => state.createProcessFormSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const handleConfigLabel = async (typeActor) => {
        setShow(false);
        if (listActors.find(itemActor => itemActor.id === actor.id).actor_form_id !== ID_NOT_DEFINED) {
            const res = await MessageConfirm("Cambio de etiqueta", "Si cambias la 🏷️etiqueta establecida inicialmente, perderás tus datos. ¿Estás seguro de cambiarla?");
            if (!res) {
                return;
            }
        }
        dispatch(setActorType({
            value: {
                id: actor.id,
                type: typeActor.id,
                required_fields: typeActor.parameterizations.filter(param => param.required).length,
            }
        }));
    }

    const popover = (
        <Popover style={{ minWidth: "15rem", maxHeight: "25rem", overflow: "scroll", overflowX: "hidden" }}>
            <Popover.Header className="bg-purple text-white text-center" as="h3">Elige un tipo de actor</Popover.Header>
            <Popover.Body>
                <div className="container">
                    {
                        selectedForm.actors.map(typeActor => <ItemUtilityCodeCategory
                            key={typeActor.id}
                            name={typeActor.name.toUpperCase()}
                            icon="fa-user-tag"
                            className="mb-1"
                            active={actor.actor_form_id === typeActor.id}
                            onClick={actor.actor_form_id === typeActor.id ? () => setShow(false) : () => handleConfigLabel(typeActor)}
                        />)
                    }
                </div>
            </Popover.Body>
        </Popover>
    );

    const handleSelectActor = () => {
        if (!active) {
            if (refForm.current.checkValidity()) dispatch(setSelectedActor({ value: actor }));
            else refForm.current.reportValidity();
        }
    }

    return (

        <div className={`row mb-2 pt-2 pb-1 ${active && "bg-light-purple rounded-3"}`}>
            <div className="col-3">
                <i className="fa-solid fa-caret-right text-purple me-2"></i><b>#{order}</b>
            </div>
            <div className="col-6 p-0">
                <OverlayTrigger placement="top" overlay={popover} show={show}>
                    <div
                        className={`rounded-2 pb-1 text-center text-purple mx-2 ${actor.actor_form_id !== ID_NOT_DEFINED ? "bg-light-purple" : "bg-light-gray"}`}
                        role="button"
                        onClick={() => setShow(c => !c)}
                    >
                        <i className="fa-solid fa-user-tag me-2 text-purple"></i>
                        <span className="fw-bold fs-8">
                            {
                                actor.actor_form_id !== ID_NOT_DEFINED ?
                                    selectedForm.actors.find(typeActor => typeActor.id === actor.actor_form_id).name.toUpperCase()
                                    :
                                    "ETIQUETA"
                            }
                        </span>
                    </div>
                </OverlayTrigger>
            </div>
            <div className="col-3 p-0">
                <IconButton
                    icon="fa-solid fa-trash-can text-red"
                    title="Eliminar actor"
                    fontSize="15px"
                    onClick={async () => await confirmDelete(() => dispatch(deleteActor({ value: actor.id })))}
                />
                <IconButton
                    icon="fa-solid fa-gear text-green"
                    title="Llenar datos del actor"
                    fontSize="15px"
                    onClick={handleSelectActor}
                />
            </div>
        </div>
    )
}

export default CardTypeActor;