import { useDispatch, useSelector } from "react-redux";

import { cleanQuestion, reducerFormQuestion, setRefresQuestions } from "../../../../reducers/documents/reducerGenerateDocument";
import { ID_NOT_DEFINED } from "../../../../constant/constant";
import { MessageError } from "../../../../utils/message";
import { toast } from "../../../../utils/toast";
import GeneralService from "../../../../services/GeneralService";

const FormQuestions = ({ }) => {
    const { selectedQuestion, dataDocument, selectedAnswer } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerFormQuestion({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("questions-documents");

        if (selectedQuestion.id === ID_NOT_DEFINED) {
            const data = {
                ...selectedQuestion,
                documents_auto_id: dataDocument.id,
            }

            const res = await service.post(data);
            if (res.is_ok) {
                if (!selectedQuestion.first_question && selectedAnswer.id !== ID_NOT_DEFINED) {
                    const serviceSequence = new GeneralService("sequence-questions");
                    const resSeq = await serviceSequence.post({
                        answers_id: selectedAnswer.id,
                        questions_documents_id: res.questions_documents.id,
                    });
                    if (resSeq.is_ok) {
                        toast(dispatch, res.message);
                        dispatch(setRefresQuestions());
                        dispatch(cleanQuestion());
                        return;
                    }
                    MessageError(resSeq);
                    return;
                }
                toast(dispatch, res.message);
                dispatch(setRefresQuestions());
                dispatch(cleanQuestion());
                return;
            }
            MessageError(res.message, service.status);
            return;
        }
        const res = await service.update(selectedQuestion);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(setRefresQuestions());
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <form onSubmit={handleSubmit} autoCorrect="on">
            <div className="row">
                <div className="col">
                    <h5><b>{selectedQuestion.id === ID_NOT_DEFINED ? "Crear" : "Editar"} pregunta</b></h5>
                    <p>
                        {
                            selectedQuestion.id === ID_NOT_DEFINED ?
                                "Crea una pregunta y establece sus parámetros."
                                :
                                "Reformula la pregunta o cambia sus parámetros."
                        }
                    </p>
                </div>
            </div>

            <label>Tipo de pregunta<b><i className="text-red">*</i></b></label>
            <div className="row abs-center mt-1">
                <div className={`col-4 py-1 px-2 mx-2 rounded-4 ${selectedQuestion.type === "QUESTION_OPEN" ? "bg-success text-white" : "border-success"} text-center`}
                    role="button"
                    onClick={() => {
                        dispatch(reducerFormQuestion({ key: "type", value: "QUESTION_OPEN" }));
                        dispatch(reducerFormQuestion({ key: "finish", value: true }));
                    }}>
                    ABIERTA
                </div>
                <div className={`col-4 py-1 px-2 mx-2 rounded-4 ${selectedQuestion.type === "QUESTION_CLOSED" ? "bg-success text-white" : "border-success"} text-center`}
                    role="button"
                    onClick={() => {
                        dispatch(reducerFormQuestion({ key: "type", value: "QUESTION_CLOSED" }));
                        dispatch(reducerFormQuestion({ key: "answer", value: "" }));
                        dispatch(reducerFormQuestion({ key: "finish", value: false }));
                    }}>
                    CERRADA
                </div>
            </div>

            <div className="row my-2">

                <div className='col'>
                    <label>Nombre<b><i className="text-red">*</i></b></label>
                    <input
                        type="text"
                        className="form-control mt-1"
                        name="name"
                        value={selectedQuestion.name}
                        maxLength={255}
                        minLength={2}
                        placeholder="Ingrese un nombre"
                        autoComplete="off"
                        autoCapitalize="words"
                        onChange={setData}
                        required
                    />
                </div>
            </div>

            <button type="submit" className={`btn btn-success d-block mx-auto mt-4 rounded-3 button-save-document'`}>
                <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                {selectedQuestion.id === ID_NOT_DEFINED ? "Agregar" : "Editar"}
            </button>
            
        </form>
    )
}

export default FormQuestions;