

export const ContextMenuColor = ({ visible, x, y, onHide, colors = [], selectedColor, onChangeColor }) => {
    if (!visible) return null;

    const style = {
        top: y,
        left: x,
    };

    return (
        <div className="context-menu__expedient" style={style}>
            <h6 className="font-lato text-center fw-bold mt-2">Color de la capeta</h6>
            {
                colors.map(color => <div key={color.id} className="item-color" onClick={() => onChangeColor(color.id)}>
                    <div className="circle-color" style={{ backgroundColor: "#" + color.color }}>
                        {
                            selectedColor === color.id
                            &&
                            <i className="fa-solid fa-check text-white"></i>}
                    </div>
                    <div className="text-status">
                        {color.status}
                    </div>
                </div>)
            }
        </div>
    );
};