import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import CompanyForms from "../modules/FillOutForm/components/CompanyForms";
import FillOutFormView from "../modules/FillOutForm/components/FillOutFormView";
import { useSelector } from "react-redux";
import ToastNotify from "../app/components/ToastNotify";
import LoadingCustom from "../app/components/LoadingCustom";

const PublicCompanyFormRoutes = () => {
    const { show } = useSelector(state => state.toastSlice);

    return (
        <Router>
            <div>
                {show && <ToastNotify />}
                <LoadingCustom />

                <Switch>
                    <Route exact path="/forms/:name"> <CompanyForms /> </Route>
                    <Route exact path="/forms/:name/fill-out/:id"> <FillOutFormView /> </Route>
                </Switch>
            </div>
        </Router>
    )
}

export default PublicCompanyFormRoutes;