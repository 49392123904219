import { createSlice } from '@reduxjs/toolkit';

export const mailSlice = createSlice({
    name: 'mailSlice',
    initialState: {
        received: [],
        filteredEmails: [],
        selectedEmail: {
            id: 0,
            subject: "",
            content: "",
            is_general: true,
            are_clients: true,
            to: [],
        }
    },
    reducers: {
        setListReceived: (state, action) => {
            state.received = action.payload.value;
        },
        setSelectedEmail: (state, action) => {
            state.selectedEmail = action.payload.value;
        },
        reducerFormEmail: (state, action) => {
            state.selectedEmail[action.payload.key] = action.payload.value;
        },
        setAreClients: (state, action) => {
            state.selectedEmail.are_clients = action.payload.value;
        },
        deleteEmail: (state, action) => {
            state.received = state.received.filter(email => email.id !== action.payload.value);
        },
        addRecipient: (state, action) => {
            state.selectedEmail.to.push(action.payload.value);
        },
        deleteRecipient: (state, action) => {
            state.selectedEmail.to = state.selectedEmail.to.filter(recipient => recipient.address !== action.payload.value);
        },
        clearNewEmail: (state, action) => {
            state.selectedEmail = {
                id: 0,
                subject: "",
                content: "",
                is_general: true,
                to: [],
            };
        },
    },
});

export const {
    setListReceived,
    setSelectedEmail,
    reducerFormEmail,
    setAreClients,
    deleteEmail,
    clearNewEmail,
    addRecipient,
    deleteRecipient,
} = mailSlice.actions;

export default mailSlice.reducer;