import { useState, useEffect, useRef } from "react";
import { ref, onValue, off, query, orderByChild, limitToLast, update } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";

import MessageItem from "./MessageItem";

import { FirebaseDB } from "../../../firebase/config/firebase-config";
import { setMessagesSelectedChat } from "../../../reducers/chat/reducerChatSlice";
import { downloadImageAsBase64 } from "../../../firebase/firebaseStorage";

export const ActiveChatMessages = () => {
    const { selectedChat } = useSelector(state => state.businessChatSlice);
    const { list } = useSelector(state => state.userSlice);
    const { url_avatar, name, nick_name } = useSelector(state => state.loginSlice.profile);
    const { company } = useSelector(state => state.businessSlice);
    const finalItemRef = useRef();
    const listMessagesRef = useRef(null);
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(25);

    useEffect(() => {
        if (selectedChat?.chatId && selectedChat.created_at) {
            const chatRef = query(
                ref(FirebaseDB, `businesses/business_id_${company.id}/chats/${selectedChat.chatId}/messages`),
                orderByChild('timestamp'),
                limitToLast(limit),
            );

            const handleMessagesUpdates = async (snapshot) => {
                const messages = snapshot.val() || {};
                const messagesArray = Object.keys(messages).map(key => ({
                    id: key,
                    ...messages[key],
                }));

                const updates = {};
                messagesArray.forEach(message => {
                    if (message.sender !== nick_name && !message.read) {
                        updates[`businesses/business_id_${company.id}/chats/${selectedChat.chatId}/messages/${message.id}/read`] = true;
                        message.read = true;
                    }
                });

                if (Object.keys(updates).length > 0) {
                    await update(ref(FirebaseDB), updates);
                }

                dispatch(setMessagesSelectedChat(messagesArray));
            };

            onValue(chatRef, handleMessagesUpdates);

            return () => {
                off(chatRef, 'value', handleMessagesUpdates);
            };
        }
    }, [selectedChat?.chatId, company, limit]);

    useEffect(() => {
        const divList = listMessagesRef.current;

        if (divList) {
            setTimeout(() => {
                if (divList.scrollTop >= divList.scrollHeight - divList.clientHeight - 50) {
                    finalItemRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }, [selectedChat?.messages]);

    useEffect(() => {
        if (listMessagesRef.current) {
            const divList = listMessagesRef.current;
            divList.scrollTop = divList.scrollHeight;
        }
    }, [selectedChat?.chatId]);

    const loadChats = () => {
        setLimit(prevLimit => prevLimit + 25);
    };

    return (
        <div ref={listMessagesRef} className="messages">
            {
                (!(selectedChat?.messages?.length < limit) && selectedChat?.created_at)
                &&
                <div className="load-more__chat" onClick={loadChats}>
                    Cargar más
                </div>
            }
            {
                selectedChat?.messages
                &&
                Object.entries(selectedChat?.messages).map(([messageId, message]) => {
                    if (message.sender === nick_name) return <MessageItem
                        key={messageId}
                        avatar={<img src={url_avatar} alt={name} />}
                        showName={false}
                        isLarge={false}
                        isExternal={message.sender !== nick_name}
                        message={message}
                    />
                    else return <MessageItem
                        key={messageId}
                        avatar={<img src={downloadImageAsBase64(list.find(user => user.nick_name === message.sender)?.url_avatar)} alt={name} />}
                        name={list.find(user => user.nick_name === message.sender)?.name}
                        showName={false}
                        isLarge={false}
                        isExternal={true}
                        message={message}
                    />
                })
            }
            <span ref={finalItemRef}></span>
        </div>
    )
}
