const Axios = require("axios");

const axiosDocumentEditor = () => {
  const axiosConnect = Axios.create({
    baseURL: process.env.REACT_APP_API_DOCUMENT_EDITOR_URL,
  });

  axiosConnect.interceptors.request.use(
    function (config) {
      config.headers = {
        Accept: "application/json",
      };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return axiosConnect;
};

export default axiosDocumentEditor();