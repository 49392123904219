import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import ModalCopyGroup from '../../../CopyClusters/ModalCopyGroup';
import IconButton from '../../../../app/components/IconButton';

import GeneralService from '../../../../services/GeneralService';
import { deleteGroup, setSelectedGroup } from '../../../../reducers/typePeople/reducerGroup';
import { MessageError, MessageSuccess } from '../../../../utils/message';
import { setData } from '../../../../reducers/copyClusters/reducerCopyGroup';
import confirmDelete from '../../../../utils/confirmDelete';

const ListGroup = ({ setShow }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showCopy, setShowCopy] = useState(false);

    const { listFilter } = useSelector(state => state.typepeopleGruopSlice);

    const deleteInstance = async (id) => {
        const service = new GeneralService("clusters");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess("El registro ha sido eliminado exitosamente!");
            dispatch(deleteGroup({ value: id }));
            return;
        }
        MessageError("No fue posible eliminar el grupo");
    }

    const handleParametrize = (item) => {
        history.push({
            pathname: "/typepeople-groups/parameterizations",
            search: `?people=${item.type_peoples_id}&cluster=${item.id}`,
        });
    }

    const handleCopyGroup = (item) => {
        setShowCopy(true);
        dispatch(setData({
            value: {
                cluster_id: item.id,
                cluster_is_category: false,
            }
        }));
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            ¡No has seleccionado un grupo! O no hay grupos para este tipo de persona, selecciona otro...
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Nombre del grupo</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td style={{ width: "40px" }}>{i + 1}.</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar grupo"
                                                        onClick={() => {
                                                            dispatch(setSelectedGroup({ value: item }));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-gear text-green"
                                                        title="Parametrizar grupo"
                                                        onClick={() => handleParametrize(item)}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-copy text-green"
                                                        title="Copiar grupo a"
                                                        onClick={() => handleCopyGroup(item)}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar tipo de grupo"
                                                        onClick={async () => await confirmDelete(() => deleteInstance(item.id))}
                                                    />

                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
            <ModalCopyGroup show={showCopy} setShow={setShowCopy} model="TYPE_PEOPLE" />
        </>
    )

}

export default ListGroup;