import alertConsumptionSlice from "./main/alertConsumption";
import toastSlice from "./main/toastReducer";
import menuSlice from "./main/menuReducer";
import loadingStateSlice from "./main/loadingReducer";
import toastProgressSlice from "./main/toastProgressSlice";

import rolesSlice from "./roles/reducerRoles";
import permissionsSlice from "./roles/reducerPermissions";

import copyFormSlice from "./copyClusters/reducerCopyForm";
import copyGroupSlice from "./copyClusters/reducerCopyGroup";

import userSlice from "./user/reducerUsers";
import storageUserSlice from "./storageUser/reducerStorageUser";

import loginSlice from "./auth/reducerLogin";
import recoverCredentialsSlice from "./auth/reducerRecoverCredentials";

import businessSlice from "./business/reducerBusiness";
import companyMetricsSlice from "./business/reducerCompanyMetrics";
import viewsSlice from "./business/reducerViews";

import typepeopleSlice from './typePeople/reducerTypepeople';
import typepeopleGruopSlice from './typePeople/reducerGroup';
import typepeopleGroupParameterizeSlice from './typePeople/reducerParametrizacion';
import typepeopleCategorySlice from './typePeople/reducerCategory';
import typepeopleCategoryGroupSlice from './typePeople/reducerGroupCategory';
import typepeopleCategoryGroupParameterizeSlice from './typePeople/reducerParameterize';

import peopleSlice from './people/reducerPeople';
import personDocumentSlice from './people/reducerPersonDocument';
import registrationLinkSlice from './people/reducerRegistrationLink';

import documentsAutoSlice from './documents/reducerGenerateDocument';

import legalCaseSlice from './legalCase/reducerLegalCase';
import clusterLegalCaseSlice from './legalCase/reducerClusterLegalCase';
import parameterizationLegalCaseSlice from './legalCase/reducerParametrizacionLegalCase';
import categoryLegalCaseSlice from './legalCase/reducerCategoryLegalCase';
import clusterCategoryLegalCaseSlice from './legalCase/reducerClusterCategory';
import parameterizationCategoryLegalCaseSlice from './legalCase/reducerParameterizationCategory';

import createCaseSlice from './createCase/reducerCreateCase';

import globalCodesSlice from './configuration/reducerGlobalCodes';
import utilitiesCodeTypePeopleSlice from './configuration/utilitiesCodeTypePeopleSlice';
import zapsignConfigurationSlice from './configuration/reducerZapsignConfiguration';
import labelGlobalSlice from './configuration/reducerLabelGlobal';
import colorsSlice from './configuration/reducerColors';
import configCronjobExpedientSlice from './configuration/reducerConfigCronjobExpedient';
import expedientGroupsSlice from "./configuration/reducerExpedientGroups";

import formSlice from "./forms/reducerFormSlice";
import clusterFormSlice from "./forms/reducerClusterForm";
import parameterizationFormSlice from "./forms/reducerParameterizationForm";
import actorFormSlice from "./forms/reducerActorForm";
import parameterizationActorFormSlice from "./forms/reducerParameterizationActorForm";
import dialogsFormSlice from "./forms/reducerDialogForm";
import createProcessFormSlice from "./forms/reducerCreateProcessForm";
import virtualNotaryProcessSlice from "./forms/reducerVirtualNotaryProcess";

import businessChatSlice from "./chat/reducerChatSlice";
import mailSlice from "./mails/reducerMails";

import typeProcessSlice from "./expedient/reducerTypeProcess";
import typeProcessMovementSlice from "./expedient/reducerTypeProcessMovement";
import expedientSlice from "./expedient/reducerExpedient";
import partsSlice from "./expedient/reducerParts";
import movementsSlice from "./expedient/reducerMovements";
import storageExpedientSlice from "./expedient/reducerStorageExpedient";

import chatGptSlice from "./chatgpt/reducerChatGpt";


import calendarslice from "./calendar/reducerCalendar"
import reducerImporterSlice from "./importer/reducerImporter";

import customMailSlice from "./customMail/reducerCustommail"

const reducer = {
    toastSlice,
    menuSlice,
    alertConsumptionSlice,
    loadingStateSlice,
    toastProgressSlice,
    rolesSlice,
    permissionsSlice,
    copyFormSlice,
    copyGroupSlice,
    userSlice,
    storageUserSlice,
    loginSlice,
    recoverCredentialsSlice,
    businessSlice,
    companyMetricsSlice,
    viewsSlice,

    typepeopleSlice,
    typepeopleGruopSlice,
    typepeopleGroupParameterizeSlice,
    typepeopleCategorySlice,
    typepeopleCategoryGroupSlice,
    typepeopleCategoryGroupParameterizeSlice,

    peopleSlice,
    personDocumentSlice,
    registrationLinkSlice,

    documentsAutoSlice,

    legalCaseSlice,
    clusterLegalCaseSlice,
    parameterizationLegalCaseSlice,
    categoryLegalCaseSlice,
    clusterCategoryLegalCaseSlice,
    parameterizationCategoryLegalCaseSlice,

    createCaseSlice,

    globalCodesSlice,
    utilitiesCodeTypePeopleSlice,
    zapsignConfigurationSlice,
    labelGlobalSlice,
    colorsSlice,
    configCronjobExpedientSlice,
    expedientGroupsSlice,

    formSlice,
    clusterFormSlice,
    parameterizationFormSlice,
    actorFormSlice,
    parameterizationActorFormSlice,
    dialogsFormSlice,
    createProcessFormSlice,
    virtualNotaryProcessSlice,

    businessChatSlice,
    mailSlice,

    typeProcessSlice,
    typeProcessMovementSlice,
    expedientSlice,
    partsSlice,
    movementsSlice,
    storageExpedientSlice,

    chatGptSlice,

    calendarslice,
    reducerImporterSlice,

    customMailSlice,
};

export default reducer;