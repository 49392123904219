import { createSlice } from "@reduxjs/toolkit";

export const utilitiesCodeTypePeopleSlice = createSlice({
    name: "utilitiesCodeTypePeopleSlice",
    initialState: {
        list: [],
    },
    reducers: {
        setList: (state, action) => {
            state.list = action.payload.value;
        },
        deleteFromCodes: (state, action) => {
            const { id, is_category } = action.payload.value;

            state.list = state.list.map(field => {
                if(is_category){
                    if (field.id === id && field.category_peoples_id) {
                        return {
                            ...field,
                            state: false,
                            utilities_id: 0,
                            type_category: "SELECCIONAR",
                        };
                    }
                }
                if (field.id === id) {
                    return {
                        ...field,
                        state: false,
                        utilities_id: 0,
                        type_category: "SELECCIONAR",
                    };
                }
                return field;
            });
        },
        updateFromCodes: (state, action) => {
            const { id, is_category, type_category } = action.payload.value;

            state.list = state.list.map(field => {
                if(is_category){
                    if (field.id === id && field.category_peoples_id) {
                        return {
                            ...field,
                            type_category,
                        };
                    }
                }
                if (field.id === id) {
                    return {
                        ...field,
                        type_category,
                    };
                }
                return field;
            });
        },
        insertFromCodes: (state, action) => {
            const { id, is_category, type_category, utilities_id } = action.payload.value;

            state.list = state.list.map(field => {
                if(is_category){
                    if (field.id === id && field.category_peoples_id) {
                        return {
                            ...field,
                            state: true,
                            utilities_id,
                            type_category,
                        };
                    }
                }
                if (field.id === id) {
                    return {
                        ...field,
                        state: true,
                        utilities_id,
                        type_category,
                    };
                }
                return field;
            });
        },
    },
});

export const {
    setList,
    deleteFromCodes,
    updateFromCodes,
    insertFromCodes,
} = utilitiesCodeTypePeopleSlice.actions;

export default utilitiesCodeTypePeopleSlice.reducer;