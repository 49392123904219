import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Select from 'react-select';

import { FolderIcon } from './FolderIcon';
import { ContextMenuColor } from './ContextMenuColor';

import GeneralService from '../../../../services/GeneralService';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import { reducerForm, setSelected } from '../../../../reducers/expedient/reducerExpedient';
import { toast, MessageError, dateIsZero } from '../../../../utils';
import { useContextMenu } from '../../../../app/hooks/useContextMenu';
import { loadOptionUser } from '../../parameterization/utils/utils';
import { reactSelectStyles } from '../styles/reactSelectStyles';

export const FormExpedient = ({ setKey }) => {
    const { selectedExpedient } = useSelector(state => state.expedientSlice);
    const { list } = useSelector(state => state.colorsSlice);
    const { list: listProcess } = useSelector(state => state.typeProcessSlice);
    const { list: listUsers } = useSelector(state => state.userSlice);
    const { list: listGroups } = useSelector(state => state.expedientGroupsSlice);
    const { cardRef, contextMenuVisible, handleContextMenu,
        handleHideContextMenu, handleClick, contextMenuPosition } = useContextMenu(() => { });
    const service = new GeneralService("expedient");
    let { id = 0 } = useParams();
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const handleSelectedUser = (selected, actionMeta) => {
        dispatch(reducerForm({
            key: "user_id",
            value: selected.value,
        }));
    }

    const onChangeColor = (color) => {
        dispatch(reducerForm({
            key: "colors_id",
            value: color,
        }))
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let data = {
            ...selectedExpedient,
            type_process_id: parseInt(selectedExpedient.type_process_id),
            expedient_group_id: parseInt(selectedExpedient.expedient_group_id),
            finish_date: dateIsZero(selectedExpedient.finish_date) ? undefined : selectedExpedient.finish_date,
            color: undefined,
            business: undefined,
            type_process: undefined,
        }

        if (selectedExpedient.id === ID_NOT_DEFINED) {
            const res = await service.post(data);
            if (res.is_ok) {
                setKey("parts");
                toast(dispatch, res.message);
                dispatch(setSelected(res.expedient));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(data);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(setSelected(data));
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="container-fluid" style={{ minHeight: "25rem" }}>
                <div className="row">
                    <div className="col-12 col-xl-7">

                        <div className="row">
                            <div className="col d-flex justify-content-between align-items-md-end">
                                <div ref={cardRef} className="folder-container" onClick={handleContextMenu}>
                                    <FolderIcon
                                        height={35}
                                        width={46}
                                        color={
                                            id === ID_NOT_DEFINED && selectedExpedient.colors_id === ID_NOT_DEFINED
                                                ?
                                                "4EA07A"
                                                :
                                                list.find(itemColor => itemColor.id === selectedExpedient.colors_id)?.color
                                        }
                                    />
                                    <img src="/images/iconColor.png" className='d-inline-block mb-3' />
                                </div>
                                <div className="d-inline-block w-75">
                                    <label className="font-rubik">Nombre expediente<b><i className="text-red">*</i></b></label>
                                    <input
                                        type="text"
                                        className="form-control green-input"
                                        name="name"
                                        placeholder="Digite un nombre"
                                        value={selectedExpedient.name}
                                        maxLength={255}
                                        minLength={3}
                                        onChange={setData}
                                    />
                                </div>
                                <div className="d-inline-block ms-2 text-center w-10">
                                    <div className="bg-light-green px-3 py-2 rounded-1 fw-bold">
                                        <i className="fa-solid fa-hashtag me-1 text-purple"></i>
                                        {selectedExpedient.number ? selectedExpedient.number : ""}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2 pe-0">
                            <div className="col pe-0">
                                <label className="font-rubik">Tipo de proceso<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select green-input"
                                    name="type_process_id"
                                    value={selectedExpedient.type_process_id}
                                    onChange={setData}
                                    required
                                >
                                    <option value="0">
                                        Seleccione...
                                    </option>
                                    {
                                        listProcess.map(process => <option value={process.id} key={process.id}>
                                            {process.name}
                                        </option>)
                                    }
                                </select>
                            </div>
                            <div className="col pe-0">
                                <label className="font-rubik">Grupo de expediente<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select green-input"
                                    name="expedient_group_id"
                                    value={selectedExpedient.expedient_group_id}
                                    onChange={setData}
                                    required
                                >
                                    <option value="0">
                                        Todos
                                    </option>
                                    {
                                        listGroups.map(group => <option value={group.id} key={group.id}>
                                            {group.name}
                                        </option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="row mt-2 pe-0">
                            <div className="col pe-0">
                                <label className="font-rubik">Responsable<b><i className="text-red">*</i></b></label>
                                <Select
                                    options={[
                                        {
                                            value: undefined,
                                            label: "Ninguno",
                                        },
                                        ...listUsers.map(itemUser => ({
                                            value: itemUser.id,
                                            label: itemUser.name + " " + itemUser.surname,
                                        }))
                                    ]}
                                    value={loadOptionUser(listUsers, selectedExpedient)}
                                    onChange={handleSelectedUser}
                                    styles={reactSelectStyles}
                                    isSearchable={true}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-2 pe-0">
                            <div className="col pe-0">
                                <label className="font-rubik">Fecha de inicio<b><i className="text-red">*</i></b></label>
                                <input
                                    type="datetime-local"
                                    className="form-control green-input"
                                    name="start_date"
                                    value={selectedExpedient.start_date}
                                    onChange={setData}
                                    required
                                />
                            </div>

                            <div className="col pe-0">
                                <label className="font-rubik">Fecha de finalización</label>
                                <input
                                    type="datetime-local"
                                    className="form-control green-input"
                                    name="finish_date"
                                    value={dateIsZero(selectedExpedient.finish_date) ? undefined : selectedExpedient.finish_date}
                                    onChange={setData}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <label className="font-rubik">Observaciones</label>
                        <textarea
                            className="form-control textarea-style green-input mt-1"
                            name="observations"
                            value={selectedExpedient.observations}
                            maxLength={2000}
                            placeholder="Digita las observaciones"
                            onChange={setData}
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="col">
                        <button type="submit" title={selectedExpedient.id === ID_NOT_DEFINED ? "Crear" : "Actualizar"}
                            className="btn btn-success rounded-pill mx-auto d-block mt-4"
                            onClick={() => { }}
                        >
                            <i className="fa-solid fa-paper-plane text-white me-2" />
                            {selectedExpedient.id === ID_NOT_DEFINED ? "Crear" : "Actualizar"}
                        </button>
                    </div>
                </div>
            </div>
            <ContextMenuColor
                visible={contextMenuVisible}
                x={contextMenuPosition.x}
                y={contextMenuPosition.y}
                onHide={handleHideContextMenu}
                colors={list}
                selectedColor={selectedExpedient.colors_id}
                onChangeColor={onChangeColor}
            />
        </form>
    )
}
