
export const LoadingMessage = ({ avatar }) => {
    return (
        <div className="message mb-3">
            <div className="avatar">
                {avatar}
            </div>
            <div className="body">
                <div className="text pb-3">
                    <div className="spinner-grow spinner-grow-sm text-success me-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm text-success me-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm text-success me-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}