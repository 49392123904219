import { useSelector, useDispatch } from 'react-redux';

import IconButton from '../../../app/components/IconButton';

import { deleteRole, setSelectedRole } from "../../../reducers/roles/reducerRoles";
import confirmDelete from '../../../utils/confirmDelete';
import { MessageSuccess } from '../../../utils/message';
import GeneralService from '../../../services/GeneralService';

export const ListRoles = ({ setShow }) => {
    const { listFilter, selectedRole } = useSelector(state => state.rolesSlice);
    const dispatch = useDispatch();

    const deleObject = async (id) => {
        const service = new GeneralService("roles");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess("El registro ha sido eliminado exitosamente!");
            dispatch(deleteRole({ value: id }));
            return;
        }
    }

    const handleEdit = (role) => {
        dispatch(setSelectedRole(role));
        setShow(true);
    }

    return (
        <div className="order">
            <div className="d-block rounded-3 clip-hide">
                <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter?.length > 0 && "table-striped"}`}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>#</th>
                            <th>Rol</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listFilter?.length === 0 ?
                                <tr>
                                    <td colSpan={5}>
                                        <div className="alert bg-green text-center mt-2" role="alert">
                                            No hay accesos que mostrar {":)"}
                                        </div>
                                    </td>
                                </tr>
                                :
                                listFilter?.map((item, i) => (
                                    <tr
                                        key={item.id}
                                        onClick={() => dispatch(setSelectedRole(item))}
                                        onDoubleClick={() => handleEdit(item)}
                                        className={selectedRole.id === item.id ? "bg-green" : ""}
                                    >
                                        <td></td>
                                        <td style={{width: "40px"}}>{i + 1}.</td>
                                        <td>{item.name}</td>
                                        <td>
                                            <IconButton
                                                icon="fa-solid fa-pen-to-square text-green"
                                                title="Editar rol"
                                                onClick={() => handleEdit(item)}
                                            />
                                            <IconButton
                                                icon="fa-solid fa-trash-can text-red"
                                                title="Eliminar rol"
                                                onClick={async () => await confirmDelete(() => deleObject(item.id))}
                                            />
                                            <IconButton
                                                icon="fa-solid fa-circle-arrow-right text-green"
                                                title="Seleccionar"
                                                onClick={() => dispatch(setSelectedRole(item))}
                                            />
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};
