import IconButton from "../../../app/components/IconButton";

import IconForm from "../assets/icon-form.png";

const CardForm = ({ form, onClick = () => { } }) => {

    return (
        <div title={form.description} className="col-md-5 col-lg-4 px-3 card-model__form animate__animated animate__fadeIn" onClick={onClick}>
            <div className="row">
                <div className="col-md-12 col-lg-3 abs-center">
                    <img src={IconForm} className="ps-2" alt="" />
                </div>
                <div className="col-md-12 col-lg-7">
                    <span className="font-lilita-one mb-2 fs-6">{form.name}</span>
                    <div className="description-card__form">
                        <p className='fs-7'>{form.description}</p>
                    </div>
                </div>
                <div className="col-md-12 col-lg-2 abs-center">
                    <IconButton
                        title="Diligenciar este formulario"
                        icon="fa-solid fa-arrow-up-right-dots text-purple"
                        fontSize='20'
                    />
                </div>
            </div>
        </div>
    )
}

export default CardForm;