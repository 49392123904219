import { useRef } from 'react';

import './styles/modalBottom.css';
import IconButton from './IconButton';

export const ModalBottom = ({ isOpen, onClose, children }) => {
    const contentRef = useRef();

    const handleClickOutside = (event) => {
        if (contentRef.current && !contentRef.current.contains(event.target)) {
            onClose();
        }
    };

    return (
        <div className={`modal-bottom ${isOpen ? 'show' : ''}`} onClick={handleClickOutside}>
            <div ref={contentRef} className="modal-bottom-content">
                {children}
            </div>
        </div>
    );
};

export const HeaderModalBottom = ({ children, onHide }) => {
    return (
        <div className="d-flex justify-content-between align-center mb-2">
            <div>
                {children}
            </div>
            <IconButton
                title="Cerrar"
                icon="fa-solid fa-xmark text-green"
                onClick={onHide}
            />
        </div>
    )
}