import DynamicTable from "../../../TercerosCreate/components/DynamicTable";
import { SelectAndCreate } from "../../../TercerosCreate/components/SelectAndCreate";
import { SearchBarPeople } from "../../../TercerosCreate/components/SearchBarPeople";
import { CardActorLabel } from "./CardActorLabel";

import CardActorViewSignature from "../../../TercerosCreate/components/CardActorViewSignature";
import { useHandleActors } from "./useHandleActors";
import { toast } from "../../../../utils/toast";
import { ALERT_DANGER } from "../../../../constant/constant";

const TabActors = ({ canEdit = true, setCurrentStep }) => {
    const { totalLabels, activeTypePeople, handleSelectedPerson, selectedPeople, dispatch } = useHandleActors();

    return (
        <div className="process-tab">
            <div className="row">
                <div className="col-8">
                    <h5><b>Selecciona los actores</b></h5>
                    <p>Este documento acepta <strong>{totalLabels} etiquetas</strong>. A continuación encontrarás los tipos de personas que
                        fueron configurados para que hagas la búsqueda de los actores respectivos.</p>

                    <div className="table-data">
                        <div className='container-fluid'>
                            <div className='row'>
                                <SelectAndCreate
                                    activeTypePeople={activeTypePeople}
                                    showCreateButton={false}
                                    showCreateLinkButton={false}
                                />
                            </div>
                            <div className='row mt-3'>
                                <SearchBarPeople forParameterization={false} />
                            </div>
                            <div className='row' style={{ height: "20rem", overflowY: "auto" }}>
                                <DynamicTable
                                    actionName="Seleccionar"
                                    forParameterization={false}
                                    onPersonSelected={(person) => {
                                        if (!canEdit) {
                                            toast(dispatch, "No puedes modificar esta sección", ALERT_DANGER);
                                            return;
                                        }
                                        handleSelectedPerson(person);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-4">
                    <div className="actors-selected__tab">
                        <h5 className="font-lato fw-bold text-center pb-2">
                            <i className="fa-regular fa-circle-check me-2"></i>
                            Seleccionados
                        </h5>
                        <hr style={{ color: "green" }} />
                        <div className="row actors-selected__list">
                            <div className="col">
                                {
                                    selectedPeople.length === 0
                                        ?
                                        <div className="alert alert-warning text-center mt-2" role="alert">
                                            No hay actores seleccionados
                                        </div>
                                        :
                                        (
                                            canEdit
                                                ?
                                                selectedPeople.map(person => <CardActorLabel key={person?.people?.id} person={person} />)
                                                :
                                                selectedPeople.map(person => <CardActorViewSignature key={person.id} actor={person} label={person.label?.name} />)
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col">
                    <button
                        type="button"
                        className="btn btn-success rounded-3 d-block ms-auto mt-4 px-3 py-2"
                        onClick={() => setCurrentStep(c => c + 1)}
                    >
                        Siguiente paso <i className="fa-solid fa-caret-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TabActors;