

export const textFormCodes = (form) => {
    let text = "";
    text += `${form.name}\n\n`;
    text += `Grupos de datos\n`;
    form.clusters?.forEach((cluster, i) => {
        text += `-> Grupo #${i + 1}: ${cluster.name}\n`;
        cluster.parameterizations.forEach(field => {
            text += `   - @${field.code_parameterization}: ${field.name}\n`;
        });
    })
    text += `\n\nActores\n`;
    form.actors?.forEach((actor, i) => {
        text += `-> Actor #${i + 1}: ${actor.name}\n`;
        actor.parameterizations?.forEach(field => {
            text += `   - @${field.code_parameterization}: ${field.name}\n`;
        });
    })

    return text;
}

export const textQuestionCode = (questions) => {
    let text = "\nCuestionario\n";
    questions.forEach(question => {
        if (question.code_questions) {
            text += ` - @${question.code_questions.code}: ${question.name}\n`;
        }
    });

    return text;
}