import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultProfile from "../../../modules/Usuarios/Assets/profile-pic.svg";
import GeneralService from "../../../services/GeneralService";
import { MessageError, toast } from "../../../utils";
import { useDownloadImageAsBase64 } from "../../hooks/useDownloadImageAsBase64";

const Header = ({ onChangeBusiness }) => {
  const [dark, setDark] = useState(false);
  const { profile, businessProfiles } = useSelector(state => state.loginSlice);
  const {isLoading, file} = useDownloadImageAsBase64(profile.url_avatar);
  const { company } = useSelector(state => state.businessSlice);
  const dispatch = useDispatch();

  const switchMode = () => {
    if (!dark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
    setDark(!dark);
  }

  const selectBusiness = async (business_id) => {
    if (profile.business_id === business_id) return;

    const service = new GeneralService("user/set-favorite-business");
    const res = await service.post({ business_id });
    if (res.is_ok) {
      toast(dispatch, res.message);
      onChangeBusiness();
      return;
    }
    MessageError(res.message);
  }

  return (
    // <nav className="header-principal font-lato">
    <nav className="header__layout font-lato">
      {/* <input type="checkbox" id="switch-mode" hidden />
        <label htmlFor="switch-mode" className="switch-mode" onClick={()=>switchMode()}></label> */}
      <div>
        <a href="#/" className="d-inline-block profile">
          <img src={profile.url_avatar && !isLoading ? file : DefaultProfile} alt={profile.name} />
        </a>

        <div className="dropdown d-inline-block ms-2">
          <a className="btn dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa-solid fa-building text-green"></i> {company.name}
          </a>

          <ul className="dropdown-menu">
            {businessProfiles.map(item => (
              <li
                key={item.id}
                role="button"
                onClick={() => selectBusiness(item.business_id)}
              >
                <div className="dropdown-item">{item.business?.name}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col">
            <i className="fa-solid fa-bell text-purple fs-5"></i>
            <span className="position-absolute top-2 start-1 translate-middle badge rounded-pill bg-info p-1">
              1
              <span className="visually-hidden">unread messages</span>
            </span>
          </div>

          <div className="col">
            <i className="fa-solid fa-message text-purple fs-5"></i>
            <span className="position-absolute top-2 start-1 translate-middle badge rounded-pill bg-info p-1">
              5
              <span className="visually-hidden">unread messages</span>
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;