import { useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import Home from '../app/components/Home'

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import GeneralRoutes from './AuthRoutes';
import CustomerRoutes from './CustomerRoutes';
import PublicSortomFormRoutes from './PublicSortomFormRoutes';
import PublicCompanyFormRoutes from './PublicCompanyFormRoutes';
import MixedRoute from './MixedRoute';
import { UserRegistrationRoutes } from './UserRegistrationRoutes';

const AppRouter = () => {
    const { auth } = useSelector(state => state.loginSlice.auth);

    return (
        <Router>
            <div>
                <Switch>
                    <PublicRoute
                        isAuthenticated={auth}
                        exact
                        path="/auth/*"
                        component={GeneralRoutes}
                    />

                    <MixedRoute
                        exact
                        path="/customer/*"
                        component={CustomerRoutes}
                    />

                    <MixedRoute
                        exact
                        path="/user-registration/*"
                        component={UserRegistrationRoutes}
                    />

                    <MixedRoute
                        exact
                        path="/virtual-notary/*"
                        component={PublicSortomFormRoutes}
                    />

                    <MixedRoute
                        exact
                        path="/forms/*"
                        component={PublicCompanyFormRoutes}
                    />

                    <PrivateRoute
                        isAuthenticated={auth}
                        path="/"
                        component={Home}
                    />
                </Switch>
            </div>
        </Router>
    );
}

export default AppRouter;