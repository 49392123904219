import { getMonthName } from "../../../utils";

export const processDataViews = (data) => {
    const allDates = new Set();
    const sortomData = {};
    const notaryData = {};

    data.sortom.forEach(item => {
        allDates.add(item.date);
        sortomData[item.date] = item.count;
    });

    data.notary.forEach(item => {
        allDates.add(item.date);
        notaryData[item.date] = item.count;
    });

    const datesArray = Array.from(allDates).sort();
    const labels = datesArray.map(date => getMonthName(date) + " " + date.substring(8, 10));
    const sortomCounts = datesArray.map(date => sortomData[date] || 0);
    const notaryCounts = datesArray.map(date => notaryData[date] || 0);

    return { labels, sortomCounts, notaryCounts };
};