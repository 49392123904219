import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

import service from '../services/servicePeople';
import { reducerForm, reducerFormSearch, resetDataSearchPeople, setListFoundPeople } from "../../../reducers/people/reducerPeople";
import { ALERT_INFO, HTTP_NO_CONTENT, ID_NOT_DEFINED } from "../../../constant/constant";
import { extractFieldsTable } from "../../DocumentsAuto/utils/functions";
import Settings from "../assets/settings.svg";
import { MessageInfo, toast } from "../../../utils";
import GeneralService from "../../../services/GeneralService";
import Search from "../assets/search.svg";

export const SearchBarPeople = ({ forParameterization = true }) => {
    const { dataTypePeole, dataSearchPeople, fieldsTypePeople, refreshTable, fieldsPeopleTable } = useSelector(state => state.peopleSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const filtered = fieldsPeopleTable.filter(item => item.state);

    const setDataInput = (e) => {
        dispatch(reducerFormSearch({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const setData = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleConfiguration = () => {
        history.push({
            pathname: "/configurations",
            search: `?tabIndex=1`,
        });
    }

    const searchPeople = async () => {
        if (parseInt(dataTypePeole.type_people_id) === ID_NOT_DEFINED) {            
            toast(dispatch, "No se ha seleccionado el tipo de persona", 8000, ALERT_INFO);
            return;
        }

        const keys = Object.keys(dataSearchPeople);
        const list = [];

        keys.forEach(e => {
            if (dataSearchPeople[e].parameterization_id && dataSearchPeople[e].value !== "") {
                list.push(dataSearchPeople[e]);
            }
        });

        if (list.length > 0) {
            const res = await service.findPeople({ parameterizations: list });
            if (res.data.is_ok) {
                const { people = [] } = res.data;
                const proccess = extractFieldsTable(people, filtered);
                dispatch(setListFoundPeople({ value: proccess }));
                return;
            }
            if (res.status === HTTP_NO_CONTENT) {
                MessageInfo("No se encontró ningún tercero");
                dispatch(setListFoundPeople({ value: [] }));
            }
        }
        else {
            await loadAll();
        }
    }

    // Resetea el campo de búsqueda para hacer que el refresh de la tabla cuando se elimine sea llamando este método el cual lista todos los terceros.
    const loadAll = async () => {
        if (parseInt(dataTypePeole.type_people_id) === ID_NOT_DEFINED) {
            toast(dispatch, "No se ha seleccionado el tipo de persona", 8000, ALERT_INFO);
            return;
        }

        dispatch(resetDataSearchPeople());
        const service = new GeneralService("people");
        const res = await service.getList(1000, { type_peoples_id: dataTypePeole.type_people_id });
        const { people = [] } = res;
        const proccess = extractFieldsTable(people, filtered);
        dispatch(setListFoundPeople({ value: proccess }));
    }

    useEffect(() => {
        searchPeople();
    }, [refreshTable]);

    return (
        <div className='search-bar rounded-3 p-1'>
            <div className='row'>
                <div className='col-1' style={{ maxWidth: "40px" }}>
                    <img src={Search} className="mb-0 mt-2 ms-2 img-button-tercero" alt="Configuraciones" style={{ color: "#158B3D" }} />
                </div>
                <div className='col-4'>
                    <input
                        type="text"
                        name={dataTypePeole.field_favorite_id.split("--")[1] ?? "none"}
                        className='mt-1 ps-1 py-1 input-borderless'
                        placeholder={dataTypePeole.field_favorite_id === "" ? "Selecciona un campo favorito" : 'Digita el dato'}
                        required
                        value={dataSearchPeople[dataTypePeole.field_favorite_id.split("--")[1]]?.value ?? ""}
                        autoComplete='off'
                        onChange={setDataInput}
                        readOnly={dataTypePeole.field_favorite_id === ""}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") searchPeople();
                        }}
                    />
                </div>

                <div className='col-3'>
                    <select
                        className="form-select input-borderless"
                        name="field_favorite_id"
                        style={{ backgroundColor: '#fafafa', border: 'none', height: '44px' }}
                        value={dataTypePeole.field_favorite_id}
                        onChange={setData}
                    >
                        <option value="0">
                            Seleccione
                        </option>
                        {
                            fieldsTypePeople.filter(item => item.state).map((item) => (
                                <option value={`${item.id}--${item.name}--${item.category_peoples_id ? 1 : 0}`} key={uuidv4()}>
                                    {item.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <div className='col'>
                    <Button title="Haz la búsqueda con los parámetros especificados" className='mt-1' variant="success" onClick={searchPeople}>
                        Buscar
                    </Button>
                </div>
                {
                    forParameterization && <>
                        <div className='col'>
                            <Button title="Listar en la tabla todos los terceros existentes" className='mt-1' variant="success" onClick={loadAll}>
                                <i className="fa-solid fa-list-ol text-white me-1" style={{ fontSize: '16px' }} />
                                Listar todo
                            </Button>
                        </div>

                        <div className='col'>
                            <img src={Settings} className="mb-0 mt-2 img-button-tercero d-block ms-auto me-3" title="Ir a las configuraciones códigos favoritos y códigos de tablas" alt="Configuraciones" role="button" tabIndex="0" onClick={() => handleConfiguration()} />
                        </div>
                    </>
                }

            </div>
        </div>
    )
}
