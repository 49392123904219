import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../parametrize/style/tree.css";
import { ALERT_DANGER, ID_NOT_DEFINED, TYPE_ANSWER } from "../../../../constant/constant";
import { addListAnswers, deleteListAnswers, setSelectedGenerateAnswer } from "../../../../reducers/documents/reducerGenerateDocument";
import ModalOpenQuestion from "./ModalOpenQuestion";
import IconButton from "../../../../app/components/IconButton";
import confirmDelete from "../../../../utils/confirmDelete";
import DialogsBySteps from "./DialogsBySteps";
import { toast } from "../../../../utils/toast";
import CardAnswer from "../../../../app/components/CardAnswer";
import GeneralService from "../../../../services/GeneralService";
import { MessageError } from "../../../../utils/message";
import CardAnswerGenerated from "./CardAnswerGenerated";

const TabDialogs = ({ canEdit = true, setCurrentStep }) => {
    const { listQuestions, listAnswers } = useSelector(state => state.documentsAutoSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const answersRef = useRef(null);

    const onTapCode = (obj, type) => {
        if (!canEdit) {
            toast(dispatch, "Ya no puedes editar estos parámetros", ALERT_DANGER);
            return;
        }
        if (listAnswers.some(answer => answer.code_questions_id === obj.code_questions_id)) {
            toast(dispatch, "La respuesta ya ha sido agregada antes", ALERT_DANGER);
            return;
        }
        if (type === TYPE_ANSWER) {
            dispatch(addListAnswers({ value: { ...obj, type, answer_text: obj.answer, type_answer: obj.name, answers_id: obj.id, id: 0 } }));
            toast(dispatch, "Respuesta agregada correctamente");
            scrollToBottom();
            return;
        }
        dispatch(setSelectedGenerateAnswer({
            value: {
                ...obj,
                type,
                code_questions_id: obj.code_questions.id,
                answer_text: "",
                contextual_text: obj.answer,
                id: 0,
            }
        }));
        setShow(true);
    }

    const handleDelete = async (item) => {
        if (item.id !== ID_NOT_DEFINED) {
            const service = new GeneralService("answers-questinos-generate");
            const res = await service.delete(item.id);
            if (!res.is_ok) {
                MessageError(res.message, service.status);
                return;
            }
        }
        dispatch(deleteListAnswers({ value: item.code_questions_id }));
        toast(dispatch, "Eliminado correctamente");
    }

    const scrollToBottom = () => {
        if (answersRef.current) {
            setTimeout(() => {
                answersRef.current.scrollTop = answersRef.current.scrollHeight;
            }, 100);
        }
    };

    return (
        <>
            <div className="process-tab">
                <div className="row">
                    <div className="col-12 col-lg-7 border-right-dashed mb-3">
                        <div className="row">
                            <div className="col">
                                <h5><b>Diálogos</b></h5>
                            </div>
                        </div>
                        <p>Tienes {listQuestions.length} preguntas para responder. Cada una tiene su respectiva secuencia, asegúrate de responderlas en su totalidad.</p>

                        <DialogsBySteps onTapCode={onTapCode} />
                    </div>
                    <div className="col-12 col-lg-5 px-3" style={{ position: "relative" }}>
                        <div className="row">
                            <div className="col">
                                <h5 className="font-lato fw-bold pb-2">
                                    <i className="fa-solid fa-circle-nodes me-2"></i>
                                    Respuestas
                                </h5>
                            </div>
                        </div>
                        <div ref={answersRef} className="row" style={{ maxHeight: "24.5rem", overflowY: "auto" }}>
                            <div className="col">
                                {
                                    listAnswers.length === 0
                                        ?
                                        <div className="alert alert-warning text-center mt-1" role="alert">
                                            No has respondido a ninguna pregunta
                                        </div>
                                        :
                                        listAnswers.map(item => canEdit ? <CardAnswer
                                            key={item.code_questions_id}
                                            item={item}
                                            actions={
                                                <>
                                                    <IconButton
                                                        icon="fa-solid fa-edit text-green"
                                                        title="Editar respuesta"
                                                        onClick={() => {
                                                            dispatch(setSelectedGenerateAnswer({ value: { ...item, edit: true } }));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash text-red"
                                                        title="Eliminar respuesta"
                                                        onClick={async () => confirmDelete(() => handleDelete(item))}
                                                    />
                                                </>
                                            }
                                        />
                                            :
                                            <CardAnswerGenerated item={item} key={item.code_questions_id} />
                                        )
                                }
                            </div>
                        </div>
                        <div className="row" style={{ position: "absolute", bottom: "0px", right: "10px" }}>
                            <div className="col mt-5">
                                <button type="submit" className="btn btn-success rounded-3 d-block ms-auto px-3 py-2" onClick={() => setCurrentStep(c => c + 1)} >
                                    Siguiente paso <i className="fa-solid fa-caret-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalOpenQuestion show={show} setShow={setShow} handleScrollAnswers={scrollToBottom} />
        </>
    );
}

export default TabDialogs;