
const CardLabelActor = ({ name, active = false, onClick }) => {
    return (
        <span
            className={`badge rounded-pill ${active ? "bg-purple" : "bg-secondary"} mx-1 my-1`}
            role="button"
            onClick={onClick}
        >
            <i className="fa-solid fa-tag"></i>
            <span className="text-white ms-1">{name}</span>
        </span>
    )
}

export default CardLabelActor;