import { useSelector, useDispatch } from 'react-redux';

import GeneralService from '../../../services/GeneralService';
import { MessageError, toast } from '../../../utils';
import { ACTION_CAN_DELETE, ACTION_CAN_READ, ACTION_CAN_WRITE, APP_MODULES, HTTP_CREATED, HTTP_OK, MODULE_BUSINESS, MODULE_NOTIFICATIONS, MODULE_ROLES } from '../../../constant/constant';
import CardSwitch from '../../../app/components/CardSwitch';
import { setPermission } from '../../../reducers/roles/reducerPermissions';
import IconButton from '../../../app/components/IconButton';

export const ListPermissions = () => {
    const { list } = useSelector(state => state.permissionsSlice);
    const { company } = useSelector(state => state.businessSlice);
    const { selectedRole } = useSelector(state => state.rolesSlice);
    const dispatch = useDispatch();
    const service = new GeneralService("permissions");

    const setDataSwitch = async (item, module_id, name, value) => {
        const data = { ...item, role_id: selectedRole.id, [name]: value };

        if (!item.id) {
            const res = await service.post(data);
            if (service.status === HTTP_CREATED) {
                dispatch(setPermission({ id: res.data?.id, module_id, name, value }));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(data);
        if (service.status === HTTP_OK) {
            dispatch(setPermission({ id: item.id, module_id, name, value }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const selectAll = async (action) => {
        const modules = Object.keys(APP_MODULES);

        for (let i = 0; i < list.length; i++) {
            if (list[i][action]) continue;

            for (let k = 0; k < modules.length; k++) {
                if ((modules[k] === MODULE_BUSINESS && !company.is_system_admin) || modules[k] === MODULE_ROLES) continue;
                await setDataSwitch(list[i], parseInt(modules[k]), action, true);
            }
        }
        toast(dispatch, "Completado");
    }

    return (
        <div className="order">
            <div className="d-block rounded-3 clip-hide">
                <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${list?.length > 0 && "table-striped"}`}>
                    <thead>
                        <tr className='font-lato'>
                            <th></th>
                            <th>Módulo</th>
                            <th className="fs-7 text-center">Lectura
                                <IconButton
                                    icon="fa-solid fa-square-check text-white"
                                    title="Todos pueden leer"
                                    onClick={() => selectAll(ACTION_CAN_READ)}
                                />
                            </th>
                            <th className="fs-7 text-center">Escritura
                                <IconButton
                                    icon="fa-solid fa-square-check text-white"
                                    title="Todos pueden escribir"
                                    onClick={() => selectAll(ACTION_CAN_WRITE)}
                                />
                            </th>
                            <th className="fs-7 text-center">Eliminación
                                <IconButton
                                    icon="fa-solid fa-square-check text-white"
                                    title="Todos pueden eliminar"
                                    onClick={() => selectAll(ACTION_CAN_DELETE)}
                                />
                            </th>
                            <th className="fs-7 text-center pb-3">¿Accionable?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list?.map((item) => {
                                if (!company.is_system_admin && item.module_id === MODULE_BUSINESS) return null;

                                const hideSwitchAction = APP_MODULES[item.module_id] === APP_MODULES[MODULE_ROLES] || APP_MODULES[item.module_id] === APP_MODULES[MODULE_NOTIFICATIONS];

                                return (<tr key={item.module_id}>
                                    <td></td>
                                    <td><i className="fa-solid fa-angles-right text-green me-2"></i> {APP_MODULES[item.module_id]}</td>
                                    <td className='text-center'>
                                        {
                                            !hideSwitchAction
                                            &&
                                            <CardSwitch
                                                name="can_read"
                                                className='d-inline-block'
                                                checked={item.can_read}
                                                onChange={(e) => setDataSwitch(item, item.module_id, e.target.name, !item.can_read)}
                                                disabled={item.can_action}
                                            />
                                        }
                                    </td>
                                    <td className='text-center'>
                                        {
                                            !hideSwitchAction
                                            &&
                                            <CardSwitch
                                                name="can_write"
                                                className='d-inline-block'
                                                checked={item.can_write}
                                                onChange={(e) => setDataSwitch(item, item.module_id, e.target.name, !item.can_write)}
                                                disabled={item.can_action}
                                            />
                                        }
                                    </td>
                                    <td className='text-center'>
                                        {
                                            !hideSwitchAction
                                            &&
                                            <CardSwitch
                                                name="can_delete"
                                                className='d-inline-block'
                                                checked={item.can_delete}
                                                onChange={(e) => setDataSwitch(item, item.module_id, e.target.name, !item.can_delete)}
                                                disabled={item.can_action}
                                            />
                                        }
                                    </td>
                                    <td className='text-center'>
                                        {
                                            hideSwitchAction
                                                ?
                                                <CardSwitch
                                                    name="can_action"
                                                    className='d-inline-block'
                                                    checked={item.can_action}
                                                    onChange={(e) => setDataSwitch(item, item.module_id, e.target.name, !item.can_action)}
                                                />
                                                :
                                                <i title='No accionable' className="fa-solid fa-square-xmark text-orange"></i>
                                        }
                                    </td>
                                </tr>)
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};
