import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../../app/components/IconButton";
import ToolFilterDocuments from "./ToolFilterDocuments";
import ListGeneratedDocuments from "./ListGeneratedDocuments";

import { applyFilters, clearFilter, fillElatedDocuments } from "../../../reducers/people/reducerPersonDocument";
import GeneralService from "../../../services/GeneralService";
import { toast } from "../../../utils/toast";

const DocumentsPerPerson = ({ peopleId }) => {
  const { dataFilter } = useSelector(state => state.personDocumentSlice);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const loadDocuments = async () => {
    const service = new GeneralService("generate-document/list-per-person");
    const res = await service.getList(1000, { people_id: peopleId });
    const { generate_documents = [] } = res;

    dispatch(fillElatedDocuments({ value: generate_documents }));
  }

  const reloadInformation = async () => {
    dispatch(clearFilter());
    await loadDocuments();
    toast(dispatch, "Los datos han sido recargados");
  }

  useEffect(() => {
    dispatch(applyFilters({ value: { isPerPerson : true }}));
  }, [dataFilter]);

  useEffect(() => {
    loadDocuments();
  }, [peopleId]);

  return (
    <div className="col-12 mb-5">
      <div className="row rounded-top bg-green pt-3 pb-2 px-3">
        <div className="col">
          <i className="fa-solid fa-file-lines fs-4 d-inline-block me-3"></i> <h5 className="d-inline-block">Documentos relacionados</h5>
        </div>
        <div className="col-6">
          <IconButton
            icon={`fa-solid ${show ? "fa-filter-circle-xmark text-black" : "fa-filter text-purple"}`}
            title={show ? "Deshacer filtros" : "Aplicar filtros"}
            fontSize={20}
            onClick={() => {
              show && dispatch(clearFilter());
              setShow(c => !c);
            }}
          />
        </div>
        <div className="col-1">
          <IconButton
            icon="fa-solid fa-arrows-rotate purple"
            title="Recargar información"
            fontSize={20}
            onClick={reloadInformation}
          />
        </div>
      </div>
      {
        show && <ToolFilterDocuments isPerPerson={true} />
      }
      <ListGeneratedDocuments isPerPerson={true} />

    </div>
  )
}

export default DocumentsPerPerson;