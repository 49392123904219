import axiosDocumentEditor from "../utils/axiosDocumentEditor";

class DocumentEditorService {
    constructor() {
        this.status = 0;
    }

    async convertDocxToSfdt(blob, params) {
        try {
            const formData = new FormData();
            formData.append('files', blob, 'document.docx');

            const response = await axiosDocumentEditor.post("production/web-services/api/documenteditor/Import", formData, {
                params
            });
            this.status = response.status;
            return response.data;
        } catch (e) {
            return e.response.data;
        }
    }
}

export default DocumentEditorService;