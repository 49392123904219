import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import BreadcrumbCustom from '../../../../app/components/BreadcrumCustom';
import ListGroup from './listGroup';
import ModalGroups from './ModalGroups';

import { cleanData, searchGroup, setGroup } from '../../../../reducers/typePeople/reducerGroup';
import { setSelectedTypepeople } from '../../../../reducers/typePeople/reducerTypepeople';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import GeneralService from '../../../../services/GeneralService';
import { SearchBar } from '../../../../app/components/SearchBar';

const TypepeopleGroup = () => {
    const { list, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { people = 0 } = queryString.parse(search);

    const loadData = async (id) => {
        const service = new GeneralService("clusters");

        if (selectedTypepeople.id === ID_NOT_DEFINED) {
            dispatch(setSelectedTypepeople({ value: list.find(i => i.id === parseInt(people)) }));
            const { clusters = [] } = await service.getList(1000, { type_peoples_id: id });
            dispatch(setGroup({ value: clusters }));
            return;
        }
        const { clusters = [] } = await service.getList(1000, { type_peoples_id: selectedTypepeople.id });
        dispatch(setGroup({ value: clusters }));
    }

    const onSearch = (e) => {
        dispatch(searchGroup({ value: e.target.value }));
    }

    useEffect(() => {
        loadData(people);
    }, [people, list]);

    return (
        <div className='container-fluid max-height-overflow-y'>
            <BreadcrumbCustom
                title="Grupos"
                routes={[
                    { name: "Tipos de persona", link: "/typepeople/list" },
                    { name: selectedTypepeople.name ? selectedTypepeople.name : "Seleccione tipo de persona", link: "/typepeople/list" },
                ]}
                activeRoute="Grupos"
            />

            <div className="row">
                <div className="col-4">
                    <SearchBar onChange={onSearch} />
                </div>
                <div className="col-8">
                    <button title="Crear nuevo grupo" className="btn btn-success rounded-pill" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                        <i className="fa-solid fa-layer-group text-white me-2" />
                        Agregar
                    </button>
                </div>
            </div>

            <div className='table-data'>
                <ListGroup setShow={setShow} />
            </div>
            <ModalGroups show={show} setShow={setShow} />
        </div>
    )
}

export default TypepeopleGroup;