import { createSlice } from '@reduxjs/toolkit';

export const toastProgressSlice = createSlice({
    name: 'toastProgressSlice',
    initialState: {
        loading: false,
        show: false,
        message: "",
    },
    reducers: {
        showLoading: (state, { payload }) => {
            state.show = true;
            state.loading = true;
            state.message = payload;
        },
        hideLoading: (state) => {
            state.show = false;
            state.loading = false;
        },
    },
});

export const {
    showLoading,
    hideLoading,
} = toastProgressSlice.actions;

export default toastProgressSlice.reducer;