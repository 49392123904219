import { createSlice } from "@reduxjs/toolkit";

const registrationLinkSlice = createSlice({
    name: 'registrationLinkSlice',
    initialState: {
        registrationLink: {
            name: "",
            email: "",
            type_people_id: "",
            expires_at: "1H",
        },
        durations: [
            { name: "1 H", value: "1H", tooltip: "Una hora" },
            { name: "2 H", value: "2H", tooltip: "Dos horas" },
            { name: "5 H", value: "5H", tooltip: "Cinco horas" },
            { name: "7 D", value: "7D", tooltip: "Siete días" },
        ],
    },
    reducers: {
        reducerForm: (state, action) => {
            state.registrationLink[action.payload.key] = action.payload.value;
        },
        cleanData: (state, action) => {
            state.registrationLink = {
                name: "",
                email: "",
                type_people_id: "",
                expires_at: "1H",
            };
        },
    }
})

export const {
    reducerForm,
    cleanData,
} = registrationLinkSlice.actions;

export default registrationLinkSlice.reducer;