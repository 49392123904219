import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { processDataViews } from '../utils/processDataViews';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarChart = () => {
    const { listFilter } = useSelector(state => state.viewsSlice);
    const { labels, sortomCounts, notaryCounts } = processDataViews(listFilter);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Visitas Sortom y Notaría Virtual",
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Sortom',
                data: sortomCounts,
                borderColor: '#198754',
                backgroundColor: '#198754',
            },
            {
                label: 'Notaría Virtual',
                data: notaryCounts,
                borderColor: '#D3E8DF',
                backgroundColor: '#D3E8DF',
            },
        ],
    };

    return (
        <div>
            <div style={{ height: "500px" }}>
                <Bar options={options} data={data} />
            </div>
        </div>
    );
};

export default BarChart;
