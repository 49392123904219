import { createSlice } from "@reduxjs/toolkit";

export const labelGlobalSlice = createSlice({
    name: "labelGlobalSlice",
    initialState: {
        selectedLabel: {
            id: 0,
            name: "",
            description: "",
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, { payload }) => {
            let {value} = payload;

            if (payload.key === "name") {
                value = value.toUpperCase().replace(" ", "_");
            }

            state.selectedLabel[payload.key] = value;
        },
        reducerFormSearch: (state, action) => {
            if (action.payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromLabels: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload);
        },
        updateFromLabels: (state, action) => {
            const code = action.payload;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromLabels: (state, action) => {
            state.list.push(action.payload);
            state.listFilter.push(action.payload);
        },
        setSelected: (state, action) => {
            state.selectedLabel = action.payload;
        },
        cleanData: (state, action) => {
            state.selectedLabel = {
                id: 0,
                name: "",
                description: "",
            };
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromLabels,
    updateFromLabels,
    insertFromLabels,
    setSelected,
    cleanData,
} = labelGlobalSlice.actions;

export default labelGlobalSlice.reducer;