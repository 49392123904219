import { useRef } from "react";

const FieldTypeText = ({ param, location, value, onChange, onInformation, className }) => {
    const textareaRef = useRef(null);

    const handleChange = (e) => {
        const { id, ...other } = param;
        const value = param.apply_format ? e.target.value.toUpperCase() : e.target.value;

        onChange({
            parameterization_id: param.id,
            value,
            ...other,
        });

        if (param.required) {
            if (value.length < param.length_min) {
                onInformation({ show: true, message: `Debe ser un texto más largo -> ${value.length}/${param.length_min}`, isError: true });
                return;
            }
        }
        onInformation({ show: true, message: `${value.length}/${param.length_max}`, isError: false });

        if (param.length_max > 100) {
            const textarea = textareaRef.current;
            if (textarea.scrollHeight > 160) return;
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

    return (
        param.length_max > 100
            ?
            <textarea
                ref={textareaRef}
                className={`form-control parametrizacion__form-input parametrizacion__form-textarea ${className}`}
                aria-label={location}
                placeholder="Ingresa un texto"
                maxLength={param.length_max}
                required={param.required}
                name={param.name}
                minLength={param.length_min}
                autoComplete="off"
                value={value}
                onChange={handleChange}
            ></textarea>
            :
            <input
                className={`form-control parametrizacion__form-input ${className}`}
                autoComplete="off"
                value={value}
                type="text"
                name={param.name}
                required={param.required}
                maxLength={param.length_max}
                minLength={param.length_min}
                placeholder="Ingresa un texto"
                aria-label={location}
                onChange={handleChange}
            />
    )
}

export default FieldTypeText;
