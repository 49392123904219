import { createSlice } from '@reduxjs/toolkit';

export const calendarslice = createSlice({
    name: 'calendarslice',
    initialState: {
        labels: [],
        events: [],
        eventsday: [],
        colors: [],
        user: [],
        oncelabel: [],
        event: {
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            notification_time: "",
            interval: 1,
            reporting_id: 0,
            responsible_id: 0,
            labels_id: []
        },
        eventupdate: {
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            notification_time: "",
            interval: 1,
            reporting_id: 0,
            responsible_id: 0,
            labels_id: []
        },
        day: Date.now(),
        label: {
            name: "",
            label_color_id: 0
        },
        filters: {
            label_id: 0,
            start_date: "",
            end_date: ""
        }
    },
    reducers: {
        setListLabels: (state, action) => {
            state.labels = action.payload.value;
        },
        setListLabel: (state, action) => {
            state.oncelabel = action.payload.value;
        },
        setListColors: (state, action) => {
            state.colors = action.payload.value;
        },
        setListUser: (state, action) => {
            state.user = action.payload.value;
        },
        setEventUpdate: (state, action) => {
            state.eventupdate[action.payload.key] = action.payload.value;
        },
        eventUpdate: (state, { payload }) => {
            state.event = payload;
        },
        setEvents: (state, action) => {
            state.events = action.payload.value;
        },
        setEventsday: (state, action) => {
            state.eventsday = action.payload.value;
        },
        setEvent: (state, action) => {
            state.event[action.payload.key] = action.payload.value;
        },
        setFilters: (state, action) => {
            state.filters[action.payload.key] = action.payload.value;
        },
        setEventData: (state, action) => {
            state.event[action.payload.key] = action.payload.value;
        },

        setLabel: (state, { payload }) => {
            state.label[payload.key] = payload.value;
        },

        setCleanLabel: (state, action) => {
            state.label = {
                name: "",
                label_color_id: 0
            }
        },
        setCleanEvent: (state, action) => {
            state.event = {
                title: "",
                description: "",
                start_date: "",
                end_date: "",
                notification_time: "",
                interval: 1,
                reporting_id: 0,
                responsible_id: 0,
                labels_id: []
            }
        }
    },
});

export const {
    setListLabels,
    setEvents,
    setEvent,
    setEventsday,
    setLabel,
    setListColors,
    saveLabel,
    setCleanLabel,
    eventUpdate,
    setListUser,
    setFilters,
    setListLabel,
    setEventUpdate,
    setCleanEvent
} = calendarslice.actions;

export default calendarslice.reducer;