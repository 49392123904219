import { useEffect } from "react";
import { ref, onValue, off, query, orderByChild } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";

import ActiveChat from "./components/ActiveChat";
import { ActiveChatMessages } from "./components/ActiveChatMessages";
import { FormSendMessage } from "./components/FormSendMessage";
import { ListRecentChats } from "./components/ListRecentChats";

import { setListChats, setSelectedChat } from "../../reducers/chat/reducerChatSlice";
import { FirebaseDB } from "../../firebase/config/firebase-config";
import { useResize } from "../../app/hooks/useResize";
import EmptyChat from '../../assets/chatEmpty.svg';
import AudioNotification from '../../assets/audio/Notification-magic-light.mp3';
import "./styles/chatStyle.css";

export const BusinessChatView = () => {
    const { nick_name } = useSelector(state => state.loginSlice.profile);
    const { company } = useSelector(state => state.businessSlice);
    const { selectedChat } = useSelector(state => state.businessChatSlice);
    const { windowSize } = useResize();
    const dispatch = useDispatch();
    var audio = new Audio(AudioNotification);

    const handleGoBack = () => {
        dispatch(setSelectedChat(undefined));
    }

    useEffect(() => {
        const currentBusiness = query(ref(FirebaseDB, `businesses/business_id_${company.id}/chats`),
            orderByChild('updated_at'));

        const handleChatUpdates = (snapshot) => {
            const chats = snapshot.val();
            const userChats = [];

            for (const chatId in chats) {
                const chat = chats[chatId];
                if (chat.participants && chat.participants[nick_name]) {
                    const filteredMessages = {};

                    let foundNewMessages = false;

                    if (chat.messages) {
                        for (const messageId in chat.messages) {
                            const message = chat.messages[messageId];
                            if (!message.deleteFor || !message.deleteFor[nick_name]) {
                                filteredMessages[messageId] = message;
                            }
                            if (!message.read && message.sender !== nick_name) {
                                foundNewMessages = true;
                            }
                        }
                    }

                    if (foundNewMessages) {
                        audio.play();
                    }

                    userChats.push({ chatId, ...chat, messages: filteredMessages });
                }
            }

            userChats.sort((a, b) => b.updated_at - a.updated_at);

            dispatch(setListChats(userChats));
        };

        onValue(currentBusiness, handleChatUpdates);

        return () => {
            off(currentBusiness, 'value', handleChatUpdates);
        };
    }, [company, nick_name]);

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row mt-2">
                {
                    windowSize.width <= 900
                        ?
                        (
                            selectedChat.chatId
                                ?
                                <div className="col-12 col-lg-8 ps-0">
                                    <ActiveChat showBackButton={windowSize.width <= 900} />

                                    <div className="chat-section business__chat">
                                        {selectedChat.chatId && <ActiveChatMessages />}

                                        <FormSendMessage />
                                    </div>
                                </div>
                                :
                                <ListRecentChats />
                        )
                        :
                        <>
                            <ListRecentChats />
                            <div className="col-12 col-lg-8 ps-0">
                                {
                                    selectedChat.chatId
                                        ?
                                        <>
                                            <ActiveChat onGoBack={handleGoBack} />

                                            <div className="chat-section business__chat">
                                                {selectedChat.chatId && <ActiveChatMessages />}

                                                <FormSendMessage />
                                            </div>
                                        </>
                                        :
                                        <div className="empty__chat">
                                            <img src={EmptyChat} alt="Chat no seleccionado" />
                                            <p>Crea un nuevo chat o selecciona uno de tus recientes</p>
                                        </div>
                                }

                            </div>
                        </>
                }
            </div>
        </div>
    )
}
