export const decodeToken = (token) => {
    try {
        const decoded = JSON.parse(window.atob(token.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);

        const isActive = decoded.exp > currentTime;
        if(!isActive) localStorage.removeItem("user");

        decoded.auth = isActive;
        return decoded;
    } catch (error) {
        return { auth: false };
    }
}