export const findChatWithParticipants = (chats, loggedInUserNickname, otherUserNickname) => {
    for (const chat of chats) {
        const participants = chat.participants;
        if (participants[loggedInUserNickname] && participants[otherUserNickname]) {
            return chat;
        }
    }
    return null;
};

export const findLastMessage = (messages) => {
    let lastMessage = null;

    for (const messageId in messages) {
        const message = messages[messageId];
        if (!lastMessage || message.timestamp > lastMessage.timestamp) {
            lastMessage = message;
        }
    }

    return lastMessage;
}

export const countRecentUnreadMessages = (messages, loggedUser) => {
    let count = 0;

    for (const messageId in messages) {
        const message = messages[messageId];
        if (message.sender !== loggedUser && !message.read) {
            count += 1;
        }
    }

    return count;
}

export const getTimeElapsed = (timestamp) => {
    if (!timestamp) return "";

    const now = Date.now();
    const elapsedMilliseconds = now - timestamp;

    const seconds = Math.floor(elapsedMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} d`;
    } else if (hours > 0) {
        return `${hours} h`;
    } else if (minutes > 0) {
        return `${minutes} min`;
    } else {
        if (seconds === 0) return "Ahora";
        return `${seconds} seg`;
    }
};