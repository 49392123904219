import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { reducerForm } from '../../../reducers/createCase/reducerCreateCase';
import ModalFavoriteCase from "./ModalFavoriteCase";
import ModalFavoriteCodeTableCase from "./ModalFavoriteCodeTableCase";

const FavoriteCodesCases = () => {
    const { list } = useSelector(state => state.legalCaseSlice);
    const { dataLegalCase } = useSelector(state => state.createCaseSlice);
    const [show, setShow] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const dispatch = useDispatch();

    const setData = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    return (
        <>
            <div className="row card__section-favorite-codes">
                <div className="col">

                    <div className="row mt-2">
                        <div className="col">
                            <h6 className="mt-3 fw-bold">Casos</h6>
                        </div>
                        <div className="col">
                            <select
                                className="form-select mt-2"
                                name="legal_case_id" 
                                value={dataLegalCase.legal_case_id}
                                onChange={setData}
                            >
                                <option value="0">
                                    Seleccione...
                                </option>
                                {
                                    list.map(item => (<option value={item.id} key={item.id}>
                                        {item.name}
                                    </option>))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            Campos de búsqueda
                        </div>
                        <div className="col">
                            <button title="Abre el modal para configurar los códigos de búsqueda" className="btn btn-success mt-1" type="button"
                                onClick={() => setShow(true)}>
                                <i className="fa-solid fa-magnifying-glass" style={{ fontSize: '16px' }} ></i>
                            </button>
                        </div>
                    </div>

                    <div className="row mt-2">

                        <div className="col">
                            <p>Columnas de la tabla</p>
                        </div>
                        <div className='col'>
                            <button title="Abre el modal para configurar los códigos de la tabla" className="btn btn-success mt-1" type="button"
                                onClick={() => setShowTable(true)}>
                                <i className="fa-solid fa-table" style={{ fontSize: '16px' }}></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <ModalFavoriteCase show={show} setShow={setShow} />
            <ModalFavoriteCodeTableCase show={showTable} setShow={setShowTable} />
        </>
    )
}

export default FavoriteCodesCases;