import axios from "../../../../../utils/axios"
const typepeopleCategory = {};
const path = 'category-people';

typepeopleCategory.getList = async (id) => {
    try {
        const urlList = `${path}?offset=${100}&type_peoples_id=${id}`;
        const response = await axios.get(urlList);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

typepeopleCategory.save = async (data) => {
    try {
        const response = await axios.post(path, data)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}
typepeopleCategory.update = async (data) => {
    try {
        const urlUpdate = `${path}/${data.id}`;
        const response = await axios.put(urlUpdate, data)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

typepeopleCategory.getById = async (id) => {
    try {
        const urlGet = `${path}/${id}`;
        const response = await axios.get(urlGet)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}


typepeopleCategory.delete = async (id) => {
    try {
        const urlDelete = `${path}/${id}`;
        const response = await axios.delete(urlDelete);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export default typepeopleCategory;