
export const Color = ({ color, size=20 }) => {
    return (
        <div
            style={{
                backgroundColor: "#" + color,
                width: size + 'px',
                height: size + 'px',
                borderRadius: size + "px"
            }}
        ></div>
    )
}
