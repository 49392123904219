import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { ListGroupCategory } from './listGroupCategory';
import ModalGroup from './ModalGroup';
import BreadcrumbCustom from '../../../../../app/components/BreadcrumCustom';
import { SearchBar } from '../../../../../app/components/SearchBar';

import { setSelectedTypepeople } from '../../../../../reducers/typePeople/reducerTypepeople';
import { searchGroup, cleanData, setListGroups } from '../../../../../reducers/typePeople/reducerGroupCategory';
import { setCategory, setSelectedCategory } from '../../../../../reducers/typePeople/reducerCategory';
import serviceCategory from '../service/serviceCategory';
import service from './service/serviceGroupCategory';
import { ID_NOT_DEFINED } from '../../../../../constant/constant';

const TypeopleCateogryGroup = () => {
    const { list: listTypePeople, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const { list: listCategory, selectedCategory } = useSelector(state => state.typepeopleCategorySlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const { search } = useLocation();
    const { people = 0, category = 0 } = queryString.parse(search);

    const loadData = async (typePeopleId, categoryId) => {
        if (people === ID_NOT_DEFINED) {
            history.push("/typepeople/categories");
        }
        dispatch(setSelectedTypepeople({ value: listTypePeople.find(i => i.id === parseInt(people)) }));

        if (listCategory.length === 0) {
            const { category_peoples = [] } = await serviceCategory.getList(typePeopleId);
            dispatch(setCategory({ value: category_peoples }));
            dispatch(setSelectedCategory({ value: category_peoples.find(i => i.id === parseInt(category)) }));
        }

        const res = await service.getList(categoryId);
        const { clusters = [] } = res;
        dispatch(setListGroups({ value: clusters }));
    }

    const onSearch = (e) => {
        dispatch(searchGroup({ value: e.target.value }));
    }

    useEffect(() => {
        loadData(people, category);
    }, [listTypePeople, people, category]);

    return (
        <div className="container-fluid max-height-overflow-y">
            <BreadcrumbCustom
                title="Grupos categoría"
                routes={[
                    { name: "Tipos de persona", link: "/typepeople/list" },
                    { name: selectedTypepeople.name ? selectedTypepeople.name : "Seleccione tipo de persona", link: "/typepeople/list" },
                    { name: selectedCategory.name ? selectedCategory.name : "Seleccione catergoría", link: "/typepeople/categories" },
                ]}
                activeRoute="Lista grupos"
            />

            <div className="row">
                <div className="col-4">
                    <SearchBar onChange={onSearch} />
                </div>
                <div className="col-8">
                    <button title="Crear nueva categoría" className="btn btn-success rounded-pill" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                        <i className="fa-solid fa-layer-group text-white me-2" />
                        Agregar
                    </button>
                </div>
            </div>
            <div className="table-data" >
                <ListGroupCategory setShow={setShow} />
            </div>

            <ModalGroup show={show} setShow={setShow} />
        </div>
    );
}

export default TypeopleCateogryGroup;