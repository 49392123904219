import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Settings from '../../../assets/settings.svg';

import CardUtilityCodeTypePeople from './CardUtilityCodeTypePeople';

const ModalUtilitiesCodeTypePeople = ({ show, setShow }) => {
  const { list } = useSelector(state => state.utilitiesCodeTypePeopleSlice);
  const { dataTypePeole } = useSelector(state => state.peopleSlice);
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
      <Modal.Header className='bg-green pe-4' closeButton>
        <div className='container'>
          <div className='row'>

            <div className='col-1'>
              <img src={Settings} className="mb-0 mt-2 img-button-tercero" alt="Configuraciones" />
            </div>
            <div className='col mt-2'>
              <h5>Indica los campos</h5>
            </div>
          </div>
        </div>

      </Modal.Header>
      <Modal.Body>
        <div className='container-fluid'>
          {
            list.length === 0 ?
              <div className="alert alert-warning text-center mt-2" role="alert">
                No hay campos que mostrar
              </div>
              :
              <div className='row row-cols-4 abs-center'>
                {
                  list.map(item => (<CardUtilityCodeTypePeople
                    field={item}
                    typePeopleId={dataTypePeole.type_people_id}
                    key={uuidv4()} />))
                }
              </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Listo
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalUtilitiesCodeTypePeople;