import { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ItemUtilityCodeCategory from "../../../Configuration/components/ItemUtilityCodeCategory";
import IconButton from "../../../../app/components/IconButton";

import GeneralService from "../../../../services/GeneralService";
import confirmDelete from "../../../../utils/confirmDelete";
import { deleteSelectedActors, updateSelectedLabelActor } from "../../../../reducers/documents/reducerGenerateDocument";
import { toast } from "../../../../utils/toast";
import { ALERT_WARNING, ID_NOT_DEFINED } from "../../../../constant/constant";
import { MessageError } from "../../../../utils/message";

export const CardActorLabel = ({ person }) => {
    const { selectedPeople } = useSelector(state => state.documentsAutoSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const labels = person.actor?.labels ?? [];

    const removeActor = async () => {
        if (person.id !== ID_NOT_DEFINED) {
            const service = new GeneralService("actors-generate-document");
            const res = await service.delete(person.id);
            if (!res.is_ok) {
                MessageError(res.message, service.status);
                return;
            }
        }
        toast(dispatch, "Actor removido");
        dispatch(deleteSelectedActors({ value: person.people_id }));
    }

    const handleRegisterLabel = async (label) => {
        const filteredByActor = selectedPeople.filter(item => item.actor.id === person.actor.id && item.id !== person.id);
        let found = false;
        filteredByActor.forEach(item => {
            if (item.labels.id === label.id) {
                found = true;
            }
        })

        if (found) {
            toast(dispatch, "Al repetir una etiqueta debes colocar TODOS", ALERT_WARNING);
        }
        if (person.id !== ID_NOT_DEFINED) {
            const data = {
                id: person.id,
                require_selfie_photo: person.require_selfie_photo,
                require_document_photo: person.require_document_photo,
                require_facial_recognition: person.require_facial_recognition,
                automatic_mailing:  person.automatic_mailing,
                automatic_whatsapp: person.automatic_whatsapp,
                name: person.name,
                email: person.email,
                phone: person.phone,
                actors_documents_id: person.actors_documents_id,
                label_global_id: label.id,
            }
            const service = new GeneralService("actors-generate-document");
            const res = await service.update(data);
            if (!res.is_ok) {
                MessageError(res.message, service.status);
                return;
            }
        }
        toast(dispatch, "Etiqueta configurada");
        dispatch(updateSelectedLabelActor({ value: { person, label } }));
        setShow(false);
    }

    const handleDeleteLabel = () => {
        dispatch(updateSelectedLabelActor({ value: { person, label: {} } }));
    }

    const popover = (
        <Popover style={{minWidth: "20rem"}}>
            <Popover.Header className="bg-purple text-white text-center" as="h3">Selecciona</Popover.Header>
            <Popover.Body>
                {
                    labels.map(label => <ItemUtilityCodeCategory
                        key={label.id}
                        name={label.name}
                        className="mb-1"
                        icon="fa-tag"
                        active={label.id === person.labels?.id}
                        onClick={() => handleRegisterLabel(label)}
                    />)
                }

                <hr />
                <div className="row mt-1">
                    <div className="col text-center category-code-delete__config" role="button" onClick={handleDeleteLabel}>
                        Borrar
                    </div>
                </div>

            </Popover.Body>
        </Popover>
    );

    return (
        <div className="animate__animated animate__fadeInLeft">
            <div className="row">
                <div className="col-9">{person.name}</div>
                <div className="col">
                    <IconButton
                        icon="fa-solid fa-trash text-redligth"
                        title="Eliminar Actor"
                        onClick={async () => await confirmDelete(() => removeActor())}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <OverlayTrigger placement="bottom" overlay={popover} show={show}>
                        <div
                            className={`rounded-2 pb-1 text-center text-purple mx-2 mt-2 ${person.labels?.id ? "bg-light-purple" : "bg-light-gray"}`}
                            role="button"
                            onClick={() => setShow(c => !c)}
                        >
                            <i className="fa-solid fa-tag me-2 fs-7 text-purple"></i>
                            <span className="fw-bold fs-7">{person.labels?.name ? person.labels.name : "ETIQUETA"}</span>
                        </div>
                    </OverlayTrigger>
                </div>
            </div>
            <hr className="mt-2 text-purple" />
        </div>
    );
}
