import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { registerPromptAndCodes } from '../../../reducers/chatgpt/thunks';
import BotParameterization from '../../../assets/ia_parameterization.svg';

const ModalAutomateParameterization = ({ show, setShow, textCodes, prompt }) => {       
    const dispatch = useDispatch();

    const handleCreatePrompt = () => {
        dispatch(registerPromptAndCodes(textCodes, prompt));
        setShow({ modal: 2, show: true });
    }

    return (
        <Modal show={show} onHide={() => setShow({ modal: 1, show: false })}>
            <Modal.Header className='bg-green pe-4' closeButton>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h5 className="mt-2 text-center">
                                Parametrización automática
                            </h5>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <img src={BotParameterization} className='d-block mx-auto' height={120} alt='Parameterization'/>
                <p>En este proceso de parametrización automática mediante IA es importante que detalles bien la información del documento que quieres elaborar. Hemos cargado por ti las instrucciones básicas y los códigos necesarios.</p>

                <button title="Iniciar parametrización automática" className="btn btn-success d-block rounded-pill px-3 py-1 mx-auto mt-5" type="button"
                    onClick={handleCreatePrompt}
                >
                    <i className="fa-solid fa-play text-white"></i>
                </button>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAutomateParameterization;