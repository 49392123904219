import { createSlice } from "@reduxjs/toolkit";

export const storageUserSlice = createSlice({
    name: 'storageUserSlice',
    initialState: {
        readFiles: [],
        selectedFile: {
            id: 0,
            url: '',
            name: '',
            profile_id: 0
        },
    },
    reducers: {
        //Obtener archivos en una lista, para mostrar
        setFilesFirebase: (state, action) => {
            state.readFiles = action.payload.value;
        },
        //Mostrar la nueva lista, después de eliminar archivos
        deleteFileOfUsers: (state, action) => {
            const newList = state.readFiles.filter(e => e.id !== action.payload.value);
            state.readFiles = newList;
        },
        setSelectedFile: (state, action) => {
            let file = { ...action.payload.value };
            //Type
            const z = file.name.split('.');
            const type = z[z.length - 1];
            file.type = type;
            //Only name
            const name = z.slice(0, z.length - 1).join('.');
            file.name = name;

            state.selectedFile = file;
        },
        reducerForm: (state, action) => {
            const { key, value } = action.payload;
            state.selectedFile = {
                ...state.selectedFile,
                [key]: value
            }
        },
        cleanSelectedFile: (state) => {
            state.selectedFile = {
                id: 0,
                url: '',
                name: '',
                profile_id: 0
            };
        },
    },
});

export const {
    setFilesFirebase,
    deleteFileOfUsers,
    setSelectedFile,
    reducerForm,
    cleanSelectedFile
} = storageUserSlice.actions;

export default storageUserSlice.reducer;