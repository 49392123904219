import { useDispatch, useSelector } from "react-redux";

import { FolderIcon } from "./FolderIcon";
import { ContextMenuColor } from "./ContextMenuColor";
import IconButton from "../../../../app/components/IconButton";

import GeneralService from "../../../../services/GeneralService";
import { useContextMenu } from "../../../../app/hooks/useContextMenu";
import { changeColorFolder, cleanData, deleteFromExpedient, setSelected } from "../../../../reducers/expedient/reducerExpedient";
import confirmDelete from "../../../../utils/confirmDelete";
import { MessageError, MessageSuccess, cutText, formatDate } from "../../../../utils";
import { useHistory } from "react-router-dom";

import '../styles/contextMenu.css';

export const ItemListExpedient = ({ expedient }) => {
    const service = new GeneralService("expedient");
    const { list } = useSelector(state => state.colorsSlice);
    const { list: listTypeProcess } = useSelector(state => state.typeProcessSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    const { cardRef, contextMenuVisible, handleContextMenu,
        handleHideContextMenu, handleClick, contextMenuPosition } = useContextMenu(() => { });

    const deleteExpedient = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess("El expediente ha sido eliminado exitosamente!");
            dispatch(deleteFromExpedient(id));
            return;
        }
        MessageError(res.message, service.status);
    }

    const onChangeColor = async (color) => {
        const data = {
            ...expedient,
            colors_id: color,
            finish_date: undefined
        }
        const res = await service.update(data);
        if (res.is_ok) {
            return dispatch(changeColorFolder(data));
        }

        MessageError(res.message, service.status);
    }

    const onEdit = (expedient) => {
        dispatch(cleanData());
        history.push("/expedient/edit/" + expedient.id);
    }

    return (
        <>
            <tr ref={cardRef} key={expedient.id}
                className="font-rubik"
                onContextMenu={handleContextMenu}
                onClick={handleClick}
                onDoubleClick={() => onEdit(expedient)}
            >
                <td></td>
                <td>#{expedient.number}</td>
                <td>
                    <FolderIcon color={list.find(itemColor => itemColor.id === expedient.colors_id)?.color} />
                </td>
                <td style={{ maxWidth: "300px" }}>{cutText(expedient.name, 50)}</td>
                <td>{cutText(listTypeProcess.find(typeProcess => typeProcess.id === expedient.type_process_id)?.name, 45)}</td>
                <td>{formatDate(expedient.start_date)}</td>
                <td>{expedient.finish_date.startsWith("0001") ? "---------" : formatDate(expedient.finish_date)}</td>
                <td>
                    <IconButton
                        icon="fa-solid fa-gear text-green"
                        title="Editar expediente"
                        onClick={() => onEdit(expedient)}
                    />
                    <IconButton
                        icon="fa-solid fa-trash-can text-red"
                        title="Eliminar expediente"
                        onClick={async () => await confirmDelete(() => deleteExpedient(expedient.id))}
                    />
                </td>
                <td></td>
            </tr>
            <ContextMenuColor
                visible={contextMenuVisible}
                x={contextMenuPosition.x}
                y={contextMenuPosition.y}
                onHide={handleHideContextMenu}
                colors={list}
                selectedColor={expedient.colors_id}
                onChangeColor={onChangeColor}
            />
        </>
    )
}