import { createSlice } from '@reduxjs/toolkit';
import { decodeToken } from '../../utils/decodeJWT';
import { MODULE_ROLES } from '../../constant/constant';

const init = () => {
    const parsed = JSON.parse(localStorage.getItem('user'));
    let auth = { auth: false };

    if (parsed) {
        const decodedToken = decodeToken(parsed.token);
        auth = {
            auth: decodedToken.auth,
            ...decodedToken,
        };
    }

    const obj = {
        auth,
        permissions: [],
        canActionRoles: false,
        profile: {},
        businessProfiles: [],
    };
    return obj;
}

export const loginSlice = createSlice({
    name: 'loginSlice',
    initialState: init(),
    reducers: {
        setProfile: (state, { payload }) => {
            state.profile = payload;

            const { permissions = [] } = payload.role;
            state.permissions = permissions;
            state.canActionRoles = permissions.some(permission => permission.module_id === MODULE_ROLES && permission.can_action);
        },
        setBusinessProfiles: (state, { payload }) => {
            state.businessProfiles = payload;
        },
        loginUser: (state, { payload }) => {
            state.auth = { auth: payload.auth, ...decodeToken(payload.token) }
            localStorage.setItem('user', JSON.stringify(payload));
        },
        logout: (state) => {
            localStorage.removeItem('user');
            state.auth = {
                "auth": false,
                "message": "usuario no autenticado, no encontrado",
                "title": "No autenticado"
            };
        },
    },
});

export const {
    setProfile,
    setBusinessProfiles,
    loginUser,
    logout
} = loginSlice.actions;

export default loginSlice.reducer;
