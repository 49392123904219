import { Accordion } from "react-bootstrap";

import ItemCardParameterization from "./ItemCardParameterization";

const ItemCardGroup = ({ list, name="", iconHeader="",iconItem="", textItem="", keyGroups, keyByGroup, insertCode }) => {
    return (
        <Accordion.Item eventKey={keyGroups}>
            <Accordion.Header className="p-0 accordion-header">
                <div className="container-fluid p-0 py-1 accordion-children-container">
                    <div className='row m-0 px-2'>
                        <div className='col-2 m-0 p-0'>
                            <i className={iconHeader} style={{ fontSize: 18 }}></i>
                        </div>
                        <div className='col-10 m-0 p-0'>
                            <span className='spanLetter'>{name}</span>
                        </div>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body className="m-0 p-0 ps-3">
                {
                    list?.map((item) => (
                        <Accordion.Item eventKey={`${keyByGroup}-${item.id}`} key={item.id}>
                            <Accordion.Header className="p-0 accordion-header">
                                <div className="container-fluid p-0 accordion-children-container">
                                    <div className='row m-0 px-2'>
                                        <div className='col-2 m-0 p-0'>
                                            <i className={iconItem}></i>
                                        </div>
                                        <div className='col-10 m-0 p-0'>
                                            <small className='smallLetter'>{textItem}</small>
                                            <br></br>
                                            <span className='spanLetter'>{item.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Header>

                            <Accordion.Body className="m-0 p-0">
                                <div className="row ms-3">
                                    {
                                        item.parameterizations?.map(
                                            p => (<ItemCardParameterization
                                                parameterization={p}
                                                key={p.id}
                                                onClick={() => insertCode(p.code_parameterization)}
                                            />))
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default ItemCardGroup;