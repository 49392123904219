import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import ToolFilterDocuments from '../../../TercerosCreate/components/ToolFilterDocuments';
import { CustomPagination } from '../../../../app/components/CustomPagination';

import GeneralService from '../../../../services/GeneralService';
import { applyFilters, fillElatedDocuments } from '../../../../reducers/people/reducerPersonDocument';
import { ListAllGeneratedDocuments } from '../../../TercerosCreate/components/ListAllGeneratedDocuments';
import { updateFromMovement } from '../../../../reducers/expedient/reducerMovements';
import { MessageError, toast } from '../../../../utils';

export const ModalAssociateDocument = ({ show, setShow }) => {
    const { dataFilter } = useSelector(state => state.personDocumentSlice);
    const { selectedMovement } = useSelector(state => state.movementsSlice);
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });
    const dispatch = useDispatch();

    const loadDocuments = async () => {
        const service = new GeneralService("generate-document");
        const res = await service.getList(0, {...pagination, documents_auto_id: dataFilter.type_document});
        const { generate_documents = [], total_pages = 0 } = res;
        dispatch(fillElatedDocuments({ value: generate_documents }));
        setPagination({ ...pagination, total_pages });
    }

    const onSelectedDocument = async (document) => {
        const service = new GeneralService("movement/associate-document");

        const data = {
            id: selectedMovement.id,
            generate_document_id: document.id,
        }

        const res = await service.post(data);
        if (res.is_ok) {
            setShow(false);
            toast(dispatch, res.message);
            dispatch(updateFromMovement({ ...selectedMovement, generate_document_id: document.id, document }));
        }
        else MessageError(res.message, service.status);

        setShow(false);
    }

    useEffect(() => {
        loadDocuments();
    }, [pagination.page, pagination.page_size, dataFilter.type_document]);

    useEffect(() => {
        dispatch(applyFilters({ value: { isPerPerson: false } }));
    }, [dataFilter]);

    return (
        <Modal show={show} onHide={() => setShow(false)} size='xl'>
            <Modal.Header className='bg-green pe-4' closeButton>
                <i className="fa-solid fa-circle-plus animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                <h5 className="mt-2 ms-2">
                    Asociar documento
                </h5>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <ToolFilterDocuments />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ListAllGeneratedDocuments onSelectedDocument={onSelectedDocument} />
                        </div>
                    </div>

                    <CustomPagination
                        pagination={pagination}
                        setPagination={setPagination}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => setShow(false)} className={`btn btn-success ms-auto rounded-3 button-save-document'`}>
                    Listo
                </button>
            </Modal.Footer>
        </Modal>
    )
}