import { useHistory, useLocation } from 'react-router-dom';

const ItemNavigation = ({ path = "", icon = "", title = "", isExpanded, identation = "ms-3" }) => {
    const history = useHistory();
    const location = useLocation();

    const isActive = location.pathname === path;

    const handleNavigation = () => {
        history.push(path);
    };

    return (
        <div title={!isExpanded ? title : undefined} onClick={handleNavigation}>
            <div
                className={`d-block text-white p-2 ${isActive ? 'active-pathv2' : ''}`}
                style={{ cursor: 'pointer' }}
            >
                {
                    isExpanded
                        ?
                        <>
                            <i className={`${icon} fs-5 ${identation} me-2`}></i> {title}
                        </>
                        :
                        <i className={`${icon} fs-5 mx-1`}></i>
                }
            </div>
        </div>
    );
};

export default ItemNavigation;
