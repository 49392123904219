import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FieldType from "../../../../app/components/fields/FieldType";
import StepNavigationGroups from "../../../../app/components/StepNavigationGroups";

import { setDataPeople } from "../../../../reducers/people/reducerPeople";
import { ALERT_WARNING, FIELD_TABLE } from "../../../../constant/constant";
import { toast } from "../../../../utils/toast";

const PeopleCategoriesClusters = ({ clusters = [], id, refForm }) => {
    const { dataPeople } = useSelector(state => state.peopleSlice);
    const [currentStep, setCurrentStep] = useState(1);
    const dispatch = useDispatch();

    return (
        <>
            <div className="container-fluid m-0 rounded-4">

                <div className="row m-0 p-0 stepper">
                    <StepNavigationGroups
                        items={clusters}
                        currentStep={currentStep}
                        updateStep={setCurrentStep}>
                    </StepNavigationGroups>

                    <div className="container-fluid m-0 p-0 stepform-contenedor-campos">
                        <div className="row m-0 p-0">
                            <div className="col p-0">
                                {
                                    clusters?.map((cluster, index) => (
                                        currentStep === (index + 1)
                                        &&
                                        <div key={cluster.id}>
                                            <h3 className="text-center">{cluster.name}</h3>
                                            <small className="d-block text-center">
                                                Los campos con asterísco son obligatorios
                                                <i className="text-red fw-bold">*</i>
                                            </small>

                                            <div className="row m-0 p-0 mt-4">
                                                {
                                                    cluster.parameterizations?.length === undefined
                                                        ?
                                                        <div className="alert alert-warning" role="alert">
                                                            ¡No hay parametrizaciones para este grupo, selecciona otro!
                                                        </div>
                                                        :
                                                        cluster.parameterizations?.map((param, index) => (
                                                            <FieldType
                                                                key={index}
                                                                param={param}
                                                                id={id}
                                                                location={cluster.name}
                                                                className="col-12 col-sm-6 col-lg-4 mt-3"
                                                                value={param.type_fields_id !== FIELD_TABLE ? (dataPeople[param.name]?.value ?? "") : (dataPeople[param.name]?.table ?? [{}])}
                                                                onChange={(value) => {
                                                                    dispatch(setDataPeople({
                                                                        key: param.name,
                                                                        value: { ...value, is_category: true },
                                                                    }));
                                                                }}
                                                            />
                                                        ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-0 p-0 mt-5">
                    <div className="col buttons-stepper">
                        <button type="button" className="btn btn-success rounded-3 mx-2 px-3 py-2"
                            style={{ opacity: currentStep === 1 ? 0.15 : 1 }}
                            disabled={currentStep === 1}
                            onClick={() => setCurrentStep(currentStep - 1)}>
                            <i className="fa-solid fa-caret-left"></i> Atrás
                        </button>

                        <button
                            type="button"
                            className="btn btn-success rounded-3 mx-2 px-3 py-2"
                            style={{ opacity: currentStep === clusters?.length ? 0.15 : 1 }}
                            disabled={currentStep === clusters?.length}
                            onClick={() => {
                                if (refForm.current.checkValidity()) {
                                    setCurrentStep(currentStep + 1)
                                } else {
                                    toast(dispatch, "Tienes algunos errores en el formulario", ALERT_WARNING)
                                    refForm.current.reportValidity();
                                }
                            }}
                        >
                            Siguiente <i className="fa-solid fa-caret-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

}

export default PeopleCategoriesClusters; 